<div class="enclosures">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear nuevo recinto</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar recinto</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar recinto</mat-card-title>
    <mat-card-content>
      <form [formGroup]="createEnclosuresForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <mat-form-field appearance="standard" class="fullWidth">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" />
            <div *ngIf="submitted && ['f.name.errors']" class="invalid-feedback">
              <div *ngIf="['f.name.errors.required']">name is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Dirección</mat-label>
            <input matInput formControlName="address" />
          </mat-form-field>
          <div *ngIf="submitted && ['f.address.errors']" class="invalid-feedback">
            <div *ngIf="['f.address.errors.required']">address is required</div>
          </div>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>Número</mat-label>
            <input matInput formControlName="number" />
            <div *ngIf="submitted && ['f.number.errors']" class="invalid-feedback">
              <div *ngIf="['f.number.errors.required']">number is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>Piso</mat-label>
            <input matInput formControlName="floor" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>CP</mat-label>
            <input matInput formControlName="zip" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>Ciudad</mat-label>
            <input matInput formControlName="city" />
            <div *ngIf="submitted && ['f.city.errors']" class="invalid-feedback">
              <div *ngIf="['f.city.errors.required']">city is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field class="halfWidth">
            <mat-label *ngIf="selectedFile; else newFile">{{
              selectedFile.item(0).name
            }}</mat-label>
            <ng-template #newFile>
              <mat-label>Elija imagen</mat-label>
            </ng-template>
            <input matInput disabled />
            <button type="button" mat-icon-button matSuffix (click)="fileInput.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input
              hidden
              (change)="selectFile($event)"
              #fileInput
              type="file"
              id="file"
              accept="image/png, image/jpeg" />
          </mat-form-field>
          <div>
            <img [src]="selectedFileName" class="preview" />
          </div>
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-raised-button (click)="gotoList()">Volver</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
