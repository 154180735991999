/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { FilesService } from 'src/app/shared/services/files.service'
import { SeatMapsService } from 'src/app/shared/services/seat-maps.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-seats-editor',
  templateUrl: './seats-editor.component.html',
  styleUrls: ['./seats-editor.component.scss']
})
export class SeatsEditorComponent implements OnInit {
  public createSeatsForm!: FormGroup
  loadedSeatMap: any
  mode = 'create'
  submitted = false
  loading = false
  polygon: any
  sectionColor = 'grey'
  seatConfig: any
  layout: any
  index = 0
  sectionType: boolean = false
  new: boolean = false

  public general: boolean = true
  public seatmap: any
  public seatChartConfig = {
    showRowsLabel: true,
    showRowWisePricing: true,
    newSeatNoForRow: true
  }
  public cart = {
    selectedSeats: [],
    seatstoStore: [],
    totalamount: 0,
    cartId: '',
    eventId: 0
  }
  selectedFile: any
  selectedFileName: any
  section: any

  constructor(
    private router: Router,
    private seatMapsService: SeatMapsService,
    private fb: FormBuilder,
    private filesService: FilesService
  ) {}

  ngOnInit(): void {
    this.createSeatsForm = this.fb.group({
      rows: ['', Validators.required],
      columns: ['', Validators.required],
      number: [''],
      curve: [0, Validators.required],
      label: [''],
      rowlabel: ['']
    })
    this.loadedSeatMap = history.state.seatMap
    this.index = history.state.index
    this.mode = history.state.mode
    this.section = history.state.seatMap.sections[history.state.index]
    console.log('section: ', this.section)
    if (this.section.seatsWithoutSeats == null && this.section.cols == 0) {
      this.sectionType = true
    }
    if (this.mode === 'edit') {
      this.createSeatsForm.controls['rows'].setValue(this.loadedSeatMap.sections[this.index].rows)
      this.createSeatsForm.controls['columns'].setValue(
        this.loadedSeatMap.sections[this.index].cols
      )
      this.createSeatsForm.controls['label'].setValue(
        this.loadedSeatMap.sections[this.index]?.label
      )
      this.createSeatsForm.controls['rowlabel'].setValue(
        this.loadedSeatMap.sections[this.index]?.rowlabel
      )
      this.createSeatsForm.controls['number'].setValue(
        this.loadedSeatMap.sections[this.index].seatsWithoutSeats
      )
      if (this.loadedSeatMap.sections[this.index]?.curve !== undefined) {
        this.createSeatsForm.controls['curve'].setValue(
          this.loadedSeatMap.sections[this.index]?.curve
        )
      }
      if (this.loadedSeatMap.sections[this.index].rows > 0) {
        this.general = false
      }
      this.generateSeats('edit')
    }
  }

  gotoList() {
    this.router.navigate(['/home/seat-map-general'], {
      state: { mode: 'edit', seatMap: this.loadedSeatMap }
    })
  }

  public processSeatChart(map_data: any[]) {
    let labels = this.createSeatsForm.get('label')?.value
    if (labels === undefined) {
      labels = ''
    }
    const processedLabels = labels.split(',')
    let rowLabels = this.createSeatsForm.get('rowlabel')?.value
    if (rowLabels === undefined) {
      rowLabels = ''
    }
    const processedRowLabels = rowLabels.split(',')
    this.seatmap = []
    if (map_data?.length > 0 || map_data !== undefined) {
      let seatNoCounter = 1
      let row_label = ''
      let item_map = map_data
      //Get the label name and price
      row_label = 'Row ' + item_map[0].seat_label + ' - '
      if (item_map[item_map.length - 1].seat_label != ' ') {
        row_label += item_map[item_map.length - 1].seat_label
      } else {
        row_label += item_map[item_map.length - 2].seat_label
      }
      item_map.forEach((map_element: any) => {
        let mapObj = {
          seatRowLabel: map_element.seat_label,
          seats: [] as any,
          seatPricingInformation: row_label
        }
        if (rowLabels !== '') {
          mapObj.seatRowLabel = processedRowLabels[parseInt(map_element.seat_label) - 1]
        }
        row_label = ''
        let seatValArr = map_element.layout.split('')
        if (this.seatChartConfig.newSeatNoForRow) {
          seatNoCounter = 1 //Reset the seat label counter for new row
        }
        let totalItemCounter = 1
        const selectedCurve = this.createSeatsForm.get('curve')?.value
        const columns = this.createSeatsForm.get('columns')?.value
        const z = columns % 2
        const middle = Math.floor(columns / 2)
        const maxCurve = selectedCurve * 0.4
        let step = maxCurve / middle
        let steps: number[] = []
        let delta = 1.5
        for (let j = 0; j < middle + 1; j++) {
          if (j === 0) {
            steps[j] = 0
          } else if (j < middle) {
            steps[j] = steps[j - 1] + step * delta
            if (delta > 0.5) {
              delta = delta - 0.1
            }
          } else {
            steps[j] = steps[j - 1]
          }
        }
        delta = 1.5
        for (let j = columns - 1; j > middle; j--) {
          if (j === columns - 1) {
            steps[j] = 0
          } else if (j > middle) {
            steps[j] = steps[j + 1] + step * delta
            if (delta > 0.5) {
              delta = delta - 0.1
            }
          }
        }
        console.log(step)
        console.log(steps)
        let nextStep = 0
        seatValArr.forEach((item: any) => {
          let seatObj = {
            key: map_element.seat_label + '_' + totalItemCounter,
            status: 'available',
            seatLabel: '',
            seatNo: '',
            seatMargin: 0
          }
          if (item !== '0') {
            seatObj.seatLabel = map_element.seat_label + ' ' + seatNoCounter
            if (labels.length > 0 && processedLabels.length > 0) {
              seatObj['seatNo'] = processedLabels[seatNoCounter - 1]
            } else {
              if (seatNoCounter < 10) {
                seatObj.seatNo = '0' + seatNoCounter
              } else {
                seatObj['seatNo'] = '' + seatNoCounter
              }
            }
            seatNoCounter++
          } else {
            seatObj['seatLabel'] = ''
          }
          if (selectedCurve === 0) {
            seatObj.seatMargin = 0
          } else {
            seatObj.seatMargin = steps[totalItemCounter - 1]
          }
          /*if (totalItemCounter <= middle) {
            seatObj.seatMargin = step * (totalItemCounter - 1)
            nextStep = step * (totalItemCounter - 1)
          } else {
            if (totalItemCounter === middle + 1 && z === 0) {
              seatObj.seatMargin = nextStep
            } else if (totalItemCounter === middle + 1 && z !== 0) {
              seatObj.seatMargin = nextStep
            } else {
              seatObj.seatMargin = nextStep - step
              nextStep = nextStep - step
            }
          }*/
          totalItemCounter++
          mapObj.seats.push(seatObj)
        })
        this.seatmap.push(mapObj)
      })
    }
  }

  public async onSubmit(): Promise<void> {
    console.log(0)
    let numberControl = null
    const createSeatsForm = this.createSeatsForm
    if (createSeatsForm) {
      numberControl = createSeatsForm.get('number')
    }
    if (this.createSeatsForm.valid || numberControl) {
      const index = this.loadedSeatMap.sections.length - 1
      console.log(1)
      if (!this.loadedSeatMap.sections[this.index]?.grid) {
        this.loadedSeatMap.sections[this.index] = Object.assign(
          {},
          this.loadedSeatMap.sections[this.index],
          {
            grid: this.layout
          }
        )
      } else {
        this.loadedSeatMap.sections[this.index].grid = this.layout
      }
      this.loadedSeatMap.sections[this.index].rows = this.createSeatsForm.get('rows')?.value
      this.loadedSeatMap.sections[this.index].cols = this.createSeatsForm.get('columns')?.value
      this.loadedSeatMap.sections[this.index].curve = this.createSeatsForm.get('curve')?.value
      this.loadedSeatMap.sections[this.index].label = this.createSeatsForm.get('label')?.value
      this.loadedSeatMap.sections[this.index].rowlabel = this.createSeatsForm.get('rowlabel')?.value
      this.loadedSeatMap.sections[this.index].letters = true
      this.loadedSeatMap.sections[this.index].seatsWithoutSeats =
        this.createSeatsForm.get('number')?.value
      if (this.general === false) {
        console.log('Asientos')
        this.loadedSeatMap.sections[this.index].seatsWithoutSeats = null
      } else {
        console.log('General')
        this.loadedSeatMap.sections[this.index].grid = []
        this.loadedSeatMap.sections[this.index].rows = null
        this.loadedSeatMap.sections[this.index].cols = null
        this.loadedSeatMap.sections[this.index].curve = null
      }
      const seatMap = {
        name: this.loadedSeatMap.name,
        stage: this.loadedSeatMap.stage,
        sections: this.loadedSeatMap.sections,
        access: this.loadedSeatMap.access,
        toilets: this.loadedSeatMap.toilets
      }
      console.log('seatmep: ', seatMap)
      this.submitted = true
      ;(await this.seatMapsService.updateOne(this.loadedSeatMap.id, seatMap)).subscribe({
        next: async response => {
          Swal.fire('Asientos configurados correctamente', '', 'success')
          this.router.navigate(['/home/seat-map-general'], {
            state: { mode: 'edit', seatMap: response.seatMap }
          })
        },
        complete: () => {},
        error: error => {
          console.log(error)
        }
      })
    }
  }

  public async generateSeats(option: string) {
    if (this.createSeatsForm.valid) {
      let labels = this.createSeatsForm.get('label')?.value
      let rowLabels = this.createSeatsForm.get('rowlabel')?.value
      if (labels === undefined) {
        labels = ''
      }
      if (rowLabels === undefined) {
        rowLabels = ''
      }
      const processedLabels = labels.split(',')
      const processedRowLabels = rowLabels.split(',')
      if (
        labels.length !== 0 &&
        processedLabels.length !== this.createSeatsForm.get('columns')?.value
      ) {
        Swal.fire('No coinciden número de columnas y etiquetas', '', 'error')
      } else if (
        rowLabels.length !== 0 &&
        processedRowLabels.length !== this.createSeatsForm.get('rows')?.value
      ) {
        Swal.fire('No coinciden número de filas y etiquetas', '', 'error')
      } else {
        if (option === 'edit') {
          this.layout = this.loadedSeatMap.sections[this.index].grid
          this.seatConfig = this.layout
          this.processSeatChart(this.seatConfig)
        } else {
          this.layout = []
          const rows = this.createSeatsForm.get('rows')?.value
          const columns = this.createSeatsForm.get('columns')?.value
          for (let i = 0; i < rows; i++) {
            let seat_map = {
              seat_label: i + 1,
              layout: '1'.repeat(columns)
            }
            this.layout.push(seat_map)
          }
          this.seatConfig = this.layout
          this.processSeatChart(this.seatConfig)
        }
      }
    }
  }

  public selectSeat(seatObject: any) {
    console.log('seatObject: ', seatObject)
    const selectedSeat = seatObject.key.split('_')
    const row = Number(selectedSeat[0])
    const seat = Number(selectedSeat[1])
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: true,
      timerProgressBar: true,
      width: 'auto',
      buttonsStyling: true,
      confirmButtonColor: '#5db2e4'
    })
    Toast.fire({
      title: '¿Quieres añadir imagen del asiento?',
      confirmButtonText: 'Añadir Imagen',
      cancelButtonText: 'No',
      position: 'center',
      showCancelButton: true
    }).then(async result => {
      if (result.isConfirmed) {
        const { value: file } = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            accept: 'image/*',
            'aria-label': 'Upload your profile picture'
          },
          showCancelButton: true,
          confirmButtonText: 'Añadir',
          cancelButtonText: 'Cancelar',
          position: 'center'
        })

        if (file) {
          console.log('file: ', file)
          this.selectFile(file, seatObject)
        }
      } else {
        const titleBloquear = seatObject.status === 'available' ? 'Bloquear' : 'Desbloquear'
        Toast.fire({
          title: '¿Quieres ' + titleBloquear + ' el asiento?',
          confirmButtonText: titleBloquear,
          cancelButtonText: 'Cancelar',
          position: 'center',
          showCancelButton: true
        }).then(result => {
          if (result.isConfirmed) {
            if (seatObject.status === 'available') {
              const newLine =
                this.layout[row - 1].layout.substring(0, seat - 1) +
                '0' +
                this.layout[row - 1].layout.substring(seat)
              this.layout[row - 1].layout = newLine
              seatObject.status = 'booked'
            } else if (seatObject.status === 'booked') {
              const newLine =
                this.layout[row - 1].layout.substring(0, seat - 1) +
                '1' +
                this.layout[row - 1].layout.substring(seat)
              this.layout[row - 1].layout = newLine
              seatObject.status = 'available'
            }
          }
        })
      }
    })
  }

  async selectFile(fileReceived: File, seatObject: any) {
    this.selectedFile = fileReceived
    console.log('this.selectedFile: ', this.selectedFile)
    const file: File = fileReceived
    ;(await this.filesService.create(file)).subscribe({
      next: async response => {
        Swal.fire('Imagen subida correctamente', '', 'success')
        this.selectedFile = response.file
        this.selectedFileName = response.file.url
        const column = seatObject.key.split('_')[1] - 1
        const images: any = {}
        images[column] = this.selectedFile.id
        const imageSeat = {
          sectionName: this.section.name,
          seatLabel: Number(seatObject.key.split('_')[0]),
          images: images
        }
        console.log('imageSeat: ', imageSeat)
        ;(await this.seatMapsService.updateSeatImages(this.loadedSeatMap.id, imageSeat)).subscribe({
          next: async response => {
            console.log('response: ', response)
            /* Swal.fire('Asientos configurados correctamente', '', 'success')
            this.router.navigate(['/home/seat-map-general'], {
              state: { mode: 'edit', seatMap: this.loadedSeatMap }
            }) */
          }
        })
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  public radioChange() {
    this.general = !this.general
    console.log('this.general: ', this.general)
  }
}
function complete(error: any): void {
  throw new Error('Function not implemented.')
}
