import { firstValueFrom } from 'rxjs'
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { ActivatedRoute, Router } from '@angular/router'
import { IEnclosure } from 'src/app/shared/models/enclosures.model'
import { AccountService } from 'src/app/shared/services/account.service'
import { EnclosuresService } from 'src/app/shared/services/enclosures.service'
import { EventsService } from 'src/app/shared/services/events.service'
import { FilesService } from 'src/app/shared/services/files.service'
import { GeolocationService } from 'src/app/shared/services/geolocation.service'
import { SeatMapsService } from 'src/app/shared/services/seat-maps.service'
import { SessionsService } from 'src/app/shared/services/sessions.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-event-sessions',
  templateUrl: './event-sessions.component.html',
  styleUrls: ['./event-sessions.component.scss']
})
export class EventSessionsComponent implements OnInit {
  private loadedEvent: any
  mode: string = 'create'
  public createSessionsForm!: FormGroup
  public typeForm!: FormGroup
  loading = false
  submitted = false
  perpetual = false
  selectedFile: any
  selectedFileName: string = ''
  public newEnclosure!: IEnclosure
  enclosuresList: any
  seatMapList: any
  private sessionType = 'Estándar'
  whiteList: any[] = []
  blackList: any[] = []
  dataSource = ['1', '2', '3', '4', '5', '6', '7', '8']
  displayedColumns: string[] = ['date', 'actions']
  grid: any
  sections: any
  sectionGrid: any
  public nMaxTickets: number = 5

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private geolocationsrv: GeolocationService,
    private sessionsService: SessionsService,
    private filesService: FilesService,
    private enclosuresService: EnclosuresService,
    private seatMapsService: SeatMapsService,
    private eventsService: EventsService
  ) {}

  ngOnInit(): void {
    //this.whiteList = new MatTableDataSource(this.whiteListArray)
    //this.blackList = new MatTableDataSource(this.blackListArray)
    this.createSessionsForm = this.fb.group({
      initDate: ['', Validators.required],
      endDate: ['', Validators.required],
      whiteDatepicker: [''],
      blackDatepicker: [''],
      seatMap: [''],
      enclosure: ['', Validators.required],
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      sunday: [false],
      mondaySelect: ['1'],
      tuesdaySelect: ['1'],
      wednesdaySelect: ['1'],
      thursdaySelect: ['1'],
      fridaySelect: ['1'],
      saturdaySelect: ['1'],
      sundaySelect: ['1'],
      totalCapacity: [1],
      nMaxTickets: [5, Validators.min(1)]
    })
    this.typeForm = this.fb.group({
      type: ['Estándar']
    })
    this.loadedEvent = history.state.event
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit') {
      this.editMode()
    } else if (this.mode === 'watch') {
      this.watchMode()
    }
    this.getEnclosure()
  }

  radioChange(event: any) {
    this.sessionType = event.value
    this.perpetual = !this.perpetual
  }

  editMode() {
    console.log('edit mode')
  }

  watchMode() {
    console.log('watch mode')
  }

  async getEnclosure() {
    ;(await this.enclosuresService.getAll()).subscribe({
      next: async (response: any) => {
        this.enclosuresList = response.enclosures
        /*if (this.mode === 'edit' || this.mode === 'watch') {
          this.createEventForm.controls['enclosure'].setValue(this.loadedEvent.enclosure)
        }*/
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  public async onEnclosureChange(event: any) {
    ;(await this.seatMapsService.getAllbyEnclosure(event.value.id)).subscribe({
      next: async (response: any) => {
        this.seatMapList = response.seatmaps
        /*if (this.mode === 'edit' || this.mode === 'watch') {
          this.createEventForm.controls['enclosure'].setValue(this.loadedEvent.enclosure)
        }*/
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  async getSections() {
    /*coger las secciones de el mapa de asientos y crear en array de sections prices*/
    let selectedSeatMap = this.createSessionsForm.get('seatMap')?.value
    let sectionPrices = []
    for (let i = 0; i < selectedSeatMap.sections.length; i++) {
      if (selectedSeatMap.sections[i].name && selectedSeatMap.sections[i].color) {
        let item = {
          name: selectedSeatMap.sections[i].name,
          price: 0,
          color: selectedSeatMap.sections[i].color
        }
        sectionPrices.push(item)
      }
    }
    return sectionPrices
  }

  public async onSubmit(): Promise<void> {
    await this.getSectionGrid(this.createSessionsForm.get('seatMap')?.value.id)
  }

  async getSectionGrid(seatMapId: string): Promise<any> {
    console.log('seatMapId: ', seatMapId)
    this.sectionGrid = []
    ;(await this.seatMapsService.getOne(seatMapId)).subscribe({
      next: async response => {
        this.sections = response.seatMap.sections
        const sgrids = []
        for (let i = 0; i < this.sections.length; i++) {
          let sectionGrid = {
            nameSection: this.sections[i].name,
            occupiedSeats: this.sections[i].occupiedSeats || 0,
            totalSeats: this.sections[i].totalSeats || 0,
            occupiedSeatsPercen: this.sections[i].occupiedSeatsPercen || 0,
            seatsWithoutSeats: this.sections[i].seatsWithoutSeats || null,
            rowSection: this.sections[i].grid || []
          }
          sgrids.push(sectionGrid)
        }
        console.log('sgrids: ', sgrids)
        this.sectionGrid = sgrids
        //this.sectionGrid = this.sections
        /* this.sectionGrid = [
          '086416a6-d82b-4585-b5e6-3f5428469ff5',
          '618327f2-bfc5-4347-b466-e888314b3eb6',
          '6d2231c8-a52b-45f3-ba50-caaab108c766',
          '8a49a0fd-80cc-43e0-943c-564b6cf12196'
        ] */

        console.log('sectionGrid: ', this.sectionGrid)
      },
      complete: async () => {
        if (this.createSessionsForm.valid) {
          let dateArray: any[] = []
          let fromDate = this.createSessionsForm.get('initDate')?.value
          let toDate = this.createSessionsForm.get('endDate')?.value
          this.nMaxTickets = this.createSessionsForm.get('nMaxTickets')?.value
          if (fromDate > toDate) {
            Swal.fire(
              'La fecha de finalización no puede ser anterior a la fecha de inicio',
              '',
              'error'
            )
          } else {
            let initDate = new Date()
            initDate.setDate(fromDate.getDate())
            initDate.setFullYear(fromDate.getFullYear())
            initDate.setMonth(fromDate.getMonth())
            let nextDate = fromDate
            let index = 0
            let sesionsNumber = [0, 0, 0, 0, 0, 0, 0]
            if (this.createSessionsForm.get('monday')?.value) {
              sesionsNumber[0] = parseInt(this.createSessionsForm.get('mondaySelect')?.value)
            }
            if (this.createSessionsForm.get('tuesday')?.value) {
              sesionsNumber[1] = parseInt(this.createSessionsForm.get('tuesdaySelect')?.value)
            }
            if (this.createSessionsForm.get('wednesday')?.value) {
              sesionsNumber[2] = parseInt(this.createSessionsForm.get('wednesdaySelect')?.value)
            }
            if (this.createSessionsForm.get('thursday')?.value) {
              sesionsNumber[3] = parseInt(this.createSessionsForm.get('thursdaySelect')?.value)
            }
            if (this.createSessionsForm.get('friday')?.value) {
              sesionsNumber[4] = parseInt(this.createSessionsForm.get('fridaySelect')?.value)
            }
            if (this.createSessionsForm.get('saturday')?.value) {
              sesionsNumber[5] = parseInt(this.createSessionsForm.get('saturdaySelect')?.value)
              console.log(332, sesionsNumber[5])
            }
            if (this.createSessionsForm.get('sunday')?.value) {
              sesionsNumber[6] = parseInt(this.createSessionsForm.get('sundaySelect')?.value)
              console.log(222, sesionsNumber[6])
            }
            while (nextDate <= toDate) {
              let dateToPush = new Date()
              console.log(77777, dateToPush)
              dateToPush.setDate(nextDate.getDate())
              dateToPush.setFullYear(nextDate.getFullYear())
              dateToPush.setMonth(nextDate.getMonth())
              console.log(555, dateToPush)
              let numberofSessions = 0
              let day = dateToPush.getDay()
              if (day === 0) {
                day = 7
              }
              console.log(1111, day)
              for (let i = 0; i < sesionsNumber.length; i++) {
                if (i === day - 1) {
                  numberofSessions = sesionsNumber[i]
                }
              }
              for (let j = 0; j < numberofSessions; j++) {
                dateArray.push(new Date(dateToPush))
              }
              console.log(99, dateArray)
              nextDate.setDate(fromDate.getDate() + 1)
              index++
            }
            const sectionsPrices = await this.getSections()
            console.log('sectiongird: ', this.sectionGrid)
            const newSessions = {
              name: 'string',
              event: this.loadedEvent.id,
              date: dateArray[0],
              endDate: null,
              initHour: '00:00',
              endHour: '00:00',
              seatMap: this.createSessionsForm.get('seatMap')?.value.id,
              sectionsPrices: sectionsPrices,
              sectionsGrids: this.sectionGrid,
              nMaxTickets: this.nMaxTickets
            }
            console.log(444, newSessions)

            this.submitted = true
            ;(await this.sessionsService.create(newSessions)).subscribe({
              next: async response => {
                const sessions = this.loadedEvent.sessions
                console.log(response)
                //   sessions.push(response.session)

                firstValueFrom(
                  await this.eventsService.updateOne(this.loadedEvent.id, { sessions })
                )

                console.log(111, dateArray.length)
                if (dateArray.length === 1) {
                  Swal.fire('Sesiones creadas correctamente', '', 'success')
                  this.router.navigate(['/home/event-sessions-details'], {
                    state: { mode: 'watch', event: this.loadedEvent }
                  })
                } else if (dateArray.length > 1) {
                  console.log(69999)
                  Swal.fire('Creando sesiones', '', 'info')
                  this.createManySessions(dateArray, sectionsPrices)
                  // this.router.navigate(['/home/event-sessions-details'], {
                  //   state: { mode: 'watch', event: this.loadedEvent }
                  // })
                }
              },
              complete: () => {},
              error: error => {
                console.log(error)
              }
            })
          }
        }
      },
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  public async savePerpetual() {
    let fromDate = this.createSessionsForm.get('initDate')?.value
    let toDate = this.createSessionsForm.get('endDate')?.value
    let totalCapacity = this.createSessionsForm.get('totalCapacity')?.value
    if (fromDate === null || toDate === null || fromDate > toDate || isNaN(totalCapacity)) {
      Swal.fire('La fecha de finalización no puede ser anterior a la fecha de inicio', '', 'error')
    } else {
      let sesionsNumber = []
      if (this.createSessionsForm.get('monday')?.value) {
        sesionsNumber.push('lunes')
      }
      if (this.createSessionsForm.get('tuesday')?.value) {
        sesionsNumber.push('martes')
      }
      if (this.createSessionsForm.get('wednesday')?.value) {
        sesionsNumber.push('miércoles')
      }
      if (this.createSessionsForm.get('thursday')?.value) {
        sesionsNumber.push('jueves')
      }
      if (this.createSessionsForm.get('friday')?.value) {
        sesionsNumber.push('viernes')
      }
      if (this.createSessionsForm.get('saturday')?.value) {
        sesionsNumber.push('sábado')
      }
      if (this.createSessionsForm.get('sunday')?.value) {
        sesionsNumber.push('domingo')
      }
      let event = {
        name: this.loadedEvent.name,
        description: this.loadedEvent.description,
        category: this.loadedEvent.category,
        subcategory: this.loadedEvent.subcategory,
        enclosure: this.loadedEvent.enclosure,
        user: this.loadedEvent.user,
        organizator: this.loadedEvent.organizator,
        eventTime: this.loadedEvent.eventTime,
        eventProgram: this.loadedEvent.eventProgram,
        initDate: this.loadedEvent.initDate,
        endDate: this.loadedEvent.endDate,
        initTime: this.loadedEvent.initTime,
        endTime: this.loadedEvent.endTime,
        sessions: this.loadedEvent.sessions,
        totalCapacity: totalCapacity,
        picture: this.loadedEvent.picture,
        eventProgram2: this.loadedEvent.eventProgram2,
        //users: this.createEventForm.get('users')?.value,
        validator: this.loadedEvent.validator,
        taquillero: this.loadedEvent.taquillero,
        perpetualEvent: true,
        whitelist: this.whiteList,
        blacklist: this.blackList,
        days: sesionsNumber
        //program: this.createEventForm.get('program')?.value
      }

      this.submitted = true
      console.log('editedEvent: ', event)
      ;(await this.eventsService.updateOne(this.loadedEvent.id, event)).subscribe({
        next: async response => {
          Swal.fire('Evento editado correctamente', '', 'success')
          this.router.navigate(['/home/perpetual-sessions-details'], {
            state: { mode: 'create', event: response.event }
          })
        },
        complete: () => {},
        error: error => {
          console.log(error)
        }
      })
    }
  }

  public editPerpetual() {
    let fromDate = this.createSessionsForm.get('initDate')?.value
    let toDate = this.createSessionsForm.get('endDate')?.value
  }

  private async createManySessions(dateArray: any, sectionsPrices: any) {
    for (let i = 1; i < dateArray.length; i++) {
      await this.createSession(dateArray[i], sectionsPrices, i, dateArray.length)
    }
    this.router.navigate(['/home/event-sessions-details'], {
      state: { mode: 'watch', event: this.loadedEvent }
    })
  }

  private async createSession(
    date: any,
    sectionsPrices: any,
    currentIndex: number,
    totalSessions: number
  ) {
    await new Promise(f => setTimeout(f, 700))
    const newSessions = {
      name: 'string',
      event: this.loadedEvent.id,
      date: date,
      endDate: null,
      initHour: '00:00',
      endHour: '00:00',
      seatMap: this.createSessionsForm.get('seatMap')?.value.id,
      sectionsPrices: sectionsPrices,
      sectionsGrids: this.sectionGrid,
      nMaxTickets: this.nMaxTickets
    }

    try {
      const response = await (await this.sessionsService.create(newSessions)).toPromise()
      console.log(this.loadedEvent.sessions)
      if (this.loadedEvent.sessions == undefined) {
        this.loadedEvent.sessions = []
      }
      const sessions = this.loadedEvent.sessions
      console.log(1111, sessions)
      console.log(22222, response.session)
      sessions.push(response.session)
      console.log('sessions: ', sessions)

      const updatedEvent = await (
        await this.eventsService.updateOne(this.loadedEvent.id, { sessions })
      ).toPromise()

      if (currentIndex === totalSessions - 1) {
        // Swal.fire('Sesiones creadas correctamente', '', 'success')
        // this.router.navigate(['/home/event-sessions-details'], {
        //   state: { mode: 'watch', event: updatedEvent }
        // })
      }
    } catch (error) {
      console.error(error)
    }
  }

  public edit() {
    console.log('edit function')
  }

  gotoEventDetail() {
    this.router.navigate(['/home/event-details'], {
      state: { mode: 'edit', event: this.loadedEvent }
    })
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2
  }

  deleteFromBlackList(index: any) {
    this.blackList.splice(index, 1)
  }

  deleteFromWhiteList(index: any) {
    this.whiteList.splice(index, 1)
  }

  addDateWhiteList(type: string, event: MatDatepickerInputEvent<Date>) {
    this.whiteList.push(event.value)
  }

  addDateBlackList(type: string, event: MatDatepickerInputEvent<Date>) {
    this.blackList.push(event.value)
    // this.blackList = new MatTableDataSource(this.blackListArray)
    // this.blackList.push(`${type}: ${event.value}`);
  }
}
