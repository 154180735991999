<div class="sessions-prices">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Precios de sesión</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar sección</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar sección</mat-card-title>
    <mat-card-content>
      <table mat-table [dataSource]="listPrices">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef>Color</th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button [ngStyle]="{ 'background-color': element.color }">
              Color de {{ element.name }}
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Precio</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input
                matInput
                placeholder="Precio"
                [value]="element.price"
                [(ngModel)]="element.price" />
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <!--<mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Número de filas</mat-label>
            <input type="number" matInput formControlName="rows" />
            <div *ngIf="submitted && ['f.rows.errors']" class="invalid-feedback">
              <div *ngIf="['f.rows.errors.required']">rows is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Número de columnas</mat-label>
            <input type="number" matInput formControlName="columns" />
            <div *ngIf="submitted && ['f.columns.errors']" class="invalid-feedback">
              <div *ngIf="['f.columns.errors.required']">columns is required</div>
            </div>
          </mat-form-field>-->
      <!--<div class="form-group">
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición X</mat-label>
            <input type="number" matInput formControlName="x" />
            <div *ngIf="submitted && ['f.number.errors']" class="invalid-feedback">
              <div *ngIf="['f.x.errors.required']">x is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición Y</mat-label>
            <input type="number" matInput formControlName="y" />
            <div *ngIf="submitted && ['f.y.errors']" class="invalid-feedback">
              <div *ngIf="['f.y.errors.required']">y is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición X2</mat-label>
            <input type="number" matInput formControlName="x2" />
            <div *ngIf="submitted && ['f.x2.errors']" class="invalid-feedback">
              <div *ngIf="['f.x2.errors.required']">x is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición Y2</mat-label>
            <input type="number" matInput formControlName="y2" />
            <div *ngIf="submitted && ['f.y2.errors']" class="invalid-feedback">
              <div *ngIf="['f.y2.errors.required']">y is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición X3</mat-label>
            <input type="number" matInput formControlName="x3" />
            <div *ngIf="submitted && ['f.x3.errors']" class="invalid-feedback">
              <div *ngIf="['f.x3fa-flip-vertical.errors.required']">x is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición Y3</mat-label>
            <input type="number" matInput formControlName="y3" />
            <div *ngIf="submitted && ['f.y3.errors']" class="invalid-feedback">
              <div *ngIf="['f.y3.errors.required']">y is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición X4</mat-label>
            <input type="number" matInput formControlName="x4" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición Y4</mat-label>
            <input type="number" matInput formControlName="y4" />
          </mat-form-field>
        </div>-->
      <div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="loading"
          class="actionButton"
          (click)="save()"
          *ngIf="mode === 'edit'">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="loading"
          class="actionButton"
          (click)="save()"
          *ngIf="mode === 'create'">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
        </button>
        <!--<i class="fa fa-i-cursor" aria-hidden="true"></i><button
            type="button"
            mat-raised-button
            color="primary"
            [disabled]="loading"
            (click)="preview()"
            class="actionButton">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Previsualizar
          </button>-->
        <button mat-raised-button (click)="gotoList()">Volver</button>
      </div>
      <div>
        <svg
          #svg
          appSvgInit
          (newItemEvent)="update($event)"
          style="border: 1px solid black"
          stroke="none"
          width="1000"
          height="700"
          xmlns="<http://www.w3.org/2000/svg>"></svg>
      </div>
    </mat-card-content>
  </mat-card>
</div>
