/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AccountService } from 'src/app/shared/services/account.service'
import { EnclosuresService } from 'src/app/shared/services/enclosures.service'
import { FilesService } from 'src/app/shared/services/files.service'
import { GeolocationService } from 'src/app/shared/services/geolocation.service'
import { environment } from 'src/environments/environment'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public createUserForm!: FormGroup
  public createBusinessUserForm!: FormGroup
  public typeForm!: FormGroup
  loading = false
  submitted = false
  isBusiness = true
  private userType = 'Empresa'
  loadedUser: any
  mode = 'create'
  selectedFile: any
  selectedFileName: string = ''
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private geolocationsrv: GeolocationService,
    private enclosuresService: EnclosuresService,
    private filesService: FilesService,
    private http: HttpClient
  ) {}
  ngOnInit(): void {
    this.typeForm = this.fb.group({
      type: ['Empresa']
    })
    this.createBusinessUserForm = this.fb.group({
      address: ['', Validators.required],
      name: ['', Validators.required],
      number: ['', Validators.required],
      floor: [''],
      zip: [''],
      city: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      businessName: ['', Validators.required],
      cif: ['', Validators.required],
      web: [''],
      surname1: ['', Validators.required],
      phone: ['', Validators.required]
    })
    this.createUserForm = this.fb.group({
      address: ['', Validators.required],
      name: ['', Validators.required],
      number: ['', Validators.required],
      floor: [''],
      zip: [''],
      city: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      businessName: ['', Validators.required],
      cif: ['', Validators.required],
      web: [''],
      surname1: ['', Validators.required],
      surname2: [''],
      phone: ['', Validators.required]
    })
    this.loadedUser = history.state.user
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      this.setLoadedData()
    }
  }

  setLoadedData() {
    if (this.loadedUser.userType === 'Empresa') {
      this.createBusinessUserForm.controls['name'].setValue(this.loadedUser.name)
      this.createBusinessUserForm.controls['address'].setValue(this.loadedUser.address.address)
      this.createBusinessUserForm.controls['number'].setValue(this.loadedUser.address.number)
      this.createBusinessUserForm.controls['floor'].setValue(this.loadedUser.address.floor)
      this.createBusinessUserForm.controls['zip'].setValue(this.loadedUser.address.zip)
      this.createBusinessUserForm.controls['city'].setValue(this.loadedUser.address.city)
      this.createBusinessUserForm.controls['email'].setValue(this.loadedUser.email)
      this.createBusinessUserForm.controls['password'].setValue(this.loadedUser.password)
      this.createBusinessUserForm.controls['businessName'].setValue(this.loadedUser.businessName)
      this.createBusinessUserForm.controls['cif'].setValue(this.loadedUser.dniCif)
      this.createBusinessUserForm.controls['surname1'].setValue(this.loadedUser.surname1)
      this.createBusinessUserForm.controls['web'].setValue(this.loadedUser.web)
      this.createBusinessUserForm.controls['phone'].setValue(this.loadedUser.phone)
      this.userType = this.loadedUser.userType
    } else {
      this.createUserForm.controls['name'].setValue(this.loadedUser.name)
      this.createUserForm.controls['address'].setValue(this.loadedUser.address.address)
      this.createUserForm.controls['number'].setValue(this.loadedUser.address.number)
      this.createUserForm.controls['floor'].setValue(this.loadedUser.address.floor)
      this.createUserForm.controls['zip'].setValue(this.loadedUser.address.zip)
      this.createUserForm.controls['city'].setValue(this.loadedUser.address.city)
      this.createUserForm.controls['email'].setValue(this.loadedUser.email)
      this.createUserForm.controls['password'].setValue(this.loadedUser.password)
      this.createUserForm.controls['businessName'].setValue(this.loadedUser.businessName)
      this.createUserForm.controls['cif'].setValue(this.loadedUser.dniCif)
      this.createUserForm.controls['surname1'].setValue(this.loadedUser.surname1)
      this.createUserForm.controls['surname2'].setValue(this.loadedUser.surname2)
      this.createUserForm.controls['web'].setValue(this.loadedUser.web)
      this.createUserForm.controls['phone'].setValue(this.loadedUser.phone)
      this.typeForm.controls['type'].setValue('Particular')
      this.userType = this.loadedUser.userType
      this.isBusiness = false
    }
    this.selectedFileName = this.loadedUser.avatar?.url
  }

  radioChange(event: any) {
    this.userType = event.value
    this.isBusiness = !this.isBusiness
  }

  uploadFileEvt(event: any) {
    console.log('lalala')
  }
  /*
  setTypeValue(gender: string): void {
    this.typeForm.get('type').setValue(gender)
  }*/

  public async createUser(location: any): Promise<void> {
    if (this.mode === 'edit') {
      this.editUser(location)
    } else {
      const address =
        this.createUserForm.get('address')?.value + ', ' + this.createUserForm.get('city')?.value
      const res = await this.geolocationsrv.verifyAddress(address)
      const newUser = {
        name: this.createUserForm.get('name')?.value,
        email: this.createUserForm.get('email')?.value,
        password: this.createUserForm.get('password')?.value,
        businessName: this.createUserForm.get('businessName')?.value,
        phone: this.createUserForm.get('phone')?.value,
        dniCif: this.createUserForm.get('cif')?.value,
        web: this.createUserForm.get('web')?.value,
        surname1: this.createUserForm.get('surname1')?.value,
        surname2: this.createUserForm.get('surname1')?.value,
        address: {
          address: this.createUserForm.get('address')?.value,
          number: this.createUserForm.get('number')?.value,
          floor: this.createUserForm.get('floor')?.value,
          zip: this.createUserForm.get('zip')?.value,
          city: this.createUserForm.get('city')?.value,
          geolocation: {
            lon: location.lng,
            lat: location.lat
          }
        },
        avatar: {},
        role: ['user', 'eventManager'],
        location: 'string',
        userType: this.userType
      }
      this.submitted = true
      ;(await this.accountService.create(newUser)).subscribe({
        next: async response => {
          Swal.fire('Usuario creado correctamente', '', 'success')
          this.router.navigate(['/home/users-list'])
        },
        complete: () => {},
        error: error => {
          Swal.fire(
            'No se ha podido crear el usuario, el servidor ha reportado el siguiente error:',
            error.error.message,
            'error'
          )
        }
      })
    }
  }

  public async editUser(location: any): Promise<void> {
    let editedUser: any
    if (this.selectedFile) {
      editedUser = {
        name: this.createUserForm.get('name')?.value,
        email: this.createUserForm.get('email')?.value,
        password: this.createUserForm.get('password')?.value,
        businessName: this.createUserForm.get('businessName')?.value,
        phone: this.createUserForm.get('phone')?.value,
        dniCif: this.createUserForm.get('cif')?.value,
        web: this.createUserForm.get('web')?.value,
        surname1: this.createUserForm.get('surname1')?.value,
        surname2: this.createUserForm.get('surname1')?.value,
        address: {
          address: this.createUserForm.get('address')?.value,
          number: this.createUserForm.get('number')?.value,
          floor: this.createUserForm.get('floor')?.value,
          zip: this.createUserForm.get('zip')?.value,
          city: this.createUserForm.get('city')?.value,
          geolocation: {
            lon: location.lng,
            lat: location.lat
          }
        },
        avatar: this.selectedFile,
        role: ['user', 'eventManager'],
        location: 'string',
        userType: this.userType
      }
    } else {
      editedUser = {
        name: this.createUserForm.get('name')?.value,
        email: this.createUserForm.get('email')?.value,
        password: this.createUserForm.get('password')?.value,
        businessName: this.createUserForm.get('businessName')?.value,
        phone: this.createUserForm.get('phone')?.value,
        dniCif: this.createUserForm.get('cif')?.value,
        web: this.createUserForm.get('web')?.value,
        surname1: this.createUserForm.get('surname1')?.value,
        surname2: this.createUserForm.get('surname1')?.value,
        address: {
          address: this.createUserForm.get('address')?.value,
          number: this.createUserForm.get('number')?.value,
          floor: this.createUserForm.get('floor')?.value,
          zip: this.createUserForm.get('zip')?.value,
          city: this.createUserForm.get('city')?.value,
          geolocation: {
            lon: location.lng,
            lat: location.lat
          }
        },
        role: ['user', 'eventManager'],
        location: 'string',
        userType: this.userType
      }
    }
    this.submitted = true
    ;(await this.accountService.updateOne(this.loadedUser.id, editedUser)).subscribe({
      next: async response => {
        Swal.fire('Usuario editado correctamente', '', 'success')

        this.router.navigate(['/home/users-list'])
      },
      complete: () => {},
      error: error => {
        Swal.fire(
          'No se ha podido editar el usuario, el servidor ha reportado el siguiente error:',
          error.error.message,
          'error'
        )
      }
    })
  }

  public async createBusinessUser(location: any): Promise<void> {
    if (this.mode === 'edit') {
      this.editBusinessUser(location)
    } else {
      const newUser = {
        name: this.createBusinessUserForm.get('name')?.value,
        email: this.createBusinessUserForm.get('email')?.value,
        password: this.createBusinessUserForm.get('password')?.value,
        businessName: this.createBusinessUserForm.get('businessName')?.value,
        phone: this.createBusinessUserForm.get('phone')?.value,
        dniCif: this.createUserForm.get('cif')?.value,
        web: this.createUserForm.get('web')?.value,
        surname1: this.createUserForm.get('surname1')?.value,
        address: {
          address: this.createBusinessUserForm.get('address')?.value,
          number: this.createBusinessUserForm.get('number')?.value,
          floor: this.createBusinessUserForm.get('floor')?.value,
          zip: this.createBusinessUserForm.get('zip')?.value,
          city: this.createBusinessUserForm.get('city')?.value,
          geolocation: {
            lon: location.lng,
            lat: location.lat
          }
        },
        avatar: {},
        role: ['user', 'eventManager'],
        location: 'string',
        userType: this.userType
      }
      this.submitted = true
      ;(await this.accountService.create(newUser)).subscribe({
        next: async response => {
          Swal.fire('Usuario creado correctamente', '', 'success')
          this.router.navigate(['/home/users-list'])
        },
        complete: () => {},
        error: error => {
          Swal.fire(
            'No se ha podido crear el usuario, el servidor ha reportado el siguiente error:',
            error.error.message,
            'error'
          )
        }
      })
    }
  }

  public async editBusinessUser(location: any): Promise<void> {
    let editedUser: any
    if (this.selectedFile) {
      editedUser = {
        name: this.createBusinessUserForm.get('name')?.value,
        email: this.createBusinessUserForm.get('email')?.value,
        password: this.createBusinessUserForm.get('password')?.value,
        businessName: this.createBusinessUserForm.get('businessName')?.value,
        phone: this.createBusinessUserForm.get('phone')?.value,
        dniCif: this.createUserForm.get('cif')?.value,
        web: this.createUserForm.get('web')?.value,
        surname1: this.createUserForm.get('surname1')?.value,
        address: {
          address: this.createBusinessUserForm.get('address')?.value,
          number: this.createBusinessUserForm.get('number')?.value,
          floor: this.createBusinessUserForm.get('floor')?.value,
          zip: this.createBusinessUserForm.get('zip')?.value,
          city: this.createBusinessUserForm.get('city')?.value,
          geolocation: {
            lon: location.lng,
            lat: location.lat
          }
        },
        avatar: this.selectedFile,
        role: ['user', 'eventManager'],
        location: 'string',
        userType: this.userType
      }
    } else {
      editedUser = {
        name: this.createBusinessUserForm.get('name')?.value,
        email: this.createBusinessUserForm.get('email')?.value,
        password: this.createBusinessUserForm.get('password')?.value,
        businessName: this.createBusinessUserForm.get('businessName')?.value,
        phone: this.createBusinessUserForm.get('phone')?.value,
        dniCif: this.createUserForm.get('cif')?.value,
        web: this.createUserForm.get('web')?.value,
        surname1: this.createUserForm.get('surname1')?.value,
        address: {
          address: this.createBusinessUserForm.get('address')?.value,
          number: this.createBusinessUserForm.get('number')?.value,
          floor: this.createBusinessUserForm.get('floor')?.value,
          zip: this.createBusinessUserForm.get('zip')?.value,
          city: this.createBusinessUserForm.get('city')?.value,
          geolocation: {
            lon: location.lng,
            lat: location.lat
          }
        },
        role: ['user', 'eventManager'],
        location: 'string',
        userType: this.userType
      }
    }
    this.submitted = true
    ;(await this.accountService.updateOne(this.loadedUser.id, editedUser)).subscribe({
      next: async response => {
        Swal.fire('Usuario editado correctamente', '', 'success')
        this.router.navigate(['/home/users-list'])
      },
      complete: () => {},
      error: error => {
        Swal.fire(
          'No se ha podido editar el usuario, el servidor ha reportado el siguiente error:',
          error.error.message,
          'error'
        )
      }
    })
  }

  gotoList() {
    this.router.navigate(['/home/users-list'])
  }

  async selectFile(event: any) {
    this.selectedFile = event.target.files
    const file: File = this.selectedFile.item(0)
    ;(await this.filesService.create(file)).subscribe({
      next: async response => {
        Swal.fire('Imagen subida correctamente', '', 'success')
        this.selectedFile = response.file
        this.selectedFileName = response.file.url
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  public async onSubmit(): Promise<void> {
    if (this.createUserForm.valid) {
      const address =
        this.createUserForm.get('address')?.value +
        ', ' +
        this.createUserForm.get('number')?.value +
        ', ' +
        this.createUserForm.get('city')?.value
      const url =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        address +
        environment.googleApiKey
      let locc
      this.http.post(url, locc).subscribe((res: any) => {
        if (res.status !== 'OK') {
          Swal.fire('Dirección errónea', '', 'error')
        } else {
          const location = res.results['0'].geometry.location
          this.createUser(location)
        }
      })
    }
  }

  public async onBusinessSubmit(): Promise<void> {
    if (this.createBusinessUserForm.valid) {
      const address =
        this.createBusinessUserForm.get('address')?.value +
        ', ' +
        this.createBusinessUserForm.get('number')?.value +
        ', ' +
        this.createBusinessUserForm.get('city')?.value
      const url =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        address +
        environment.googleApiKey
      let locc
      this.http.post(url, locc).subscribe((res: any) => {
        if (res.status !== 'OK') {
          Swal.fire('Dirección errónea', '', 'error')
        } else {
          const location = res.results['0'].geometry.location
          this.createBusinessUser(location)
        }
      })
    }
  }
}

/*
 let user: IUser = {
      username: '',
      email: '',
      name: '',
      surname: '',
      businessName: '',
      password: '',
      location: ' ',
      phone: ' ',
      avatar: {
        url: '',
        relativeDirectory: '',
        filename: '',
        mimetype: '',
        originalName: '',
        title: '',
        description: '',
        size: 0
      },
      address: {
        address: '',
        number: '',
        floor: '',
        zip: '',
        city: '',
        geolocation: {
          lon: 0,
          lat: 0
        }
      },
      role: ['user']
    }
*/
