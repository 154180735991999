/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { ActivatedRoute, Router } from '@angular/router'
import { firstValueFrom } from 'rxjs'
import { IEnclosure } from 'src/app/shared/models/enclosures.model'
import { AccountService } from 'src/app/shared/services/account.service'
import { CategoriesService } from 'src/app/shared/services/categories.service'
import { EnclosuresService } from 'src/app/shared/services/enclosures.service'
import { EventsService } from 'src/app/shared/services/events.service'
import { FilesService } from 'src/app/shared/services/files.service'
import { GeolocationService } from 'src/app/shared/services/geolocation.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  public createEventForm!: FormGroup
  loading = false
  submitted = false
  public newEvent!: IEnclosure
  categoriesList: any
  subCcategoriesList: any
  enclosuresList: any
  userList: any
  subCategoriesList: any
  loadedEvent: any
  mode = 'create'
  selectedFile: any
  selectedFileName: string = ''
  selectedProgram: any
  selectedProgramName: string = ''
  validatorsList: any
  taquillerosList: any
  evento: any
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private geolocationsrv: GeolocationService,
    private enclosuresService: EnclosuresService,
    private categoriesService: CategoriesService,
    private eventsService: EventsService,
    private filesService: FilesService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-GB')
  }
  async ngOnInit(): Promise<any> {
    this.createEventForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      user: ['', Validators.required],
      validator: ['', Validators.required],
      taquillero: ['', Validators.required],
      enclosure: ['', Validators.required],
      initDate: ['', Validators.required],
      endDate: ['', Validators.required],
      program: [''],
      administrationFees: [0, Validators.required]
    })
    this.loadedEvent = history.state.event
    console.log(this.loadedEvent)
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      this.evento = await firstValueFrom(await this.eventsService.getOne(this.loadedEvent.id))
      console.log(this.evento)
      this.setLoadedData()
    }
    await this.getCategories()
    await this.getUsers()
    await this.getTaquilleros()
    await this.getValidators()
    await this.getEnclosure()
  }

  setLoadedData() {
    this.createEventForm.controls['name'].setValue(this.evento.event.name)
    this.createEventForm.controls['description'].setValue(this.evento.event.description)
    this.selectedFileName = this.evento.event.picture?.url
    this.selectedProgramName = this.evento.event.eventProgram2?.url
    let initYear = this.evento.event.initDate.substring(0, 4)
    let initMonth = this.evento.event.initDate.substring(5, 7)
    let initDay = this.evento.event.initDate.substring(8, 10)
    let endYear = this.evento.event.endDate.substring(0, 4)
    let endMonth = this.evento.event.endDate.substring(5, 7)
    let endDay = this.evento.event.endDate.substring(8, 10)
    let initialDate = new Date()
    let endingDate = new Date()
    initialDate.setFullYear(initYear)
    initialDate.setMonth(parseInt(initMonth) - 1)
    initialDate.setDate(parseInt(initDay) + 1)
    endingDate.setFullYear(endYear)
    endingDate.setMonth(parseInt(endMonth) - 1)
    endingDate.setDate(parseInt(endDay) + 1)
    this.createEventForm.controls['initDate'].setValue(initialDate)
    this.createEventForm.controls['endDate'].setValue(endingDate)
    this.createEventForm.controls['program'].setValue(this.evento.event.program)
    //this.createEventForm.controls['users'].setValue(this.loadedEvent.users)
    this.createEventForm.controls['enclosure'].setValue(this.evento.event.enclosure)
    this.createEventForm.controls['validator'].setValue(this.evento.event.validator)
    this.createEventForm.controls['taquillero'].setValue(this.evento.event.taquillero)
    this.createEventForm.controls['user'].setValue(this.evento.event.user)
    this.createEventForm.controls['administrationFees'].setValue(
      this.evento.event?.administrationFees
    )
  }

  async getCategories() {
    ;(await this.categoriesService.getAll()).subscribe({
      next: async (response: any) => {
        this.categoriesList = response.category
        if (this.mode === 'edit' || this.mode === 'watch') {
          this.createEventForm.controls['category'].setValue(this.evento.event.category)
          this.subCategoriesList = this.evento.event.category.subcategories
          console.log(this.evento.event.subcategory)
          this.createEventForm.controls['subcategory'].setValue(this.evento.event.subcategory)
        }
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  async getUsers() {
    ;(await this.accountService.getAllManagers()).subscribe({
      next: async (response: any) => {
        this.userList = response.users
        if (this.mode === 'edit' || this.mode === 'watch') {
          this.createEventForm.controls['user'].setValue(this.evento.event.user)
        }
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  async getValidators() {
    ;(await this.accountService.getValidators()).subscribe({
      next: async (response: any) => {
        this.validatorsList = response.users
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  async getTaquilleros() {
    ;(await this.accountService.getTaquilleros()).subscribe({
      next: async (response: any) => {
        this.taquillerosList = response.users
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  async getEnclosure() {
    ;(await this.enclosuresService.getAll()).subscribe({
      next: async (response: any) => {
        this.enclosuresList = response.enclosures
        if (this.mode === 'edit' || this.mode === 'watch') {
          this.createEventForm.controls['enclosure'].setValue(this.loadedEvent.enclosure)
        }
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  public onCategoryChange(category: any) {
    const subcategory = this.createEventForm.get('category')?.value
    this.subCategoriesList = subcategory.subcategories
  }

  public async onSubmit(): Promise<void> {
    if (this.createEventForm.valid) {
      let init = this.createEventForm.get('initDate')?.value
      let end = this.createEventForm.get('endDate')?.value
      if (init > end) {
        Swal.fire(
          'La fecha de finalización no puede ser anterior a la fecha de inicio',
          '',
          'error'
        )
      } else if (this.mode === 'edit') {
        this.edit()
      } else {
        const newEvent = {
          name: this.createEventForm.get('name')?.value,
          description: this.createEventForm.get('description')?.value,
          category: this.createEventForm.get('category')?.value.id,
          subcategory: this.createEventForm.get('subcategory')?.value.id,
          enclosure: this.createEventForm.get('enclosure')?.value.id,
          user: this.createEventForm.get('user')?.value.id,
          organizator: 'organizator',
          eventTime: '22:00',
          eventProgram: 'programa',
          initDate: this.createEventForm.get('initDate')?.value,
          endDate: this.createEventForm.get('endDate')?.value,
          initTime: '22:00',
          endTime: '23:00',
          sessions: [],
          totalCapacity: 0,
          picture: this.selectedFile,
          eventProgram2: this.selectedProgram,
          perpetualEvent: false,
          //users: this.createEventForm.get('users')?.value,
          validator: this.createEventForm.get('validator')?.value,
          taquillero: this.createEventForm.get('taquillero')?.value,
          administrationFees: this.createEventForm.get('administrationFees')?.value
          //program: this.createEventForm.get('program')?.value
        }
        this.submitted = true
        ;(await this.eventsService.create(newEvent)).subscribe({
          next: async response => {
            console.log('response: ', response)
            Swal.fire('Evento creado correctamente', '', 'success')
            this.router.navigate(['/home/events-list'])
          },
          complete: () => {},
          error: error => {
            console.log(error)
          }
        })
      }
    }
  }

  public async edit(): Promise<void> {
    let editedEvent: any
    let init = this.createEventForm.get('initDate')?.value
    let end = this.createEventForm.get('endDate')?.value
    if (init > end) {
      Swal.fire('La fecha de finalización no puede ser anterior a la fecha de inicio', '', 'error')
    } else if (this.selectedFile && this.selectedProgram) {
      editedEvent = {
        name: this.createEventForm.get('name')?.value,
        description: this.createEventForm.get('description')?.value,
        category: this.createEventForm.get('category')?.value,
        subcategory: this.createEventForm.get('subcategory')?.value,
        enclosure: this.createEventForm.get('enclosure')?.value.id,
        user: this.createEventForm.get('user')?.value,
        organizator: 'organizator',
        eventTime: '22:00',
        eventProgram: 'programa',
        initDate: this.createEventForm.get('initDate')?.value,
        endDate: this.createEventForm.get('endDate')?.value,
        initTime: '22:00',
        endTime: '23:00',
        sessions: [],
        perpetualEvent: false,
        totalCapacity: 0,
        picture: this.selectedFile,
        eventProgram2: this.selectedProgram,
        //users: this.createEventForm.get('users')?.value,
        validator: this.createEventForm.get('validator')?.value,
        taquillero: this.createEventForm.get('taquillero')?.value,
        administrationFees: this.createEventForm.get('administrationFees')?.value
        //program: this.createEventForm.get('program')?.value
      }
    } else if (this.selectedFile) {
      editedEvent = {
        name: this.createEventForm.get('name')?.value,
        description: this.createEventForm.get('description')?.value,
        category: this.createEventForm.get('category')?.value,
        subcategory: this.createEventForm.get('subcategory')?.value,
        enclosure: this.createEventForm.get('enclosure')?.value.id,
        user: this.createEventForm.get('user')?.value,
        organizator: 'organizator',
        eventTime: '22:00',
        eventProgram: 'programa',
        initDate: this.createEventForm.get('initDate')?.value,
        endDate: this.createEventForm.get('endDate')?.value,
        initTime: '22:00',
        endTime: '23:00',
        perpetualEvent: false,
        sessions: [],
        totalCapacity: 0,
        picture: this.selectedFile,
        eventProgram2: this.loadedEvent.eventProgram2,
        //users: this.createEventForm.get('users')?.value,
        validator: this.createEventForm.get('validator')?.value,
        taquillero: this.createEventForm.get('taquillero')?.value,
        administrationFees: this.createEventForm.get('administrationFees')?.value
        //program: this.createEventForm.get('program')?.value
      }
    } else if (this.selectedProgram) {
      editedEvent = {
        name: this.createEventForm.get('name')?.value,
        description: this.createEventForm.get('description')?.value,
        category: this.createEventForm.get('category')?.value,
        subcategory: this.createEventForm.get('subcategory')?.value,
        enclosure: this.createEventForm.get('enclosure')?.value.id,
        user: this.createEventForm.get('user')?.value,
        organizator: 'organizator',
        eventTime: '22:00',
        eventProgram: 'programa',
        initDate: this.createEventForm.get('initDate')?.value,
        endDate: this.createEventForm.get('endDate')?.value,
        initTime: '22:00',
        endTime: '23:00',
        sessions: [],
        perpetualEvent: false,
        totalCapacity: 0,
        picture: this.loadedEvent.picture,
        eventProgram2: this.selectedProgram,
        //users: this.createEventForm.get('users')?.value,
        validator: this.createEventForm.get('validator')?.value,
        taquillero: this.createEventForm.get('taquillero')?.value,
        administrationFees: this.createEventForm.get('administrationFees')?.value
        //program: this.createEventForm.get('program')?.value
      }
    } else {
      editedEvent = {
        name: this.createEventForm.get('name')?.value,
        description: this.createEventForm.get('description')?.value,
        category: this.createEventForm.get('category')?.value,
        subcategory: this.createEventForm.get('subcategory')?.value,
        enclosure: this.createEventForm.get('enclosure')?.value.id,
        user: this.createEventForm.get('user')?.value,
        organizator: 'organizator',
        eventTime: '22:00',
        eventProgram: 'programa',
        initDate: this.createEventForm.get('initDate')?.value,
        endDate: this.createEventForm.get('endDate')?.value,
        initTime: '22:00',
        endTime: '23:00',
        perpetualEvent: false,
        sessions: this.loadedEvent.sessions,
        totalCapacity: 0,
        picture: this.loadedEvent.picture,
        eventProgram2: this.loadedEvent.eventProgram2,
        //users: this.createEventForm.get('users')?.value,
        validator: this.createEventForm.get('validator')?.value,
        taquillero: this.createEventForm.get('taquillero')?.value,
        administrationFees: this.createEventForm.get('administrationFees')?.value
        //program: this.createEventForm.get('program')?.value
      }
    }
    this.submitted = true
    console.log('editedEvent: ', editedEvent)
    ;(await this.eventsService.updateOne(this.loadedEvent.id, editedEvent)).subscribe({
      next: async response => {
        Swal.fire('Evento editado correctamente', '', 'success')
        this.router.navigate(['/home/events-list'])
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  gotoList() {
    this.router.navigate(['/home/events-list'])
  }

  async selectFile(event: any) {
    this.selectedFile = event.target.files
    const file: File = this.selectedFile.item(0)
    ;(await this.filesService.create(file)).subscribe({
      next: async response => {
        Swal.fire('Imagen subida correctamente', '', 'success')
        this.selectedFile = response.file
        this.selectedFileName = response.file.url
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  async selectProgram(event: any) {
    this.selectedProgram = event.target.files
    const file: File = this.selectedProgram.item(0)
    ;(await this.filesService.create(file)).subscribe({
      next: async response => {
        Swal.fire('Programa subido correctamente', '', 'success')
        this.selectedProgram = response.file
        this.selectedProgramName = response.file.url
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2
  }
}

/* eslint-disable @angular-eslint/no-empty-lifecycle-method */

/*

{
  "name": "string",
  "organizator": "string",
  "description": "string",
  "eventTime": "string",
  "eventProgram": "string",
  "enclosure": {
    "name": "string",
    "address": {
      "address": "string",
      "number": "string",
      "floor": "string",
      "zip": "string",
      "city": "string",
      "geolocation": {
        "lon": 0,
        "lat": 0
      }
    }
  },
  "category": {
    "name": "string",
    "subcategories": [
      "string"
    ]
  },
  "subcategory": {
    "name": "string"
  },
  "initDate": "string",
  "endDate": "string",
  "initTime": "string",
  "endTime": "string",
  "sessions": [
    {
      "id": "string",
      "name": "string",
      "event": {},
      "date": 0,
      "initHour": 0,
      "endHour": 0,
      "seatMap": {},
      "sectionsPrices": [
        "string"
      ]
    }
  ],
  "totalCapacity": 0,
  "user": {
    "email": "string",
    "name": "string",
    "surname": "string",
    "userType": "string",
    "businessName": "string",
    "password": "string",
    "location": "string",
    "phone": "string",
    "address": {
      "address": "string",
      "number": "string",
      "floor": "string",
      "zip": "string",
      "city": "string",
      "geolocation": {
        "lon": 0,
        "lat": 0
      }
    },
    "role": [
      "string"
    ],
    "isActive": true
  }
}
*/
