/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CategoriesService } from 'src/app/shared/services/categories.service'
import { SubcategoriesService } from 'src/app/shared/services/subcategories.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.scss']
})
export class SubcategoriesComponent implements OnInit {
  public createSubcategoryForm!: FormGroup
  loading = false
  submitted = false
  dataSource: any
  loadedSubcategory: any
  mode = 'create'
  constructor(
    private subcategoriesService: SubcategoriesService,
    private categoriesService: CategoriesService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.createSubcategoryForm = this.fb.group({
      name: ['', Validators.required],
      category: ['', Validators.required]
    })
    this.loadedSubcategory = history.state.subcategory
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      this.editMode()
    }
    this.getData()
  }

  editMode() {
    this.createSubcategoryForm.controls['name'].setValue(this.loadedSubcategory.name)
  }

  async getData() {
    ;(await this.categoriesService.getAll()).subscribe({
      next: async (response: any) => {
        this.dataSource = response.category
        if (this.mode === 'edit' || this.mode === 'watch') {
          this.createSubcategoryForm.controls['category'].setValue(this.loadedSubcategory.category)
        }
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  public async onSubmit(): Promise<void> {
    if (this.createSubcategoryForm.valid) {
      if (this.mode === 'edit') {
        this.edit()
      } else {
        const selectedCat = this.createSubcategoryForm.get('category')?.value
        const newSubcategory = {
          name: this.createSubcategoryForm.get('name')?.value,
          category: selectedCat.id
        }
        this.submitted = true
        ;(await this.subcategoriesService.create(newSubcategory)).subscribe({
          next: async response => {
            Swal.fire('Subcategoría creada correctamente', '', 'success')
            this.router.navigate(['/home/subcategories-list'])
          },
          complete: () => {},
          error: error => {
            console.log(error)
          }
        })
      }
    }
  }

  public async edit(): Promise<void> {
    const selectedCat = this.createSubcategoryForm.get('category')?.value
    const newSubcategory = {
      name: this.createSubcategoryForm.get('name')?.value,
      category: selectedCat.id
    }
    this.submitted = true
    ;(
      await this.subcategoriesService.updateOne(this.loadedSubcategory.id, newSubcategory)
    ).subscribe({
      next: async response => {
        Swal.fire('Subcategoría editada correctamente', '', 'success')
        this.router.navigate(['/home/subcategories-list'])
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  gotoList() {
    this.router.navigate(['/home/subcategories-list'])
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2
  }
}
