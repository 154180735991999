<div class="sessions">
  <mat-card class="maincard">
    <mat-card-title *ngIf="mode === 'create'">Elegir fecha</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar fecha</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar fecha</mat-card-title>
    <mat-card-subtitle>Información general</mat-card-subtitle>
    <mat-card-content>
      <div>
        <form [formGroup]="typeForm">
          <mat-radio-group aria-label="" (change)="radioChange($event)" formControlName="type">
            <mat-radio-button [value]="'Estándar'">Estándar</mat-radio-button>
            <mat-radio-button [value]="'Perpetua'">Perpetua</mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
      <mat-card-subtitle class="quarterWidth">Seleccionar días</mat-card-subtitle>
      <form [formGroup]="createSessionsForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Fecha de inicio</mat-label>
            <input matInput [matDatepicker]="initDatePicker" formControlName="initDate" />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="initDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #initDatePicker></mat-datepicker>
          </mat-form-field>
          <div *ngIf="submitted && ['f.initDate.errors']" class="invalid-feedback">
            <div *ngIf="['f.initDate.errors.required']">initDate is required</div>
          </div>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Fecha de fin</mat-label>
            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
          <div *ngIf="submitted && ['f.endDate.errors']" class="invalid-feedback">
            <div *ngIf="['f.endDate.errors.required']">endDate is required</div>
          </div>
        </div>
        <div>
          <mat-card-subtitle class="quarterWidth">Seleccionar sesión por día</mat-card-subtitle>
        </div>
        <div class="form-group" *ngIf="perpetual === false">
          <mat-form-field appearance="fill" class="select">
            <mat-label>Elija recinto</mat-label>
            <mat-select
              formControlName="enclosure"
              (selectionChange)="onEnclosureChange($event)"
              [compareWith]="compareFn">
              <mat-option *ngFor="let option of enclosuresList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="select">
            <mat-label>Elija mapa de asientos</mat-label>
            <mat-select formControlName="seatMap" [compareWith]="compareFn">
              <mat-option *ngFor="let option of seatMapList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Número máximo de tickets</mat-label>
            <input matInput type="number" min="1" formControlName="nMaxTickets" />
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="monday" class="quarterWidth">Lunes</mat-checkbox>
          <mat-form-field appearance="standard" class="quarterWidth" *ngIf="perpetual === false">
            <mat-label>Nº de sesiones</mat-label>
            <mat-select formControlName="mondaySelect">
              <mat-option *ngFor="let option of dataSource" [value]="option">{{
                option
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="tuesday" class="quarterWidth">Martes</mat-checkbox>
          <mat-form-field appearance="standard" class="quarterWidth" *ngIf="perpetual === false">
            <mat-label>Nº de sesiones</mat-label>
            <mat-select formControlName="tuesdaySelect">
              <mat-option *ngFor="let option of dataSource" [value]="option">{{
                option
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="wednesday" class="quarterWidth">Miércoles</mat-checkbox>
          <mat-form-field appearance="standard" class="quarterWidth" *ngIf="perpetual === false">
            <mat-label>Nº de sesiones</mat-label>
            <mat-select formControlName="wednesdaySelect">
              <mat-option *ngFor="let option of dataSource" [value]="option">{{
                option
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="thursday" class="quarterWidth">Jueves</mat-checkbox>
          <mat-form-field appearance="standard" class="quarterWidth" *ngIf="perpetual === false">
            <mat-label>Nº de sesiones</mat-label>
            <mat-select formControlName="thursdaySelect">
              <mat-option *ngFor="let option of dataSource" [value]="option">{{
                option
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="friday" class="quarterWidth">Viernes</mat-checkbox>
          <mat-form-field appearance="standard" class="quarterWidth" *ngIf="perpetual === false">
            <mat-label>Nº de sesiones</mat-label>
            <mat-select formControlName="fridaySelect">
              <mat-option *ngFor="let option of dataSource" [value]="option">{{
                option
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="saturday" class="quarterWidth">Sábado</mat-checkbox>
          <mat-form-field appearance="standard" class="quarterWidth" *ngIf="perpetual === false">
            <mat-label>Nº de sesiones</mat-label>
            <mat-select formControlName="saturdaySelect">
              <mat-option *ngFor="let option of dataSource" [value]="option">{{
                option
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-checkbox formControlName="sunday" class="quarterWidth">Domingo</mat-checkbox>
          <mat-form-field appearance="standard" class="quarterWidth" *ngIf="perpetual === false">
            <mat-label>Nº de sesiones</mat-label>
            <mat-select formControlName="sundaySelect">
              <mat-option *ngFor="let option of dataSource" [value]="option">{{
                option
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth" *ngIf="perpetual === true">
            <mat-label>Capacidad total</mat-label>
            <input matInput type="number" min="1" formControlName="totalCapacity" />
          </mat-form-field>
        </div>
        <div class="form-group" *ngIf="perpetual === true">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Fechas excluídas</mat-label>
            <input
              matInput
              [matDatepicker]="blackDatepicker"
              formControlName="blackDatepicker"
              (dateInput)="addDateBlackList('input', $event)" />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="blackDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #blackDatepicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Fechas añadidas</mat-label>
            <input
              matInput
              [matDatepicker]="whiteDatepicker"
              formControlName="whiteDatepicker"
              (dateInput)="addDateWhiteList('input', $event)" />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="whiteDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #whiteDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group" *ngIf="perpetual === true" style="display: flex">
          <mat-card *ngIf="blackList.length > 0" class="blackwhiteList" style="flex: 1 1 auto">
            <mat-card-title>Fechas excluídas</mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div *ngFor="let item of blackList; let i = index">
                {{ item }}
                <button
                  mat-icon-button
                  aria-label="Delete"
                  (click)="deleteFromBlackList(i); $event.stopPropagation()">
                  <mat-icon>delete</mat-icon>
                </button>
              </div></mat-card-content
            ></mat-card
          >
          <mat-card *ngIf="whiteList.length > 0" class="blackwhiteList" style="flex: 1 1 auto">
            <mat-card-title>Fechas añadidas</mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div *ngFor="let item of whiteList; let i = index">
                {{ item }}
                <button
                  mat-icon-button
                  aria-label="Delete"
                  (click)="deleteFromWhiteList(i); $event.stopPropagation()">
                  <mat-icon>delete</mat-icon>
                </button>
              </div></mat-card-content
            ></mat-card
          >
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit' && perpetual === false">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create' && perpetual === false">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            (click)="savePerpetual()"
            *ngIf="mode === 'create' && perpetual === true">
            Guardar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            (click)="editPerpetual()"
            *ngIf="mode === 'edit' && perpetual === true">
            Editar
          </button>
          <button mat-raised-button (click)="gotoEventDetail()">Volver</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
