/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import * as d3 from 'd3'
import { access, toilets } from 'src/app/shared/constants/signs'
import { SeatMapsService } from 'src/app/shared/services/seat-maps.service'
import Swal from 'sweetalert2'

interface View {
  value: string
  viewValue: string
}
@Component({
  selector: 'app-seat-maps-sections',
  templateUrl: './seat-maps-sections.component.html',
  styleUrls: ['./seat-maps-sections.component.scss']
})
export class SeatMapsSectionsComponent implements OnInit {
  views: View[] = [
    { viewValue: 'Rectángulo', value: 'svgclick' },
    { viewValue: 'Polígono', value: 'svg' },
    { viewValue: 'Curva', value: 'svgclick' },
    { viewValue: 'Círculo', value: 'svgclick' }
  ]
  selectedView: any
  public createSectionForm!: FormGroup
  public adhocForm!: FormGroup
  public points: Array<[number, number]> = []
  loadedSeatMap: any
  mode = 'create'
  trapezoidType = ''
  sectionColor = '#FFFFFF'
  polygon: any
  submitted = false
  loading = false
  view = false
  initSelected = true
  items = [
    {
      points: '20,10 20,190 160,210',
      color: 'lime'
    },
    {
      points: '300,10 350,190 260,210',
      color: 'lime'
    }
  ]
  andrew = '200,10 250,190 160,210'
  color = '#000000'
  r = 0
  cx = 0
  cy = 0
  //@ViewChild('svg', { static: true })
  //svg!: SvgDirective
  @ViewChild('canvas', { static: true })
  canvas!: ElementRef<HTMLCanvasElement>
  ctx!: CanvasRenderingContext2D
  index = 0
  constructor(
    private router: Router,
    private seatMapsService: SeatMapsService,
    private fb: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    //let svgContainer = d3.select(document.querySelector('svg')).append('svg')

    /*d3.select(document.querySelector('svg'))
      //svgContainer
      .append('defs')
      .append('pattern')
      .attr('id', 'locked2')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 100)
      .attr('height', 100)
      .append('image')
      .attr(
        'href',
        'https://phantom-marca.unidadeditorial.es/de6ce43cd34f92f47ca2636bcbfeffe7/resize/660/f/webp/assets/multimedia/imagenes/2023/05/03/16831360743181.jpg'
      )
      .attr('width', 100)
      .attr('height', 100)*/
    //const svg = document.querySelector('svg')

    // variable for the namespace
    // const svgns = 'http://www.w3.org/2000/svg'

    // make a simple rectangle
    /*let newRect = document.createElementNS(svgns, 'rect')

    newRect.setAttribute('x', '150')
    newRect.setAttribute('y', '150')
    newRect.setAttribute('width', '100')
    newRect.setAttribute('height', '100')
    newRect.setAttribute('fill', '#5cceee')
        // append the new rectangle to the svg

    if (svg !== null) {
      svg.appendChild(newRect)
    }
    */

    /*const prevctx = this.canvas.nativeElement.getContext('2d')
    if (prevctx !== null) {
      this.ctx = prevctx
      const poly = [5, 5, 100, 50, 50, 100, 10, 90]
      //var canvas = document.getElementById('canvas')
      //var ctx = canvas.getContext('2d')
      this.ctx.fillStyle = '#f00'

      this.ctx.beginPath()
      this.ctx.moveTo(poly[0], poly[1])
      for (let item = 2; item < poly.length - 1; item += 2) {
        this.ctx.lineTo(poly[item], poly[item + 1])
      }

      this.ctx.closePath()
      this.ctx.fill()
    }*/
    this.createSectionForm = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required],
      x0: [50],
      width: [100],
      y0: [100],
      height: [100],
      ro: [0],
      lo: [0],
      vro: [0],
      vlo: [0],
      cp: [100],
      cp2: [100],
      hwidth: [100],
      hx0: [400],
      hy0: [100],
      hHeight: [100],
      hro: [0],
      hlo: [0],
      hvro: [0],
      hvlo: [0],
      hhro: [0],
      hhlo: [0],
      vhro: [0],
      vhlo: [0],
      hcp: [100],
      hcp2: [100],
      k1: [200],
      k2: [200],
      vk1: [50],
      vk2: [50]
    })
    this.adhocForm = this.fb.group({
      adhoc: ['']
    })
    this.loadedSeatMap = history.state.seatMap
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    if (history.state.index) {
      this.index = history.state.index
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      this.views[1].value = 'svgclick'
      if (this.loadedSeatMap.sections[this.index].polygon[0] === 'C') {
        this.selectedView = this.views[2]
      } else if (this.loadedSeatMap.sections[this.index].polygon[0] === 'H') {
        this.selectedView = this.views[2]
      } else if (this.loadedSeatMap.sections[this.index].polygon[0] === 'R') {
        this.selectedView = this.views[3]
      } else {
        this.selectedView = this.views[1]
      }
      await new Promise(f => setTimeout(f, 500))
      this.setLoadedData()
    } else {
      this.selectedView = this.views[1]
      await new Promise(f => setTimeout(f, 500))
    }
    this.renderCurrentSections()
  }

  public async onViewChange() {
    // this.selectedView = $event
    await new Promise(f => setTimeout(f, 500))

    this.renderCurrentSections()
    if (this.selectedView.value === 'svgclick' && this.selectedView.viewValue === 'Rectángulo') {
      this.createPredefinedPolygon('0,0 0,50 50,50 50,0', 'name')
    }
    if (this.selectedView.value === 'svgclick' && this.selectedView.viewValue === 'Círculo') {
      this.createPredefinedCircle()
    }
  }

  private async renderCurrentSections() {
    if (this.loadedSeatMap.stage.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.stage.length; i++) {
        if (this.loadedSeatMap.stage[i].polygon[0] === 'C') {
          this.createStoredVerticalCurvedTrapezoid(
            this.loadedSeatMap.stage[i].polygon,
            this.loadedSeatMap.stage[i]?.name,
            this.loadedSeatMap.stage[i].color
          )
        } else if (this.loadedSeatMap.stage[i].polygon[0] === 'H') {
          this.createStoredCurvedTrapezoid(
            this.loadedSeatMap.stage[i].polygon,
            this.loadedSeatMap.stage[i]?.name,
            this.loadedSeatMap.stage[i].color
          )
        } else if (this.loadedSeatMap.stage[i].polygon[0] === 'R') {
          this.createCircle(
            this.loadedSeatMap.stage[i],
            this.loadedSeatMap.stage[i]?.name,
            i,
            'stage'
          )
        } else {
          this.createPolygon(
            this.loadedSeatMap.stage[i],
            this.loadedSeatMap.stage[i]?.name,
            i,
            'stage'
          )
        }
      }
    }
    if (this.loadedSeatMap.toilets.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.toilets.length; i++) {
        this.createToilet(this.loadedSeatMap.toilets[i], 'Aseo' + i)
      }
    }
    if (this.loadedSeatMap.access.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.access.length; i++) {
        this.createAccess(this.loadedSeatMap.access[i], 'Acceso' + i)
      }
    }
    if (this.loadedSeatMap.sections.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.sections.length; i++) {
        if (this.loadedSeatMap.sections[i].polygon[0] === 'C') {
          this.createStoredVerticalCurvedTrapezoid(
            this.loadedSeatMap.sections[i].polygon,
            this.loadedSeatMap.sections[i].name,
            this.loadedSeatMap.sections[i].color
          )
        } else if (this.loadedSeatMap.sections[i].polygon[0] === 'H') {
          this.createStoredCurvedTrapezoid(
            this.loadedSeatMap.sections[i].polygon,
            this.loadedSeatMap.sections[i].name,
            this.loadedSeatMap.sections[i].color
          )
        } else if (this.loadedSeatMap.sections[i].polygon[0] === 'R') {
          this.createCircle(
            this.loadedSeatMap.sections[i],
            this.loadedSeatMap.sections[i].name,
            i,
            'sections'
          )
        } else {
          this.createPolygon(
            this.loadedSeatMap.sections[i],
            this.loadedSeatMap.sections[i].name,
            i,
            'sections'
          )
        }
      }
    }
  }

  public createToilet(toilet: any, name: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    let newStr = toilet.polygon.slice(1)
    let i = parseInt(toilet.polygon[0])
    let svg = d3.select(document.querySelector('svg'))
    const idImage = 'url(#' + name + 'locked2)'
    let myimage = svg
      .append('defs')
      .append('pattern')
      .attr('id', name + 'locked2')
      //.attr('patternUnits', 'userSpaceOnUse')
      .attr('width', toilets[i].width)
      .attr('height', toilets[i].height)
      .append('image')
      .attr('href', toilets[i].url)
      .attr('width', toilets[i].width)
      .attr('height', toilets[i].height)
      .attr('id', name + 'locked2')
    /*.attr('transform', function () {
        return 'translate(5,5)'
      })*/

    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', newStr)
      //.attr('fill', this.createSectionForm.get('color')?.value)
      .attr('fill', idImage)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    //this.polygon = polygon
    // this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  public createAccess(acc: any, name: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    let newStr = acc.polygon.slice(2)
    let i = parseInt(acc.polygon[0] + acc.polygon[1])
    let svg = d3.select(document.querySelector('svg'))
    const idImage = 'url(#' + name + 'locked2)'
    let myimage = svg
      .append('defs')
      .append('pattern')
      .attr('id', name + 'locked2')
      //.attr('patternUnits', 'userSpaceOnUse')
      .attr('width', access[i].width)
      .attr('height', access[i].height)
      .append('image')
      .attr('href', access[i].url)
      .attr('width', access[i].width)
      .attr('height', access[i].height)
      .attr('id', name + 'locked2')
    //.attr('transform', (200, 200))

    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', newStr)
      //.attr('fill', this.createSectionForm.get('color')?.value)
      .attr('fill', idImage)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    //this.polygon = polygon
    // this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  private createPolygon(sector: any, name: string, index: number, type: string) {
    let uniqueId = `${type}-${name.replace(/ /g, '')}-${index}`
    d3.select('#' + uniqueId).remove()
    // if (this.mode === 'edit' && index === this.index && type === 'sections') {
    //   name = 'patata'
    // }
    // if (this.mode === 'edit' && index === this.index && type === 'stage') {
    //   name = 'patata'
    // }
    // if (name === undefined) {
    //   name = 'Escenario'
    // }
    // name = name.replace(/ /g, '')
    // d3.select('#' + name).remove()
    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', sector.polygon)
      .attr('fill', sector.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  private createCircle(sector: any, name: string, index: number, type: string) {
    /* if (this.mode === 'edit' && index === this.index && type === 'sections') {
      name = 'patata'
    } */
    let newStr = sector.polygon.slice(1)
    let polygon = newStr.split(',')
    //d3.select('#patata').remove()
    // d3.selectAll('circle').remove()
    d3.select(document.querySelector('svg'))
      .attr('fill', sector.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
      .append('circle')
      .style('stroke', 'gray')
      .attr('r', polygon[0])
      .attr('cx', polygon[1])
      .attr('cy', polygon[2])
  }

  private createPredefinedCircle() {
    d3.select('#patata').remove()
    this.r = 100
    this.cx = 200
    this.cy = 200
    //d3.selectAll('circle').remove()
    d3.select(document.querySelector('svg'))
      .attr('fill', this.createSectionForm.get('color')?.value)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
      .append('circle')
      .style('stroke', 'gray')
      .attr('id', 'patata')
      .attr('r', 100)
      .attr('cx', 200)
      .attr('cy', 200)
  }

  private modifyPredefinedCircle(r: number, cx: number, cy: number) {
    d3.select('#patata').remove()
    //d3.selectAll('circle').remove()
    d3.select(document.querySelector('svg'))
      .attr('fill', this.createSectionForm.get('color')?.value)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
      .append('circle')
      .style('stroke', 'gray')
      .attr('id', 'patata')
      .attr('r', r)
      .attr('cx', cx)
      .attr('cy', cy)
  }

  public createPredefinedPolygon(polygon: any, name: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    d3.select('#patata').remove()
    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', polygon)
      .attr('fill', this.createSectionForm.get('color')?.value)
      .attr('id', 'patata')
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    this.polygon = polygon
    this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  public createStoredVerticalCurvedTrapezoid(path: string, name: string, color: string) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width / 2
    }
    if (Number.isNaN(k2)) {
      k2 = width / 2
    }
    const k = (x0 + width + k1) / 2
    const kb = (x0 + width + k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()
    this.trapezoidType = 'C'

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          k +
          ',' +
          (y0 + cp) +
          ' ' +
          (x0 + width + hlo) +
          ',' +
          (y0 + vro) +
          'L ' +
          (x0 + width - ro) +
          ',' +
          (y0 + height + hro) +
          ' Q ' +
          kb +
          ',' +
          (y0 + height + cp2) +
          ' ' +
          (x0 + lo) +
          ',' +
          (y0 + height + vlo) +
          ' Z'
        )
      })
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public createStoredCurvedTrapezoid(path: string, name: string, color: string) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp)) {
      cp = 100
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width
    }
    if (Number.isNaN(k2)) {
      k2 = width
    }
    const k = (x0 + width - k1) / 2
    const kb = (x0 + width - k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()
    this.trapezoidType = 'H'

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          (x0 + cp) +
          ',' +
          k +
          ' ' +
          (x0 + hro) +
          ',' +
          (y0 + height + hlo) +
          'L ' +
          (x0 - width - ro) +
          ',' +
          (y0 + height + vro) +
          ' Q ' +
          (x0 + cp2 - width) +
          ',' +
          kb +
          ' ' +
          (x0 - width - lo) +
          ',' +
          (y0 + lo + vlo) +
          ' Z'
        )
      })
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public createVerticalCurvedTrapezoid() {
    let data = [3]
    const value = this.adhocForm.get('adhoc')?.value
    let polygon = value.split(' ').join(',').split(',')
    const name = 'patata'
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = this.createSectionForm.get('x0')?.value
    const y0 = this.createSectionForm.get('y0')?.value
    const width = this.createSectionForm.get('width')?.value
    const height = this.createSectionForm.get('height')?.value
    const ro = this.createSectionForm.get('ro')?.value
    const lo = this.createSectionForm.get('lo')?.value
    const vro = this.createSectionForm.get('vro')?.value
    const vlo = this.createSectionForm.get('vlo')?.value
    const cp = this.createSectionForm.get('cp')?.value
    const cp2 = this.createSectionForm.get('cp2')?.value
    const hro = this.createSectionForm.get('vhro')?.value
    const hlo = this.createSectionForm.get('vhlo')?.value
    const k1 = this.createSectionForm.get('vk1')?.value
    const k2 = this.createSectionForm.get('vk2')?.value
    const k = (parseFloat(x0) + parseFloat(width) + parseFloat(k1)) / 2
    const kb = (parseFloat(x0) + parseFloat(width) + parseFloat(k2)) / 2
    d3.select('#' + name).remove()
    this.trapezoidType = 'C'
    /*const x0 = 100
    const y0 = 200
    const width = 100
    const height = 200
    const ro = 0
    const lo = 0
    const k = x0 + width / 2*/
    /*let path = d3.path()
    path.moveTo(100, 300)
    path.quadraticCurveTo(150, 200, 200, 300)
    path.lineTo(200, 400)
    path.quadraticCurveTo(150, 300, 100, 400)
    path.closePath()
    //d3.select('.path2').attr('d', path)*/
    // 'M100,250 Q250,300 400,250 L250,400 Q250,500 100,400 Z'
    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          k +
          ',' +
          (y0 + cp) +
          ' ' +
          (x0 + width + hlo) +
          ',' +
          (y0 + vro) +
          'L ' +
          (x0 + width - ro) +
          ',' +
          (y0 + height + hro) +
          ' Q ' +
          kb +
          ',' +
          (y0 + height + cp2) +
          ' ' +
          (x0 + lo) +
          ',' +
          (y0 + height + vlo) +
          ' Z'
        )
      })
      .attr('fill', this.createSectionForm.get('color')?.value)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public createCurvedTrapezoid() {
    let data = [3]
    const value = this.adhocForm.get('adhoc')?.value
    let polygon = value.split(' ').join(',').split(',')
    const name = 'patata'
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = this.createSectionForm.get('hx0')?.value
    const y0 = this.createSectionForm.get('hy0')?.value
    const width = this.createSectionForm.get('hwidth')?.value
    const height = this.createSectionForm.get('hHeight')?.value
    const ro = this.createSectionForm.get('hro')?.value
    const lo = this.createSectionForm.get('hlo')?.value
    const vro = this.createSectionForm.get('hvro')?.value
    const vlo = this.createSectionForm.get('hvlo')?.value
    const hro = this.createSectionForm.get('hhro')?.value
    const hlo = this.createSectionForm.get('hhlo')?.value
    const cp2 = this.createSectionForm.get('hcp2')?.value
    const cp = this.createSectionForm.get('hcp')?.value
    const k1 = this.createSectionForm.get('k1')?.value
    const k2 = this.createSectionForm.get('k2')?.value
    const k = (parseFloat(x0) + parseFloat(width) - parseFloat(k1)) / 2
    const kb = (parseFloat(x0) + parseFloat(width) - parseFloat(k2)) / 2
    d3.select('#' + name).remove()
    this.trapezoidType = 'H'
    /*const x0 = 100
    const y0 = 200
    const width = 100
    const height = 200
    const ro = 0
    const lo = 0
    const k = x0 + width / 2*/
    /*let path = d3.path()
    path.moveTo(100, 300)
    path.quadraticCurveTo(150, 200, 200, 300)
    path.lineTo(200, 400)
    path.quadraticCurveTo(150, 300, 100, 400)
    path.closePath()
    //d3.select('.path2').attr('d', path)*/

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        // return 'M 400,100 Q9,150 400,200 L 100,200 Q9,150 100,100 Z'
        return (
          // 'M 400,100 Q9,250 500,200 L 100,300 Q9,250 100,200 Z'

          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          (x0 + cp) +
          ',' +
          k +
          ' ' +
          (x0 + hro) +
          ',' +
          (y0 + height + hlo) +
          'L ' +
          (x0 - width - ro) +
          ',' +
          (y0 + height + vro) +
          ' Q ' +
          (x0 + cp2 - width) +
          ',' +
          kb +
          ' ' +
          (x0 - width - lo) +
          ',' +
          (y0 + lo + vlo) +
          ' Z'
        )
      })
      .attr('fill', this.createSectionForm.get('color')?.value)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public modifyCircle(opt: string) {
    // d3.selectAll('circle')[0][0].attributes.cx.value
    /* let cx = 0
    let cy = 0
    let r = 0
    d3.select('circle').each(function (d, i) {
      var elt = d3.select(this)
      cx = parseFloat(elt.attr('cx'))
      cy = parseFloat(elt.attr('cy'))
      r = parseFloat(elt.attr('r'))
      console.log('dentro del for')
      if (elt.attr('id') === '#patata') {
        console.log(elt.attr('cx'))
      }
    })*/

    //const svgPoints = polygonSelection.getAttribute('cx')
    let coords = ''
    /* for (let i = 0; i < svgPoints.length; i++) {
      if (i === 0) {
        coords = svgPoints[i].x + ',' + svgPoints[i].y
      } else {
        coords = coords + ' ' + svgPoints[i].x + ',' + svgPoints[i].y
      }
    }*/
    let sector = {
      polygon: ''
    }
    let polygon = coords.split(' ').join(',').split(',')
    let result = ''
    switch (opt) {
      case 'up':
        this.cy = this.cy - 2
        sector.polygon =
          'R' + this.r.toString() + ',' + this.cx.toString() + ',' + this.cy.toString()
        this.modifyPredefinedCircle(this.r, this.cx, this.cy)
        break
      case 'down':
        this.cy = this.cy + 2
        sector.polygon =
          'R' + this.r.toString() + ',' + this.cx.toString() + ',' + this.cy.toString()
        this.modifyPredefinedCircle(this.r, this.cx, this.cy)
        break
      case 'left':
        this.cx = this.cx - 2
        sector.polygon =
          'R' + this.r.toString() + ',' + this.cx.toString() + ',' + this.cy.toString()
        this.modifyPredefinedCircle(this.r, this.cx, this.cy)
        break
      case 'right':
        this.cx = this.cx + 2
        sector.polygon =
          'R' + this.r.toString() + ',' + this.cx.toString() + ',' + this.cy.toString()
        this.modifyPredefinedCircle(this.r, this.cx, this.cy)
        break
      case 'r+':
        this.r = this.r + 2
        sector.polygon =
          'R' + this.r.toString() + ',' + this.cx.toString() + ',' + this.cy.toString()
        this.modifyPredefinedCircle(this.r, this.cx, this.cy)
        break
      case 'r-':
        this.r = this.r - 2
        sector.polygon =
          'R' + this.r.toString() + ',' + this.cx.toString() + ',' + this.cy.toString()
        this.modifyPredefinedCircle(this.r, this.cx, this.cy)
        break
    }
    //this.createPredefinedPolygon(result, 'name')
  }

  public modifyRect(opt: string) {
    const polygonSelection = d3.select('#patata')
    const svgPoints = polygonSelection.property('points')
    let coords = ''
    for (let i = 0; i < svgPoints.length; i++) {
      if (i === 0) {
        coords = svgPoints[i].x + ',' + svgPoints[i].y
      } else {
        coords = coords + ' ' + svgPoints[i].x + ',' + svgPoints[i].y
      }
    }

    let polygon = coords.split(' ').join(',').split(',')
    let result = ''
    switch (opt) {
      case 'h+':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 4 || i === 6) {
            let newCoords = parseInt(polygon[i]) + 2
            result = result + newCoords.toString()
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'h-':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 4 || i === 6) {
            let newCoords = parseInt(polygon[i]) - 2
            result = result + newCoords.toString()
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'v+':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 3 || i === 5) {
            let newCoords = parseInt(polygon[i]) + 2
            result = result + ',' + newCoords.toString() + ' '
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'v-':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 3 || i === 5) {
            let newCoords = parseInt(polygon[i]) - 2
            result = result + ',' + newCoords.toString() + ' '
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
    }
    this.createPredefinedPolygon(result, 'name')
  }

  public createAdHocPolygon() {
    const value = this.adhocForm.get('adhoc')?.value
    console.log(value)
    this.createPredefinedPolygon(value, 'name')
  }

  public modifyRectByOnePoint(opt: string, symbol: string) {
    let patata = d3.select('#patata')
    let polygon = this.polygon.split(' ').join(',').split(',')
    let result = ''
    switch (opt) {
      case 'upperLeft':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 4 || i === 6) {
            let newCoords = parseInt(polygon[i]) + 2
            result = result + newCoords.toString()
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'lowerLeft':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 4 || i === 6) {
            let newCoords = parseInt(polygon[i]) - 2
            result = result + newCoords.toString()
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'upperRight':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 3 || i === 5) {
            let newCoords = parseInt(polygon[i]) + 2
            result = result + ',' + newCoords.toString() + ' '
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'lowerRight':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 3 || i === 5) {
            let newCoords = parseInt(polygon[i]) - 2
            result = result + ',' + newCoords.toString() + ' '
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
    }
    this.createPredefinedPolygon(result, 'name')
  }

  setLoadedData() {
    this.createSectionForm.controls['name'].setValue(this.loadedSeatMap.sections[this.index].name)
    this.createSectionForm.controls['color'].setValue(this.loadedSeatMap.sections[this.index].color)
    this.adhocForm.controls['adhoc'].setValue(this.loadedSeatMap.sections[this.index].polygon)
    let newStr = this.loadedSeatMap.sections[this.index].polygon.slice(1)
    let polygon = newStr.split(',')
    if (this.loadedSeatMap.sections[this.index].polygon[0] === 'C') {
      this.createSectionForm.controls['x0'].setValue(parseFloat(polygon[0]))
      this.createSectionForm.controls['y0'].setValue(parseFloat(polygon[1]))
      this.createSectionForm.controls['width'].setValue(parseFloat(polygon[2]))
      this.createSectionForm.controls['height'].setValue(parseFloat(polygon[3]))
      this.createSectionForm.controls['ro'].setValue(parseFloat(polygon[4]))
      this.createSectionForm.controls['lo'].setValue(parseFloat(polygon[5]))
      this.createSectionForm.controls['vro'].setValue(parseFloat(polygon[6]))
      this.createSectionForm.controls['vlo'].setValue(parseFloat(polygon[7]))
      this.createSectionForm.controls['cp'].setValue(parseFloat(polygon[8]))
      this.createSectionForm.controls['vhro'].setValue(parseFloat(polygon[9]))
      this.createSectionForm.controls['vhlo'].setValue(parseFloat(polygon[10]))
      this.createSectionForm.controls['cp2'].setValue(parseFloat(polygon[11]))
      this.createSectionForm.controls['vk1'].setValue(parseFloat(polygon[12]))
      this.createSectionForm.controls['vk2'].setValue(parseFloat(polygon[13]))
    } else if (this.loadedSeatMap.sections[this.index].polygon[0] === 'H') {
      this.createSectionForm.controls['hx0'].setValue(parseFloat(polygon[0]))
      this.createSectionForm.controls['hy0'].setValue(parseFloat(polygon[1]))
      this.createSectionForm.controls['hwidth'].setValue(parseFloat(polygon[2]))
      this.createSectionForm.controls['hHeight'].setValue(parseFloat(polygon[3]))
      this.createSectionForm.controls['hro'].setValue(parseFloat(polygon[4]))
      this.createSectionForm.controls['hlo'].setValue(parseFloat(polygon[5]))
      this.createSectionForm.controls['hvro'].setValue(parseFloat(polygon[6]))
      this.createSectionForm.controls['hvlo'].setValue(parseFloat(polygon[7]))
      this.createSectionForm.controls['hcp'].setValue(parseFloat(polygon[8]))
      this.createSectionForm.controls['hhro'].setValue(parseFloat(polygon[9]))
      this.createSectionForm.controls['hhlo'].setValue(parseFloat(polygon[10]))
      this.createSectionForm.controls['hcp2'].setValue(parseFloat(polygon[11]))
      this.createSectionForm.controls['k1'].setValue(parseFloat(polygon[12]))
      this.createSectionForm.controls['k2'].setValue(parseFloat(polygon[13]))
    }
  }

  gotoList() {
    this.router.navigate(['/home/seat-map-general'], {
      state: { mode: 'edit', seatMap: this.loadedSeatMap }
    })
  }

  preview() {
    this.view = false
    this.sectionColor = this.createSectionForm.get('color')?.value
    this.points.length = 0
    this.points.push([
      this.createSectionForm.get('x')?.value,
      this.createSectionForm.get('y')?.value
    ])
    this.points.push([
      this.createSectionForm.get('x2')?.value,
      this.createSectionForm.get('y2')?.value
    ])
    this.points.push([
      this.createSectionForm.get('x3')?.value,
      this.createSectionForm.get('y3')?.value
    ])
    this.points.push([
      this.createSectionForm.get('x4')?.value,
      this.createSectionForm.get('y4')?.value
    ])
    //this.svg.points = this.points
    this.view = true
  }

  public async onSubmit(): Promise<void> {
    if (this.createSectionForm.valid) {
      let coords = ''
      if (this.selectedView.viewValue === 'Curva' && this.trapezoidType === 'C') {
        coords =
          this.trapezoidType +
          this.createSectionForm.get('x0')?.value +
          ',' +
          this.createSectionForm.get('y0')?.value +
          ',' +
          this.createSectionForm.get('width')?.value +
          ',' +
          this.createSectionForm.get('height')?.value +
          ',' +
          this.createSectionForm.get('ro')?.value +
          ',' +
          this.createSectionForm.get('lo')?.value +
          ',' +
          this.createSectionForm.get('vro')?.value +
          ',' +
          this.createSectionForm.get('vlo')?.value +
          ',' +
          this.createSectionForm.get('cp')?.value +
          ',' +
          this.createSectionForm.get('vhro')?.value +
          ',' +
          this.createSectionForm.get('vhlo')?.value +
          ',' +
          this.createSectionForm.get('cp2')?.value +
          ',' +
          this.createSectionForm.get('vk1')?.value +
          ',' +
          this.createSectionForm.get('vk2')?.value
      } else if (this.selectedView.viewValue === 'Curva' && this.trapezoidType === 'H') {
        coords =
          this.trapezoidType +
          this.createSectionForm.get('hx0')?.value +
          ',' +
          this.createSectionForm.get('hy0')?.value +
          ',' +
          this.createSectionForm.get('hwidth')?.value +
          ',' +
          this.createSectionForm.get('hHeight')?.value +
          ',' +
          this.createSectionForm.get('hro')?.value +
          ',' +
          this.createSectionForm.get('hlo')?.value +
          ',' +
          this.createSectionForm.get('hvro')?.value +
          ',' +
          this.createSectionForm.get('hvlo')?.value +
          ',' +
          this.createSectionForm.get('hcp')?.value +
          ',' +
          this.createSectionForm.get('hhro')?.value +
          ',' +
          this.createSectionForm.get('hhlo')?.value +
          ',' +
          this.createSectionForm.get('hcp2')?.value +
          ',' +
          this.createSectionForm.get('k1')?.value +
          ',' +
          this.createSectionForm.get('k2')?.value
      } else if (this.selectedView.viewValue === 'Círculo') {
        if (this.r !== 0) {
          coords = 'R' + this.r + ',' + this.cx + ',' + this.cy
        } else {
          let cx = ''
          let cy = ''
          let r = ''
          d3.select('circle').each(function (d, i) {
            var elt = d3.select(this)
            cx = elt.attr('cx')
            cy = elt.attr('cy')
            r = elt.attr('r')
          })
          coords = 'R' + r + ',' + cx + ',' + cy
        }
      } else {
        const polygonSelection = d3.select('#patata')
        const svgPoints = polygonSelection.property('points')

        for (let i = 0; i < svgPoints.length; i++) {
          if (i === 0) {
            coords = svgPoints[i].x + ',' + svgPoints[i].y
          } else {
            coords = coords + ' ' + svgPoints[i].x + ',' + svgPoints[i].y
          }
        }
      }

      const sections = {
        name: this.createSectionForm.get('name')?.value,
        rows: 0,
        cols: 0,
        price: 0,
        color: this.createSectionForm.get('color')?.value,
        orientation: 'string',
        polygon: coords
      }
      if (this.mode === 'edit') {
        this.loadedSeatMap.sections[this.index] = sections
      } else {
        this.loadedSeatMap.sections.push(sections)
      }
      const seatMap = {
        name: this.loadedSeatMap.name,
        stage: this.loadedSeatMap.stage,
        sections: this.loadedSeatMap.sections,
        access: this.loadedSeatMap.access,
        toilets: this.loadedSeatMap.toilets
      }
      this.submitted = true
      ;(await this.seatMapsService.updateOne(this.loadedSeatMap.id, seatMap)).subscribe({
        next: async response => {
          Swal.fire('Sección creada correctamente', '', 'success')
          this.router.navigate(['/home/seat-map-general'], {
            state: { mode: 'edit', seatMap: response.seatMap }
          })
        },
        complete: () => {},
        error: error => {
          console.log(error)
        }
      })
    }
  }

  gotoEdit() {
    this.router.navigate(['/home/seat-map-general'], {
      state: { mode: 'edit', seatMap: this.loadedSeatMap }
    })
  }

  update($event: any) {
    this.polygon = $event
    this.adhocForm.controls['adhoc'].setValue(this.polygon)
    this.andrew = '200,10 250,190 160,210'
    this.color = 'lime'
    // Swal.fire($event, '', 'success')
  }

  printOutSVGFormat() {
    if (this.index < 4) {
      this.index++
      const andrew = '200,10 250,190 160,210'
      return andrew
    } else {
      this.index++
      const andrew = '200,10 250,190 160,210'
      return andrew
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2
  }
}
