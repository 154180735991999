/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { EnclosuresService } from 'src/app/shared/services/enclosures.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-enclosures-list',
  templateUrl: './enclosures-list.component.html',
  styleUrls: ['./enclosures-list.component.scss']
})
export class EnclosuresListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'address', 'city', 'actions']
  enclosuresList: any
  @ViewChild(MatPaginator)
  paginator!: MatPaginator

  constructor(private enclosuresService: EnclosuresService, private router: Router) {}

  ngOnInit(): void {
    this.getEnclosure()
  }

  async getEnclosure() {
    ;(await this.enclosuresService.getAll()).subscribe({
      next: async (response: any) => {
        this.enclosuresList = new MatTableDataSource(response.enclosures)
        this.enclosuresList.paginator = this.paginator
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  delete(id: string) {
    Swal.fire({
      title: '¿Quieres borrar el recinto?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.enclosuresService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Recinto borrado correctamente', '', 'success')
            this.getEnclosure()
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se ha podido borrar el recinto', '', 'error')
          }
        })
      }
    })
  }
  gotoCreate() {
    this.router.navigate(['/home/enclosures'])
  }

  gotoEdit(enclosure: any) {
    this.router.navigate(['/home/enclosures'], {
      state: { mode: 'edit', enclosure: enclosure }
    })
  }

  gotoWatch(enclosure: any) {
    this.router.navigate(['/home/enclosures'], {
      state: { mode: 'watch', enclosure: enclosure }
    })
  }

  createSeatMap(enclosure: any) {
    this.router.navigate(['/home/seat-map-new'], {
      state: { mode: 'create', enclosure: enclosure }
    })
  }
}
