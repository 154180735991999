/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
@Component({
  selector: 'app-zones',
  template: `<svg
    appSvg
    style="border: 1px solid black;"
    width="1000"
    height="700"
    xmlns="<http://www.w3.org/2000/svg>">
    <polygon
      points="477,175 579,281 407,318"
      fill="lightgrey"
      stroke="black"
      stroke-width="0.2"></polygon>
    <polygon
      points="77,75 579,281 407,318"
      fill="#C4C4C4"
      stroke="black"
      stroke-width="0.2"></polygon>
  </svg>`
})
export class ZonesComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
