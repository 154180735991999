import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { FilesService } from 'src/app/shared/services/files.service'
import { PerpetualService } from 'src/app/shared/services/perpetual.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-event-perpetual-sessions-details',
  templateUrl: './event-perpetual-sessions-details.component.html',
  styleUrls: ['./event-perpetual-sessions-details.component.scss']
})
export class EventPerpetualSessionsDetailsComponent implements OnInit {
  public createPerpetualSessionGroup!: FormGroup
  loading = false
  submitted = false
  mode = 'create'
  loadedPerpetual: any
  loadedEvent: any
  constructor(
    private perpetualService: PerpetualService,
    private router: Router,
    private fb: FormBuilder,
    private filesService: FilesService
  ) {}

  ngOnInit(): void {
    this.createPerpetualSessionGroup = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      price: [0, Validators.required],
      nMaxTickets: [0, Validators.required]
    })
    this.loadedPerpetual = history.state.perpetual
    this.loadedEvent = history.state.event
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      this.setData()
    }
  }

  public setData() {
    this.createPerpetualSessionGroup.controls['name'].setValue(this.loadedPerpetual.name)
    this.createPerpetualSessionGroup.controls['description'].setValue(
      this.loadedPerpetual.descripcion
    )
    this.createPerpetualSessionGroup.controls['price'].setValue(this.loadedPerpetual.price)
    this.createPerpetualSessionGroup.controls['nMaxTickets'].setValue(
      this.loadedPerpetual.nMaxTickets
    )
  }

  public async onSubmit(): Promise<void> {
    if (this.createPerpetualSessionGroup.valid) {
      if (this.mode === 'edit') {
        this.edit()
      } else {
        const newPerpetual = {
          name: this.createPerpetualSessionGroup.get('name')?.value,
          event: this.loadedEvent.id,
          date: this.loadedEvent.initDate,
          initHour: this.loadedEvent.initTime,
          endHour: this.loadedEvent.endTime,
          nMaxTickets: this.createPerpetualSessionGroup.get('nMaxTickets')?.value,
          descripcion: this.createPerpetualSessionGroup.get('description')?.value,
          price: this.createPerpetualSessionGroup.get('price')?.value
        }
        this.submitted = true
        ;(await this.perpetualService.create(newPerpetual)).subscribe({
          next: async response => {
            Swal.fire('Sesión creada correctamente', '', 'success')
            this.router.navigate(['/home/perpetual-list'], {
              state: { mode: 'edit', event: this.loadedEvent }
            })
          },
          complete: () => {},
          error: error => {
            console.log(error)
          }
        })
      }
    }
  }

  public async edit(): Promise<void> {
    const newPerpetual = {
      name: this.createPerpetualSessionGroup.get('name')?.value,
      event: this.loadedEvent.id,
      date: this.loadedPerpetual.date,
      initHour: this.loadedPerpetual.initHour,
      endHour: this.loadedPerpetual.endHour,
      nMaxTickets: this.createPerpetualSessionGroup.get('nMaxTickets')?.value,
      descripcion: this.createPerpetualSessionGroup.get('description')?.value,
      price: this.createPerpetualSessionGroup.get('price')?.value
    }
    this.submitted = true
    ;(await this.perpetualService.updateOne(this.loadedPerpetual.id, newPerpetual)).subscribe({
      next: async response => {
        Swal.fire('Sesión editada correctamente', '', 'success')
        this.router.navigate(['/home/perpetual-list'], {
          state: { mode: 'edit', event: this.loadedEvent }
        })
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  gotoList() {
    this.router.navigate(['/home/events-list'])
  }
}
