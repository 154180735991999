<div class="seat-map-list">
  <mat-card>
    <mat-card-title>Listado de mapas de asientos</mat-card-title>
    <!--<mat-card-subtitle
      ><button mat-raised-button color="primary" (click)="gotoCreate()">
        Crear nuevo Evento
      </button></mat-card-subtitle
    >-->
    <mat-card-content>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="enclosure">
          <th mat-header-cell *matHeaderCellDef>Recinto</th>
          <td mat-cell *matCellDef="let element">{{ element.enclosure?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row; let element">
            <button mat-icon-button aria-label="Visibility" (click)="gotoWatch(element)">
              <mat-icon>visibility</mat-icon>
            </button>
            <button mat-icon-button aria-label="Edit" (click)="gotoEdit(element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete" (click)="delete(element.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
