import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { IUser } from 'src/app/shared/models/user.model'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private KEY_LOCAL_STORAGE_TOKEN = 'fndsadmin::web_token'
  private KEY_LOCAL_STORAGE_USER = 'fndsadmin::current_user'
  constructor(private _http: HttpClient, private router: Router) {}

  login(user: any): Observable<any> {
    return this._http.post(environment.apiUrl + '/auth/login', user)
  }

  public async setToken(tokenValue: string) {
    localStorage.setItem(this.KEY_LOCAL_STORAGE_TOKEN, tokenValue)
  }

  public async setUser(userValue: any) {
    localStorage.setItem(this.KEY_LOCAL_STORAGE_USER, JSON.stringify(userValue))
  }

  public async logOut() {
    localStorage.removeItem(this.KEY_LOCAL_STORAGE_USER)
    localStorage.removeItem(this.KEY_LOCAL_STORAGE_TOKEN)
    return true
  }

  public async getToken() {
    let token = localStorage?.getItem(this.KEY_LOCAL_STORAGE_TOKEN)
    if (!token) {
      //let snackBarRef = this.snackBar.open('login')
      this.router.navigate(['/'])
      // return 'error'
    }
    return token
  }

  public async getLocalUser(): Promise<string> {
    let user = localStorage?.getItem(this.KEY_LOCAL_STORAGE_USER)
    if (!user) {
      // let snackBarRef = this.snackBar.open('login')
      this.router.navigate(['/'])
      return 'error'
    } else {
      return user
    }
  }

  public setNewUser(): IUser {
    let user: IUser = {
      username: '',
      email: '',
      name: '',
      surname: '',
      businessName: '',
      password: '',
      location: ' ',
      phone: ' ',
      avatar: {
        url: '',
        relativeDirectory: '',
        filename: '',
        mimetype: '',
        originalName: '',
        title: '',
        description: '',
        size: 0
      },
      address: {
        address: '',
        number: '',
        floor: '',
        zip: '',
        city: '',
        geolocation: {
          lon: 0,
          lat: 0
        }
      },
      role: ['user']
    }
    return user
  }

  async create(user: any): Promise<Observable<any>> {
    const auth_token = await this.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.post(environment.apiUrl + '/admin/users', user, { headers })
  }

  async getAll(): Promise<Observable<any>> {
    const auth_token = await this.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/users', { headers })
  }

  async getAllManagers(): Promise<Observable<any>> {
    const auth_token = await this.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/users/list/eventManagers', { headers })
  }

  async getTaquilleros(): Promise<Observable<any>> {
    const auth_token = await this.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/users/ventaTaquilla', { headers })
  }

  async getValidators(): Promise<Observable<any>> {
    const auth_token = await this.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/users/validador', { headers })
  }

  async delete(id: string): Promise<Observable<any>> {
    const auth_token = await this.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.delete(environment.apiUrl + '/admin/users/' + id, { headers })
  }

  async updateOne(id: string, user: any): Promise<Observable<any>> {
    const auth_token = await this.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.put(environment.apiUrl + '/admin/users/' + id, user, { headers })
  }
}
