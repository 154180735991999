<div class="columned-initial">
  <mat-card>
    <div mat-line *ngIf="!actualStatus">
      <h2 style="color: white; background-color: red; border-radius: 3px; padding-left: 3px">
        No hay conexión
      </h2>
    </div>
    <div mat-line *ngIf="actualStatus">
      <h2 style="color: white; background-color: green; border-radius: 3px; padding-left: 3px">
        Sí hay conexión
      </h2>
    </div>
    <mat-card-title
      ><div class="separed-buttons">
        <div>Lista de eventos</div>
        <div (click)="openSearch()"><mat-icon>search</mat-icon></div>
      </div>
    </mat-card-title>
    <mat-card-subtitle *ngIf="showSearch">
      <div class="white-text-search" mat-line>
        <mat-form-field class="lined">
          <mat-label class="white-text-search">Buscar</mat-label>
          <input
            id="searchInput"
            matInput
            [(ngModel)]="search"
            (ngModelChange)="searchEvent(search)"
            (keyup.enter)="openSearch()"
            autofocus />
        </mat-form-field>
      </div>
    </mat-card-subtitle>

    <mat-divider></mat-divider>
    <mat-card-content>
      <mat-list>
        <mat-list-item
          *ngFor="let eventoItem of events"
          style="margin-top: 30px; margin-bottom: 30px">
          <div class="columned" (click)="selectEvent(eventoItem)">
            <h3 class="white-text" mat-line>
              <strong>{{ eventoItem.name }}</strong>
            </h3>
            <p class="white-text" mat-line>Recinto: {{ eventoItem.enclosure.name }}</p>
            <ng-container *ngIf="!eventoItem.perpetualEvent">
              <div class="white-text" mat-line>
                <mat-form-field>
                  <mat-label style="color: white" *ngIf="!fechaSelected"
                    >Selecciona fecha:
                  </mat-label>
                  <mat-select
                    class="white-text"
                    [(ngModel)]="fechaSelected"
                    name="fecha"
                    (selectionChange)="selectFecha(fechaSelected)">
                    <mat-option *ngFor="let fecha of sessions" [value]="fecha">
                      {{ fecha.date | date: 'dd/MM/yy' }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>

            <ng-container
              *ngIf="eventoItem.perpetualEvent"
              style="margin-bottom: 20px; margin-top: 20px">
              <div class="white-text" mat-line>
                <mat-form-field>
                  <input
                    matInput
                    [(ngModel)]="fechaSelectedMientras"
                    (dateInput)="OnDateChange($event.value)"
                    [matDatepicker]="picker"
                    [min]="minDate"
                    placeholder="Elige día" />

                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </ng-container>

            <div *ngIf="fechaSelectedMientras">
              <div *ngIf="!tipoPerpetuo">
                <div *ngFor="let perpetualSession of evento.PerpetualSession">
                  <div (click)="saveSession(perpetualSession)">
                    {{ perpetualSession.name }}
                  </div>
                </div>
                <div *ngIf="tipoPerpetuo">
                  {{ tipoPerpetuo }}
                </div>
              </div>
            </div>

            <ng-container *ngIf="!eventoItem.perpetualEvent">
              <div class="white-text" mat-line>
                <mat-form-field>
                  <mat-label style="color: white" *ngIf="!hourSelected"
                    >Selecciona hora:
                  </mat-label>
                  <mat-select
                    class="white-text"
                    [(ngModel)]="hourSelected"
                    name="hour"
                    (selectionChange)="selectHour(hourSelected)">
                    <mat-option *ngFor="let hour of hours" [value]="hour">
                      {{ hour.substring(0, 5) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
    <div
      class="centered-buttons"
      *ngIf="(fechaSelected && hourSelected) || (fechaSelected && perpetualSession)">
      <div class="centered-buttons">
        <ngx-scanner-qrcode
          id="scan"
          (event)="readQR(action); action.stop()"
          #action="scanner"
          [config]="config"
          [scannerQRCodeDevice]="device"
          [canvasStyles]="canvasStyles"></ngx-scanner-qrcode>

        <!-- Loading -->
        <p *ngIf="action.isLoading">⌛ Loading...</p>

        <!-- start -->
        <button
          mat-raised-button
          style="margin-bottom: 10px"
          color="primary"
          (click)="handle(action, action.isStart ? 'stop' : 'start')">
          {{ action.isStart ? 'Stop' : 'Validar Entradas' }}
        </button>
        <!--  (click)="action.isStart ? action.stop() : action.start()" -->
        <!-- data  -->
        <!--  <p (click)="readQR(action.data)">{{ action.data.value | json }}</p> -->
        <!-- value -->
      </div>
      <button mat-raised-button (click)="gotoList()">Volver</button>
    </div>
    <mat-divider></mat-divider>
  </mat-card>
</div>
