<div class="subcategories">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear nueva Subcategoría</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar Subcategoría</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar Subcategoría</mat-card-title>
    <mat-card-content>
      <form [formGroup]="createSubcategoryForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" />
            <div *ngIf="submitted && ['f.name.errors']" class="invalid-feedback">
              <div *ngIf="['f.name.errors.required']">name is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="fill" class="halfWidth">
            <mat-label>Select</mat-label>
            <mat-select formControlName="category" [compareWith]="compareFn">
              <mat-option *ngFor="let option of dataSource" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-raised-button (click)="gotoList()">Volver</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
