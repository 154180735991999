import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { CategoriesService } from 'src/app/shared/services/categories.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'actions']
  dataSource: any
  @ViewChild(MatPaginator)
  paginator!: MatPaginator

  constructor(private categoriesService: CategoriesService, private router: Router) {}

  ngOnInit(): void {
    this.getData()
  }

  async getData() {
    ;(await this.categoriesService.getAll()).subscribe({
      next: async (response: any) => {
        this.dataSource = new MatTableDataSource(response.category)
        this.dataSource.paginator = this.paginator
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  delete(id: string) {
    Swal.fire({
      title: '¿Quieres borrar la categoría?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.categoriesService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Categoría borrada correctamente', '', 'success')
            this.getData()
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se ha podido borrar la categoría', '', 'error')
          }
        })
      }
    })
  }

  gotoCreate() {
    this.router.navigate(['/home/categories'])
  }

  gotoEdit(category: any) {
    this.router.navigate(['/home/categories'], {
      state: { mode: 'edit', category: category }
    })
  }

  gotoWatch(category: any) {
    this.router.navigate(['/home/categories'], {
      state: { mode: 'watch', category: category }
    })
  }
}
