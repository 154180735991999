import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AccountService } from 'src/app/shared/services/account.service'
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class SeatMapsService {
  constructor(private _http: HttpClient, private accountService: AccountService) {}

  async create(seatMap: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.post(environment.apiUrl + '/admin/seat-maps', seatMap, { headers })
  }

  async getAll(): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/seat-maps', { headers })
  }

  async getOne(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/seat-maps/' + id, { headers })
  }

  async updateOne(id: string, seatMap: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.put(environment.apiUrl + '/admin/seat-maps/' + id, seatMap, { headers })
  }

  async delete(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.delete(environment.apiUrl + '/admin/seat-maps/' + id, { headers })
  }

  async getAllbyEnclosure(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/enclosures/seatmaps/' + id, { headers })
  }

  /*


update-price-by-position/ : id






  */

  async updateSeat(idSeatMap: string, imageSeat: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.put(
      environment.apiUrl + '/admin/seat-maps/update-imagesAndPrice/' + idSeatMap,
      imageSeat,
      {
        headers
      }
    )
  }

  async updateSeatImages(idSeatMap: string, imageSeat: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.put(
      environment.apiUrl + '/admin/seat-maps/update-image-by-position/' + idSeatMap,
      imageSeat,
      {
        headers
      }
    )
  }
}
