<div class="access">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear acceso</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar acceso</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar acceso</mat-card-title>
    <mat-card-content>
      <form [formGroup]="createAccessForm" (ngSubmit)="onSubmit()">
        <div>
          <img
            src="../../../../../assets/img/up.png"
            alt="No Image"
            width="24"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/up.png', '24', '24', 0)" />
          <img
            src="../../../../../assets/img/down.png"
            alt="No Image"
            width="24"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/down.png', '24', '24', 1)" />
          <img
            src="../../../../../assets/img/left.png"
            alt="No Image"
            width="24"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/left.png', '24', '24', 2)" />
          <img
            src="../../../../../assets/img/right.png"
            alt="No Image"
            width="24"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/right.png', '24', '24', 3)" />
        </div>
        <div>
          <img
            src="../../../../../assets/img/GroupAup.png"
            alt="No Image"
            width="24"
            height="48"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/GroupAup.png', '24', '48', 4)" />
          <img
            src="../../../../../assets/img/GroupAdown.png"
            alt="No Image"
            width="24"
            height="48"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/GroupAdown.png', '24', '48', 5)" />
          <img
            src="../../../../../assets/img/GroupAleft.png"
            alt="No Image"
            width="48"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/GroupAleft.png', '48', '24', 6)" />
          <img
            src="../../../../../assets/img/GroupAright.png"
            alt="No Image"
            width="48"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/GroupAright.png', '48', '24', 7)" />
        </div>
        <div>
          <img
            src="../../../../../assets/img/GroupBup.png"
            alt="No Image"
            width="24"
            height="48"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/GroupBup.png', '24', '48', 8)" />
          <img
            src="../../../../../assets/img/GroupBdown.png"
            alt="No Image"
            width="24"
            height="48"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/GroupBdown.png', '24', '48', 9)" />
          <img
            src="../../../../../assets/img/GroupBleft.png"
            alt="No Image"
            width="48"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/GroupBleft.png', '48', '24', 10)" />
          <img
            src="../../../../../assets/img/GroupBright.png"
            alt="No Image"
            width="48"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/GroupBright.png', '48', '24', 11)" />
        </div>
        <!--<div class="form-group">
          <mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Posición X</mat-label>
            <input type="number" matInput formControlName="x" />
            <div *ngIf="submitted && ['f.number.errors']" class="invalid-feedback">
              <div *ngIf="['f.x.errors.required']">x is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Posición Y</mat-label>
            <input type="number" matInput formControlName="y" />
            <div *ngIf="submitted && ['f.y.errors']" class="invalid-feedback">
              <div *ngIf="['f.y.errors.required']">y is required</div>
            </div>
          </mat-form-field>
        </div>-->
        <!--<div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-raised-button (click)="gotoList()">Volver</button>
        </div>-->
        <div>
          <svg
            #svg
            appSvg
            (newItemEvent)="update($event)"
            style="
              border: 1px solid black;
              background-image: url('../../../../../assets/img/grid.png');
            "
            stroke="none"
            width="1000"
            height="700"
            xmlns="<http://www.w3.org/2000/svg>">
            <polygon [fill]="sectionColor" stroke="black" stroke-width="0.2"></polygon>
          </svg>
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-stroked-button class="light-button" type="button" (click)="gotoEdit()">
            Volver
          </button>
          <button mat-stroked-button class="light-button" type="button" (click)="onViewChange()">
            Limpiar
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
