<div class="perpetual">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear nuevo tipo de sesión perpetua</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar sesión perpetua</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar sesión perpetua</mat-card-title>
    <mat-card-content>
      <form [formGroup]="createPerpetualSessionGroup" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <mat-form-field appearance="standard" class="fullWidth">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" />
            <div *ngIf="submitted && ['f.name.errors']" class="invalid-feedback">
              <div *ngIf="['f.name.errors.required']">name is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="fullWidth">
            <mat-label>Descripción</mat-label>
            <textarea
              matInput
              formControlName="description"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="12"></textarea>
            <div *ngIf="submitted && ['f.description.errors']" class="invalid-feedback">
              <div *ngIf="['f.description.errors.required']">description is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="priceWidth">
            <mat-label>Precio</mat-label>
            <input type="number" matInput formControlName="price" min="0" />
            <div *ngIf="submitted && ['f.price.errors']" class="invalid-feedback">
              <div *ngIf="['f.price.errors.required']">price is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="priceWidth">
            <mat-label>Número de entradas</mat-label>
            <input type="number" matInput formControlName="nMaxTickets" min="0" />
            <div *ngIf="submitted && ['f.nMaxTickets.errors']" class="invalid-feedback">
              <div *ngIf="['f.nMaxTickets.errors.required']">nMaxTickets is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-raised-button (click)="gotoList()">Volver</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
