<div class="seats-editor">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear asientos</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar asientos</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar asientos</mat-card-title>
    <mat-card-content>
      <form [formGroup]="createSeatsForm" (ngSubmit)="onSubmit()">
        <div class="">
          <mat-radio-group (change)="radioChange()" [value]="this.general">
            <mat-radio-button [value]="false" *ngIf="sectionType">Asientos</mat-radio-button>
            <mat-radio-button [value]="true" *ngIf="sectionType">General</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="stageWidth" *ngIf="!general">
            <mat-label>Número de filas</mat-label>
            <input type="number" matInput formControlName="rows" min="1" />
            <div *ngIf="submitted && ['f.rows.errors']" class="invalid-feedback">
              <div *ngIf="['f.rows.errors.required']">rows is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="stageWidth" *ngIf="!general">
            <mat-label>Número de columnas</mat-label>
            <input type="number" matInput formControlName="columns" min="1" />
            <div *ngIf="submitted && ['f.columns.errors']" class="invalid-feedback">
              <div *ngIf="['f.columns.errors.required']">columns is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="stageWidth" *ngIf="general">
            <mat-label>Número de asientos</mat-label>
            <input type="number" matInput formControlName="number" min="0" value="0" />
            <!-- <div *ngIf="!general && submitted && ['f.columns.errors']" class="invalid-feedback">
              <div *ngIf="['f.columns.errors.required']">number is required</div>
            </div> -->
          </mat-form-field>
        </div>
        <div class="form-group" *ngIf="!general">
          <mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Curvatura</mat-label>
            <input type="number" matInput formControlName="curve" min="-10" max="10" />
            <div *ngIf="submitted && ['f.curve.errors']" class="invalid-feedback">
              <div *ngIf="['f.curve.errors.required']">curve is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group" *ngIf="!general">
          <mat-form-field appearance="standard" class="labelWidth">
            <mat-label>Etiquetas para asientos (introduzcalas separadas por comas)</mat-label>
            <input matInput formControlName="label" />
          </mat-form-field>
        </div>
        <div class="form-group" *ngIf="!general">
          <mat-form-field appearance="standard" class="labelWidth">
            <mat-label>Etiquetas para filas (introduzcalas separadas por comas)</mat-label>
            <input matInput formControlName="rowlabel" />
          </mat-form-field>
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="generateSeats('create')"
            *ngIf="!general">
            Generar asientos
          </button>
          <button mat-raised-button color="primary" [disabled]="loading" class="actionButton">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-raised-button (click)="gotoList()" type="button">Volver</button>
        </div>
      </form>
      <div></div>
    </mat-card-content>
    <mat-card-footer *ngIf="!general">
      <h3>Haga click en los asientos que no serán elegibles</h3>
      <div *ngFor="let seatrow of seatmap">
        <div class="seatrow container">
          <div *ngIf="seatChartConfig?.showRowsLabel">
            <div class="row-label">{{ seatrow.seatRowLabel }}</div>
          </div>
          <div *ngFor="let seatobj of seatrow.seats">
            <div
              class="seat-item seat-space"
              style="color: white; background-color: white"
              [style.margin-top.rem]="seatobj.seatMargin"
              *ngIf="seatobj.seatLabel === ''">
              &nbsp;
            </div>
            <div
              tooltip="Seat : {{ seatobj.seatLabel }} | Price : {{ seatobj.price }}Rs"
              placement="top"
              [style.margin-top.rem]="seatobj.seatMargin"
              [ngClass]="{
                'seat-item': true,
                'seat-available': seatobj.status === 'available',
                'seat-booked': seatobj.status === 'booked',
                'seat-unavailable': seatobj.status === 'unavailable'
              }"
              (click)="selectSeat(seatobj)"
              *ngIf="
                (seatobj.status === 'available' || seatobj.status === 'booked') &&
                seatobj.seatLabel !== ''
              ">
              {{ seatobj.seatNo }}
            </div>

            <div
              tooltip="Seat not available"
              placement="top"
              [style.margin-top.rem]="seatobj.seatMargin"
              [ngClass]="{ 'seat-item': true, 'seat-unavailable': true }"
              *ngIf="seatobj.status === 'unavailable' && seatobj.seatLabel !== ''">
              {{ seatobj.seatNo }}
            </div>
          </div>
        </div>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
