<div class="events-details1"></div>
<div class="events-details">
  <mat-card>
    <mat-card-title>Datos de evento</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div id="map">
        <map-marker
          [position]="marker.position"
          [label]="marker.label"
          [title]="marker.title"
          [options]="marker.options">
        </map-marker>
      </div>
      <!--<google-map [center]="center" [zoom]="zoom">
        <map-marker
          #markerElem
          [position]="markerPosition.position"
          [label]="markerPosition.label"
          [title]="markerPosition.title"
          [options]="markerPosition.options">
        </map-marker>
        (mapClick)="openInfo(markerElem, markerPosition.info)">

        <map-info-window>{{ infoContent }}</map-info-window>
      </google-map>--></mat-card-content
    >
    <mat-card-content
      ><div>Nombre del evento: {{ loadedEvent.name }}</div>
      <div>Recinto: {{ loadedEvent.enclosure.name }}</div>
      <div>
        Dirección del recinto: {{ loadedEvent.enclosure.address.address }},
        {{ loadedEvent.enclosure.address.number }}, {{ loadedEvent.enclosure.address.city }}
      </div></mat-card-content
    ><mat-card-footer>
      <button mat-raised-button color="primary" (click)="gotoEdit()">Editar</button>
    </mat-card-footer></mat-card
  >
</div>
<div class="events-details">
  <mat-card
    (click)="gotoEventSessions()"
    class="clickable"
    *ngIf="sessionsList.length === 0 && perpetualList.length === 0">
    <mat-card-title>Sesiones y precios</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content> Aún no hay fecha creada</mat-card-content></mat-card
  >
  <mat-card
    (click)="gotoEventSessionsDetails()"
    class="clickable"
    *ngIf="sessionsList.length > 0 && perpetualList.length === 0">
    <mat-card-title>Sesiones y precios</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content> {{ sessionsList.length }} Sesiones creadas</mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-footer>
      <button mat-raised-button color="primary" (click)="gotoEventSessions()">
        Crear nueva sesión
      </button>
    </mat-card-footer></mat-card
  >
  <mat-card (click)="gotoPerpetual()" class="clickable" *ngIf="perpetualList.length > 0">
    <mat-card-title>Sesiones perpetuas</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content> {{ perpetualList.length }} Sesiones creadas</mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-footer>
      <button mat-raised-button color="primary" (click)="gotoPerpetual()">
        Crear nueva sesión perpetua
      </button>
    </mat-card-footer></mat-card
  >
</div>

<!--<div class="events-details">
  <mat-card>
    <mat-card-title>Secciones y precios</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content> Aún no hay precios creados</mat-card-content></mat-card
  >
</div>-->

<div class="events-details">
  <mat-card (click)="gotoDiscountsRates()" class="clickable">
    <mat-card-title>Tarifas y descuentos</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content *ngIf="dataSourceCodes.length === 0 && dataSourceRates.length === 0">
      Aún no hay tarifas y descuentos creados</mat-card-content
    >
    <mat-card-content *ngIf="dataSourceCodes.length > 0 || dataSourceRates.length > 0">
      {{ dataSourceCodes.length }} Códigos de descuento creados y
      {{ dataSourceRates.length }} tarifas creadas</mat-card-content
    ></mat-card
  >
</div>
<div class="events-details3">
  <button mat-raised-button color="primary" (click)="gotoList()">Cancelar</button>
</div>
