import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { BannersService } from 'src/app/shared/services/banners.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-banners-list',
  templateUrl: './banners-list.component.html',
  styleUrls: ['./banners-list.component.scss']
})
export class BannersListComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'image',
    'since',
    'until',
    'active',
    'homeDown',
    'homeTop',
    'envenDown',
    'eventTop',
    'url',
    'actions'
  ]
  dataSource: any
  @ViewChild(MatPaginator)
  paginator!: MatPaginator

  constructor(private bannersService: BannersService, private router: Router) {}

  ngOnInit(): void {
    this.getData()
  }

  async getData() {
    ;(await this.bannersService.getAll()).subscribe({
      next: async (response: any) => {
        this.dataSource = new MatTableDataSource(response.banners)
        this.dataSource.paginator = this.paginator
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  delete(id: string) {
    Swal.fire({
      title: '¿Quieres borrar el banner?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.bannersService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Banner borrado correctamente', '', 'success')
            this.getData()
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se ha podido borrar el banner', '', 'error')
          }
        })
      }
    })
  }

  gotoCreate() {
    this.router.navigate(['/home/banners'])
  }

  gotoEdit(banner: any) {
    this.router.navigate(['/home/banners'], {
      state: { mode: 'edit', banner: banner }
    })
  }

  gotoWatch(banner: any) {
    this.router.navigate(['/home/banners'], {
      state: { mode: 'watch', banner: banner }
    })
  }
}
