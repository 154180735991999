/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { HttpClient } from '@angular/common/http'
import { Component, OnInit, ViewChild } from '@angular/core'
import { GoogleMap, MapInfoWindow } from '@angular/google-maps'
import { Router } from '@angular/router'
import { Loader } from '@googlemaps/js-api-loader'
import { EventsService } from 'src/app/shared/services/events.service'
import { PerpetualService } from 'src/app/shared/services/perpetual.service'
import { PromotionalCodesService } from 'src/app/shared/services/promotional-codes.service'
import { ReducedRatesService } from 'src/app/shared/services/reduced-rates.service'
import { SessionsService } from 'src/app/shared/services/sessions.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-events-detail',
  templateUrl: './events-detail.component.html',
  styleUrls: ['./events-detail.component.scss']
})
export class EventsDetailComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false })
  //map!: GoogleMap
  map: any
  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow
  loadedEvent: any
  zoom = 12
  marker: any = {}
  center!: google.maps.LatLngLiteral
  markerPosition: any = {}
  mode = 'create'
  sessionsList = []
  dataSourceRates: any
  dataSourceCodes: any
  perpetualList = []
  //google maps
  loader = new Loader({
    apiKey: environment.apiUrl,
    version: 'weekly'
  })
  markerPositions: any = []
  // map!: google.maps.Map

  //google maps
  constructor(
    httpClient: HttpClient,
    private router: Router,
    private sessionsService: SessionsService,
    private eventsService: EventsService,
    private promotionalCodesService: PromotionalCodesService,
    private reducedRatesService: ReducedRatesService,
    private perpetualService: PerpetualService
  ) {}
  ngOnInit(): void {
    this.loadedEvent = history.state.event
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    this.getCodes()
    this.getRates()

    this.getPerpetuals()

    this.getSessions()

    //google maps

    this.loader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        center: {
          lat: this.loadedEvent.enclosure.address.geolocation.lat,
          lng: this.loadedEvent.enclosure.address.geolocation.lon
        },
        zoom: 16
        /*this.marker = {
          position: {
            lat: this.loadedEvent.enclosure.address.geolocation.lat,
            lng: this.loadedEvent.enclosure.address.geolocation.lon
          },
          label: {
            color: 'red',
            text: this.loadedEvent.place
          },
          title: this.loadedEvent.place,
          info: this.loadedEvent.place,
          options: {
            animation: google.maps.Animation.BOUNCE
          }
        }*/
      })
    })
    //google maps
  }

  onMapClick(event: any) {
    console.log(event)
  }

  onMapReady(map: any) {
    map.addListener(this.onMapClick)
  }

  async getSessions() {
    ;(await this.eventsService.getOne2(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        this.sessionsList = response.event.sessions
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
    /*;(await this.sessionsService.getAllbyEventId(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        this.sessionsList = response.sessions
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })*/
  }

  async getCodes() {
    ;(await this.promotionalCodesService.getAllbyEventId(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        this.dataSourceCodes = response.promotionalCodes
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  async getRates() {
    ;(await this.reducedRatesService.getAllbyEventId(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        this.dataSourceRates = response.reducedRates
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  async getPerpetuals() {
    ;(await this.perpetualService.getAllbyEventId(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        this.perpetualList = response.perpetualSessions
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  gotoList() {
    this.router.navigate(['/home/events-list'])
  }

  gotoEdit() {
    if (this.mode === 'edit') {
      this.router.navigate(['/home/events'], {
        state: { mode: 'edit', event: this.loadedEvent }
      })
    } else if (this.mode === 'watch') {
      this.router.navigate(['/home/events'], {
        state: { mode: 'watch', event: this.loadedEvent }
      })
    }
  }

  gotoEventSessions() {
    this.router.navigate(['/home/event-sessions'], {
      state: { mode: 'create', event: this.loadedEvent }
    })
  }

  gotoEventSessionsDetails() {
    this.router.navigate(['/home/event-sessions-details'], {
      state: { mode: 'edit', event: this.loadedEvent }
    })
  }

  gotoDiscountsRates() {
    this.router.navigate(['/home/discounts-rates'], {
      state: { mode: 'create', event: this.loadedEvent }
    })
  }

  gotoPerpetual() {
    this.router.navigate(['/home/perpetual-list'], {
      state: { mode: 'edit', event: this.loadedEvent }
    })
  }
}
