<mat-toolbar class="header">
  <img src="../../../../../assets/img/logo-fundos.svg" class="logo" />
  <button
    class="white-button"
    mat-icon-button
    aria-label="Logout"
    (click)="logout()"
    *ngIf="isLoggedIn()">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>

<!-- Toolbar
<div class="toolbar" role="banner" style="background-color: #2f363e">
  <img width="40" src="" />
  <span>Ticketernal</span>
  <div class="spacer"></div>
</div>-->
