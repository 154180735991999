import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { IEnclosure } from 'src/app/shared/models/enclosures.model'
import { AccountService } from 'src/app/shared/services/account.service'
import { BannersService } from 'src/app/shared/services/banners.service'
import { FilesService } from 'src/app/shared/services/files.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  public createBannerForm!: FormGroup
  loading = false
  submitted = false
  public newBanner!: IEnclosure
  categoriesList: any
  subCcategoriesList: any
  enclosuresList: any
  userList: any
  subCategoriesList: any
  loadedBanner: any
  mode = 'create'
  selectedFile: any
  selectedFileName: string = ''
  selectedFileDesktop: any
  selectedFileNameDesktop: string = ''
  selectedFileMobile: any
  selectedFileNameMobile: string = ''
  imgDesktopLoaded: boolean = false
  imgMobileLoaded: boolean = false
  allSelected: boolean = false
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private filesService: FilesService,
    private bannersService: BannersService
  ) {}

  async ngOnInit(): Promise<void> {
    this.createBannerForm = this.fb.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
      since: ['', Validators.required],
      until: ['', Validators.required],
      active: [false, Validators.required],
      imgDesktop: ['', Validators.required],
      imgMobile: ['', Validators.required],
      homeTop: [false, Validators.required],
      homeDown: [false, Validators.required],
      eventTop: [false, Validators.required],
      envenDown: [false, Validators.required],
      all: [false, Validators.required],
      some: [false, Validators.required]
    })
    this.loadedBanner = history.state.banner
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      this.setLoadedData()
    }
  }
  setLoadedData() {
    this.createBannerForm.controls['name'].setValue(this.loadedBanner.name)
    this.createBannerForm.controls['url'].setValue(this.loadedBanner.url)
    this.createBannerForm.controls['since'].setValue(this.loadedBanner.since)
    this.createBannerForm.controls['until'].setValue(this.loadedBanner.until)
    this.createBannerForm.controls['active'].setValue(this.loadedBanner.active)
    this.createBannerForm.controls['imgDesktop'].setValue(this.loadedBanner.imgDesktop.originalName)
    this.createBannerForm.controls['imgMobile'].setValue(this.loadedBanner.imgMobile.originalName)
    this.createBannerForm.controls['homeTop'].setValue(this.loadedBanner.homeTop)
    this.createBannerForm.controls['homeDown'].setValue(this.loadedBanner.homeDown)
    this.createBannerForm.controls['eventTop'].setValue(this.loadedBanner.eventTop)
    this.createBannerForm.controls['envenDown'].setValue(this.loadedBanner.envenDown)
    this.selectedFileDesktop = this.loadedBanner.imgDesktop
    this.selectedFileMobile = this.loadedBanner.imgMobile
    this.selectedFileNameDesktop = this.loadedBanner.imgDesktop.url
    this.selectedFileNameMobile = this.loadedBanner.imgMobile.url
    this.imgDesktopLoaded = true
    this.imgMobileLoaded = true
  }

  public async onSubmit(): Promise<void> {
    if (this.createBannerForm) {
      if (this.mode === 'edit') {
        await this.edit()
      } else {
        const newBanner = {
          name: this.createBannerForm.get('name')?.value,
          url: this.createBannerForm.get('url')?.value,
          since: this.createBannerForm.get('since')?.value,
          until: this.createBannerForm.get('until')?.value,
          active: this.createBannerForm.get('active')?.value,
          imgDesktop: this.selectedFileDesktop.id,
          imgMobile: this.selectedFileMobile?.id || this.selectedFileDesktop.id,
          homeTop: this.createBannerForm.get('homeTop')?.value,
          homeDown: this.createBannerForm.get('homeDown')?.value,
          eventTop: this.createBannerForm.get('eventTop')?.value,
          envenDown: this.createBannerForm.get('envenDown')?.value
        }
        this.submitted = true
        console.log(newBanner)
        if (newBanner.active) {
          ;(
            await this.bannersService.getConflicts(
              newBanner.homeTop,
              newBanner.homeDown,
              newBanner.eventTop,
              newBanner.envenDown
            )
          ).subscribe({
            next: async response => {
              console.log(response)
              if (response.success) {
                const banners = response.banners
                let bannersConflictive = ''
                for (const banner of banners) {
                  bannersConflictive = bannersConflictive + banner.name + ', '
                }
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'center',
                  showConfirmButton: true,
                  showCancelButton: true,
                  timerProgressBar: true,
                  width: 'auto',
                  buttonsStyling: true,
                  confirmButtonColor: '#5db2e4',
                  cancelButtonColor: '#f27474'
                })
                Toast.fire({
                  title: 'Conflicto de banners',
                  text:
                    'Los banners "' +
                    bannersConflictive +
                    '" están activos en las mismas posiciones',
                  confirmButtonText: 'Si, continuar',
                  cancelButtonText: 'Cancelar'
                }).then(async result => {
                  if (result.isConfirmed) {
                    ;(await this.bannersService.create(newBanner)).subscribe({
                      next: async response => {
                        Swal.fire('Banner creado correctamente', '', 'success')
                        this.router.navigate(['/home/banners-list'])
                      },
                      complete: () => {},
                      error: error => {
                        console.log(error)
                      }
                    })
                  }
                })
              }
            },
            complete: () => {},
            error: error => {
              console.log(error)
            }
          })
        } else {
          ;(await this.bannersService.create(newBanner)).subscribe({
            next: async response => {
              Swal.fire('Banner creado correctamente', '', 'success')
              this.router.navigate(['/home/banners-list'])
            },
            complete: () => {},
            error: error => {
              console.log(error)
            }
          })
        }
      }
    }
  }

  public async edit(): Promise<void> {
    let editedBanner: any
    if (this.selectedFileDesktop || this.selectedFileMobile) {
      editedBanner = {
        name: this.createBannerForm.get('name')?.value,
        url: this.createBannerForm.get('url')?.value,
        since: this.createBannerForm.get('since')?.value,
        until: this.createBannerForm.get('until')?.value,
        active: this.createBannerForm.get('active')?.value,
        imgDesktop: {
          id: this.selectedFileDesktop.id || this.createBannerForm.get('imgDesktop')?.value
        },
        imgMobile: {
          id:
            this.selectedFileMobile?.id ||
            this.selectedFileDesktop.id ||
            this.createBannerForm.get('imgMobile')?.value
        },
        homeTop: this.createBannerForm.get('homeTop')?.value,
        homeDown: this.createBannerForm.get('homeDown')?.value,
        eventTop: this.createBannerForm.get('eventTop')?.value,
        envenDown: this.createBannerForm.get('envenDown')?.value
      }
    } else {
      editedBanner = {
        name: this.createBannerForm.get('name')?.value,
        url: this.createBannerForm.get('url')?.value,
        since: this.createBannerForm.get('since')?.value,
        until: this.createBannerForm.get('until')?.value,
        active: this.createBannerForm.get('active')?.value,
        imgDesktop: { id: this.createBannerForm.get('imgDesktop')?.value },
        imgMobile: { id: this.createBannerForm.get('imgMobile')?.value },
        homeTop: this.createBannerForm.get('homeTop')?.value,
        homeDown: this.createBannerForm.get('homeDown')?.value,
        eventTop: this.createBannerForm.get('eventTop')?.value,
        envenDown: this.createBannerForm.get('envenDown')?.value
      }
    }
    this.submitted = true
    ;(await this.bannersService.updateOne(this.loadedBanner.id, editedBanner)).subscribe({
      next: async response => {
        Swal.fire('Banner editado correctamente', '', 'success')
        this.router.navigate(['/home/banners-list'])
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  gotoList() {
    this.router.navigate(['/home/banners-list'])
  }

  gotoWeb() {
    let url = this.createBannerForm.get('url')?.value
    if (!(url.includes('http://') || url.includes('https://'))) {
      url = 'https://' + url
    }
    window.open(url)
  }

  async selectFileDesktop(banner: any) {
    this.selectedFileDesktop = banner.target.files
    const file: File = this.selectedFileDesktop.item(0)
    ;(await this.filesService.create(file)).subscribe({
      next: async response => {
        Swal.fire('Imagen subida correctamente', '', 'success')
        this.selectedFileDesktop = response.file
        this.selectedFileNameDesktop = response.file.url
        this.imgDesktopLoaded = true
        this.createBannerForm.controls['imgDesktop'].setValue(this.selectedFileDesktop)
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
    /* this.selectedFileNameDesktop = file.name
    this.imgDesktopLoaded = true */
  }

  async selectFileMobile(banner: any) {
    this.selectedFileMobile = banner.target.files
    const file: File = this.selectedFileMobile.item(0)
    ;(await this.filesService.create(file)).subscribe({
      next: async response => {
        Swal.fire('Imagen subida correctamente', '', 'success')
        this.selectedFileMobile = response.file
        this.selectedFileNameMobile = response.file.url
        this.imgMobileLoaded = true
        this.createBannerForm.controls['imgMobile'].setValue(this.selectedFileMobile)
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2
  }

  public allPages() {
    if (this.createBannerForm.get('all')?.value === 'on') {
      this.createBannerForm.controls['homeTop'].setValue(true)
      this.createBannerForm.controls['homeDown'].setValue(true)
      this.createBannerForm.controls['eventTop'].setValue(true)
      this.createBannerForm.controls['envenDown'].setValue(true)
      this.allSelected = true
    } else {
      this.createBannerForm.controls['homeTop'].setValue(false)
      this.createBannerForm.controls['homeDown'].setValue(false)
      this.createBannerForm.controls['eventTop'].setValue(false)
      this.createBannerForm.controls['envenDown'].setValue(false)
      this.allSelected = false
    }
  }

  public noPages() {
    if (this.createBannerForm.get('some')?.value === 'on') {
      this.createBannerForm.controls['homeTop'].setValue(false)
      this.createBannerForm.controls['homeDown'].setValue(false)
      this.createBannerForm.controls['eventTop'].setValue(false)
      this.createBannerForm.controls['envenDown'].setValue(false)
      this.allSelected = false
    }
  }
}
