<div class="users">
  <mat-card class="mainCard">
    <mat-card-title>Tarifas y descuentos</mat-card-title>
    <mat-card-content>
      <div>
        <form [formGroup]="typeForm">
          <mat-radio-group aria-label="" (change)="radioChange($event)" formControlName="type">
            <mat-radio-button [value]="'Empresa'">Tarifa reducida</mat-radio-button>
            <mat-radio-button [value]="'Particular'">Código promocional</mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
      <div>
        <form [formGroup]="createRateForm" (ngSubmit)="onRateSubmit()" *ngIf="isRate">
          <div>
            <mat-card class="first">
              <mat-card-title>Descuento sobre el precio</mat-card-title>
              <mat-card-content>
                <div>
                  <form [formGroup]="discountTypeForm">
                    <mat-radio-group
                      aria-label=""
                      (change)="radioDiscountChange($event)"
                      formControlName="type">
                      <mat-radio-button [value]="'Porcentaje'">Porcentaje</mat-radio-button>
                      <mat-radio-button [value]="'Cantidad'">Cantidad</mat-radio-button>
                    </mat-radio-group>
                  </form>
                </div>
                <div>
                  <mat-form-field appearance="standard" class="stageWidth" *ngIf="!isQuantity">
                    <mat-label>Porcentaje descuento</mat-label>
                    <input type="number" matInput formControlName="discountPercentage" />
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="stageWidth" *ngIf="isQuantity">
                    <mat-label>Cantidad descuento</mat-label>
                    <input type="number" matInput formControlName="discountQuantity" />
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="first">
              <mat-card-title>Grupos aplicables</mat-card-title>
              <mat-card-content>
                <div class="form-group">
                  <mat-checkbox formControlName="group1" class="quarterWidth"
                    >Mayores de 65 años y pensionistas</mat-checkbox
                  >
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="group2" class="quarterWidth"
                    >Estudiantes</mat-checkbox
                  >
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="group3" class="quarterWidth"
                    >Personas con discapacidad desde 33%</mat-checkbox
                  >
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="group4" class="quarterWidth"
                    >Desempleados</mat-checkbox
                  >
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="group5" class="quarterWidth"
                    >Carné joven</mat-checkbox
                  >
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="group6" class="quarterWidth"
                    >Menores de...</mat-checkbox
                  >
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="first">
              <mat-card-title>Días donde aplicar</mat-card-title>
              <mat-card-content>
                <div class="form-group">
                  <mat-checkbox formControlName="monday" class="quarterWidth">Lunes</mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="tuesday" class="quarterWidth">Martes</mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="wednesday" class="quarterWidth"
                    >Miércoles</mat-checkbox
                  >
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="thursday" class="quarterWidth"
                    >Jueves</mat-checkbox
                  >
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="friday" class="quarterWidth">Viernes</mat-checkbox>
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="saturday" class="quarterWidth"
                    >Sábado</mat-checkbox
                  >
                </div>
                <div class="form-group">
                  <mat-checkbox formControlName="sunday" class="quarterWidth">Domingo</mat-checkbox>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="form-group-buttons">
            <button
              mat-raised-button
              color="primary"
              [disabled]="loading"
              class="actionButton"
              *ngIf="mode === 'edit'">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
            </button>
            <button
              mat-raised-button
              color="primary"
              [disabled]="loading"
              class="actionButton"
              *ngIf="mode === 'create'">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
            </button>
            <button mat-raised-button type="button" (click)="gotoList()">Volver</button>
          </div>
        </form>
      </div>
      <div class="fullWidth" *ngIf="!isRate">
        <div>
          <form [formGroup]="createDiscountForm" (ngSubmit)="onDiscountSubmit()" *ngIf="!isRate">
            <div>
              <mat-card class="first">
                <mat-card-title>Descuento sobre el precio</mat-card-title>
                <mat-card-content>
                  <div>
                    <form [formGroup]="discountTypeForm">
                      <mat-radio-group
                        aria-label=""
                        (change)="radioDiscountChange($event)"
                        formControlName="type">
                        <mat-radio-button [value]="'Porcentaje'">Porcentaje</mat-radio-button>
                        <mat-radio-button [value]="'Cantidad'">Cantidad</mat-radio-button>
                      </mat-radio-group>
                    </form>
                  </div>
                  <div>
                    <mat-form-field appearance="standard" class="stageWidth" *ngIf="!isQuantity">
                      <mat-label>Porcentaje descuento</mat-label>
                      <input type="number" matInput formControlName="discountPercentage" />
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="stageWidth" *ngIf="isQuantity">
                      <mat-label>Cantidad descuento</mat-label>
                      <input type="number" matInput formControlName="discountQuantity" />
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="stageWidth">
                      <mat-label>Cantidad de códigos</mat-label>
                      <input type="number" matInput formControlName="numberOfCodes" />
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="first">
                <mat-card-title>Código descuento</mat-card-title>
                <mat-card-content>
                  <mat-form-field appearance="standard" class="fullWidth">
                    <mat-label>Código</mat-label>
                    <input matInput formControlName="name" />
                    <div *ngIf="submitted && ['f.name.errors']" class="invalid-feedback">
                      <div *ngIf="['f.name.errors.required']">code is required</div>
                    </div>
                  </mat-form-field>
                </mat-card-content>
              </mat-card>
              <mat-card class="first">
                <mat-card-title>Días donde aplicar</mat-card-title>
                <mat-card-content>
                  <div class="form-group">
                    <mat-checkbox formControlName="monday" class="quarterWidth">Lunes</mat-checkbox>
                  </div>
                  <div class="form-group">
                    <mat-checkbox formControlName="tuesday" class="quarterWidth"
                      >Martes</mat-checkbox
                    >
                  </div>
                  <div class="form-group">
                    <mat-checkbox formControlName="wednesday" class="quarterWidth"
                      >Miércoles</mat-checkbox
                    >
                  </div>
                  <div class="form-group">
                    <mat-checkbox formControlName="thursday" class="quarterWidth"
                      >Jueves</mat-checkbox
                    >
                  </div>
                  <div class="form-group">
                    <mat-checkbox formControlName="friday" class="quarterWidth"
                      >Viernes</mat-checkbox
                    >
                  </div>
                  <div class="form-group">
                    <mat-checkbox formControlName="saturday" class="quarterWidth"
                      >Sábado</mat-checkbox
                    >
                  </div>
                  <div class="form-group">
                    <mat-checkbox formControlName="sunday" class="quarterWidth"
                      >Domingo</mat-checkbox
                    >
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="form-group-buttons">
              <button
                mat-raised-button
                color="primary"
                [disabled]="loading"
                class="actionButton"
                *ngIf="mode === 'edit'">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
              </button>
              <button
                mat-raised-button
                color="primary"
                [disabled]="loading"
                class="actionButton"
                *ngIf="mode === 'create'">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
              </button>
              <button mat-raised-button type="button" (click)="gotoList()">Volver</button>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="fullCard">
    <mat-card-title>Listado de tarifas con descuento</mat-card-title>
    <mat-card-content>
      <table mat-table [dataSource]="dataSourceRates">
        <ng-container matColumnDef="days">
          <th mat-header-cell *matHeaderCellDef>Días</th>
          <td mat-cell *matCellDef="let element">{{ element.days }}</td>
        </ng-container>
        <ng-container matColumnDef="groups">
          <th mat-header-cell *matHeaderCellDef>Grupos</th>
          <td mat-cell *matCellDef="let element">{{ element.groups }}</td>
        </ng-container>
        <ng-container matColumnDef="percentage">
          <th mat-header-cell *matHeaderCellDef>Porcentaje desc</th>
          <td mat-cell *matCellDef="let element">{{ element.discountPercentage }}</td>
        </ng-container>
        <ng-container matColumnDef="discountValue">
          <th mat-header-cell *matHeaderCellDef>Descuento</th>
          <td mat-cell *matCellDef="let element">{{ element.discountQuantity }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row; let element">
            <button mat-icon-button aria-label="Delete" (click)="deleteRate(element.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsRates"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsRates"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
  <mat-card class="fullCard">
    <mat-card-title>Listado de códigos de descuento</mat-card-title>
    <mat-card-content>
      <table mat-table [dataSource]="dataSourceCodes">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Código</th>
          <td mat-cell *matCellDef="let element">{{ element.code }}</td>
        </ng-container>
        <ng-container matColumnDef="days">
          <th mat-header-cell *matHeaderCellDef>Días</th>
          <td mat-cell *matCellDef="let element">{{ element.days }}</td>
        </ng-container>
        <ng-container matColumnDef="percentage">
          <th mat-header-cell *matHeaderCellDef>Porcentaje desc</th>
          <td mat-cell *matCellDef="let element">{{ element.discountPercentage }}</td>
        </ng-container>
        <ng-container matColumnDef="discountValue">
          <th mat-header-cell *matHeaderCellDef>Descuento</th>
          <td mat-cell *matCellDef="let element">{{ element.discountQuantity }}</td>
        </ng-container>
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Cantidad</th>
          <td mat-cell *matCellDef="let element">{{ element.numberOfCodes }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row; let element">
            <button mat-icon-button aria-label="Delete" (click)="deleteCode(element.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsCodes"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCodes"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
