export const toilets = [
  {
    url: '../../../../../assets/img/male.png',
    width: '24',
    height: '24'
  },
  { url: '../../../../../assets/img/female.png', width: '24', height: '24' },
  { url: '../../../../../assets/img/malefemale.png', width: '24', height: '24' }
]

export const access = [
  { url: '../../../../../assets/img/up.png', width: '24', height: '24' },
  { url: '../../../../../assets/img/down.png', width: '24', height: '24' },
  { url: '../../../../../assets/img/left.png', width: '24', height: '24' },
  { url: '../../../../../assets/img/right.png', width: '24', height: '24' },
  { url: '../../../../../assets/img/GroupAup.png', width: '24', height: '48' },
  { url: '../../../../../assets/img/GroupAdown.png', width: '24', height: '48' },
  { url: '../../../../../assets/img/GroupAleft.png', width: '48', height: '24' },
  { url: '../../../../../assets/img/GroupAright.png', width: '48', height: '24' },
  { url: '../../../../../assets/img/GroupBup.png', width: '24', height: '48' },
  { url: '../../../../../assets/img/GroupBdown.png', width: '24', height: '48' },
  { url: '../../../../../assets/img/GroupBleft.png', width: '48', height: '24' },
  { url: '../../../../../assets/img/GroupBright.png', width: '48', height: '24' }
]
