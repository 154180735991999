import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AccountService } from 'src/app/shared/services/account.service'
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private _http: HttpClient, private accountService: AccountService) {}

  async create(event: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.post(environment.apiUrl + '/admin/events', event, { headers })
  }

  async getAll(): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/events', { headers })
  }
  async getAll2(): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/events/eventsolo', { headers })
  }

  async getOne(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/events/' + id, { headers })
  }

  async getOne2(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/events/findoneWithOutEnclousure/' + id, {
      headers
    })
  }

  async getEventsByValidator(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/events/findEventsByValidatorId/' + id, {
      headers
    })
  }

  async getEventsByTaquillero(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/events/findEventsByTaquilleroId/' + id, {
      headers
    })
  }

  async updateOne(id: string, event: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.put(environment.apiUrl + '/admin/events/' + id, event, { headers })
  }

  async delete(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.delete(environment.apiUrl + '/admin/events/' + id, { headers })
  }

  async getExcell(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/excell/' + id, {
      headers,
      observe: 'response',
      responseType: 'arraybuffer'
    })
  }
}
