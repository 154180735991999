/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { CategoriesService } from 'src/app/shared/services/categories.service'
import { FilesService } from 'src/app/shared/services/files.service'
import { PromotionalCodesService } from 'src/app/shared/services/promotional-codes.service'
import { ReducedRatesService } from 'src/app/shared/services/reduced-rates.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-discounts-rates',
  templateUrl: './discounts-rates.component.html',
  styleUrls: ['./discounts-rates.component.scss']
})
export class DiscountsRatesComponent implements OnInit {
  displayedColumnsRates: string[] = ['days', 'groups', 'percentage', 'discountValue', 'actions']
  displayedColumnsCodes: string[] = [
    'code',
    'days',
    'percentage',
    'discountValue',
    'quantity',
    'actions'
  ]
  dataSourceCodes: any
  dataSourceRates: any
  @ViewChild(MatPaginator)
  paginatorCodes!: MatPaginator
  @ViewChild(MatPaginator)
  paginatorRates!: MatPaginator
  public createDiscountForm!: FormGroup
  public typeForm!: FormGroup
  public discountTypeForm!: FormGroup
  public createRateForm!: FormGroup
  discountType: any
  discountAppType: any
  isRate = true
  loading = false
  submitted = false
  loadedEvent: any
  selectedFile: any
  selectedFileName: string = ''
  preview: any
  mode = 'create'
  isQuantity = false
  constructor(
    private categoriesService: CategoriesService,
    private router: Router,
    private fb: FormBuilder,
    private filesService: FilesService,
    private promotionalCodesService: PromotionalCodesService,
    private reducedRatesService: ReducedRatesService
  ) {}

  ngOnInit(): void {
    this.typeForm = this.fb.group({
      type: ['Empresa']
    })
    this.discountTypeForm = this.fb.group({
      type: ['Porcentaje']
    })
    this.createRateForm = this.fb.group({
      discountPercentage: [0],
      discountQuantity: [0],
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      sunday: [false],
      group1: [false],
      group2: [false],
      group3: [false],
      group4: [false],
      group5: [false],
      group6: [false]
    })
    this.createDiscountForm = this.fb.group({
      name: ['', Validators.required],
      discountPercentage: [0],
      discountQuantity: [0],
      numberOfCodes: [0],
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      sunday: [false]
    })
    this.loadedEvent = history.state.event
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      // this.setLoadedData()
    }
    this.getCodes()
    this.getRates()
  }

  async getCodes() {
    ;(await this.promotionalCodesService.getAllbyEventId(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        this.dataSourceCodes = new MatTableDataSource(response.promotionalCodes)
        this.dataSourceCodes.paginator = this.paginatorCodes
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  async getRates() {
    ;(await this.reducedRatesService.getAllbyEventId(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        this.dataSourceRates = new MatTableDataSource(response.reducedRates)
        this.dataSourceRates.paginator = this.paginatorRates
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  radioChange(event: any) {
    this.discountType = event.value
    this.isRate = !this.isRate
  }

  radioDiscountChange(event: any) {
    this.discountAppType = event.value
    this.isQuantity = !this.isQuantity
  }

  calculateDaysArray(): string {
    this.createRateForm.get('name')?.value
    return 'patata'
  }

  public async onRateSubmit(): Promise<void> {
    if (this.createRateForm.valid) {
      if (this.mode === 'edit') {
        //this.edit()
      } else {
        let days = []
        const monday = this.createRateForm.get('monday')?.value
        if (monday) {
          days.push('Lunes')
        }
        const tuesday = this.createRateForm.get('tuesday')?.value
        if (tuesday) {
          days.push('Martes')
        }
        const wednesday = this.createRateForm.get('wednesday')?.value
        if (wednesday) {
          days.push('Miércoles')
        }
        const thursday = this.createRateForm.get('thursday')?.value
        if (thursday) {
          days.push('Jueves')
        }
        const friday = this.createRateForm.get('friday')?.value
        if (friday) {
          days.push('Viernes')
        }
        const saturday = this.createRateForm.get('saturday')?.value
        if (saturday) {
          days.push('Sábado')
        }
        const sunday = this.createRateForm.get('sunday')?.value
        if (sunday) {
          days.push('Domingo')
        }
        let groups = []
        const group1 = this.createRateForm.get('group1')?.value
        if (group1) {
          groups.push('Mayores de 65 años y pensionistas')
        }
        const group2 = this.createRateForm.get('group2')?.value
        if (group2) {
          groups.push('Estudiantes')
        }
        const group3 = this.createRateForm.get('group3')?.value
        if (group3) {
          groups.push('Personas con discapacidad desde 33')
        }
        const group4 = this.createRateForm.get('group4')?.value
        if (group4) {
          groups.push('Desempleados')
        }
        const group5 = this.createRateForm.get('group5')?.value
        if (group5) {
          groups.push('Carne joven')
        }
        const group6 = this.createRateForm.get('group6')?.value
        if (group6) {
          groups.push('Menores de ')
        }
        const newRate = {
          days: days,
          groups: groups,
          discountPercentage: this.createRateForm.get('discountPercentage')?.value,
          discountQuantity: this.createRateForm.get('discountQuantity')?.value,
          // numberOfCodes: 0,
          event: this.loadedEvent.id
        }
        this.submitted = true
        ;(await this.reducedRatesService.create(newRate)).subscribe({
          next: async response => {
            Swal.fire('Tarifa creada correctamente', '', 'success')
            this.getRates()
            this.createRateForm.reset({
              discountPercentage: 0,
              discountQuantity: 0,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: false,
              group1: false,
              group2: false,
              group3: false,
              group4: false,
              group5: false,
              group6: false
            })
          },
          complete: () => {},
          error: error => {
            console.log(error)
          }
        })
      }
    }
  }

  public async onDiscountSubmit(): Promise<void> {
    if (this.createDiscountForm.valid) {
      if (this.mode === 'edit') {
        //this.edit()
      } else {
        let days = []
        const monday = this.createDiscountForm.get('monday')?.value
        if (monday) {
          days.push('Lunes')
        }
        const tuesday = this.createDiscountForm.get('tuesday')?.value
        if (tuesday) {
          days.push('Martes')
        }
        const wednesday = this.createDiscountForm.get('wednesday')?.value
        if (wednesday) {
          days.push('Miércoles')
        }
        const thursday = this.createDiscountForm.get('thursday')?.value
        if (thursday) {
          days.push('Jueves')
        }
        const friday = this.createDiscountForm.get('friday')?.value
        if (friday) {
          days.push('Viernes')
        }
        const saturday = this.createDiscountForm.get('saturday')?.value
        if (saturday) {
          days.push('Sábado')
        }
        const sunday = this.createDiscountForm.get('sunday')?.value
        if (sunday) {
          days.push('Domingo')
        }
        const newCode = {
          days: days,
          groups: [],
          discountPercentage: this.createDiscountForm.get('discountPercentage')?.value,
          numberOfCodes: this.createDiscountForm.get('numberOfCodes')?.value,
          discountQuantity: this.createDiscountForm.get('discountQuantity')?.value,
          code: this.createDiscountForm.get('name')?.value,
          event: this.loadedEvent.id
        }
        this.submitted = true
        ;(await this.promotionalCodesService.create(newCode)).subscribe({
          next: async response => {
            Swal.fire('Descuento creado correctamente', '', 'success')
            this.getCodes()
            this.createDiscountForm.reset({
              name: '',
              discountPercentage: 0,
              discountQuantity: 0,
              numberOfCodes: 0,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: false
            })
          },
          complete: () => {},
          error: error => {
            console.log(error)
          }
        })
      }
    }
  }

  public gotoList() {
    this.router.navigate(['/home/event-details'], {
      state: { mode: 'edit', event: this.loadedEvent }
    })
  }

  deleteCode(id: string) {
    Swal.fire({
      title: '¿Quieres borrar el código?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.promotionalCodesService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Evento borrado correctamente', '', 'success')
            this.getCodes()
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se ha podido borrar el evento', '', 'error')
          }
        })
      }
    })
  }

  deleteRate(id: string) {
    Swal.fire({
      title: '¿Quieres borrar la tarifa?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.reducedRatesService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Tarifa borrada correctamente', '', 'success')
            this.getRates()
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se ha podido borrar el evento', '', 'error')
          }
        })
      }
    })
  }
}
