import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { Observable, firstValueFrom } from 'rxjs'
import { EventsService } from 'src/app/shared/services/events.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'enclosure',
    'organizator',
    'category',
    'initDate',
    'endDate',
    'actions'
  ]
  dataSource: any
  @ViewChild(MatPaginator)
  paginator!: MatPaginator
  user: any
  roles: any
  constructor(private eventsService: EventsService, private router: Router) {}

  ngOnInit(): void {
    this.getRoles()
    this.getData()
  }

  async getData() {
    ;(await this.eventsService.getAll2()).subscribe({
      next: async (response: any) => {
        this.dataSource = new MatTableDataSource(response.event)
        this.dataSource.paginator = this.paginator
        console.log(response)
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  async delete(id: string) {
    const eventDataObservable: Observable<any> = await this.eventsService.getOne(id)
    const eventdata: any = await firstValueFrom(eventDataObservable)
    console.log(eventdata)
    console.log(eventdata.event.sessions.length)
    console.log(eventdata.event.PerpetualSession.length)
    if (eventdata.event.sessions.length > 0 || eventdata.event.PerpetualSession.length > 0) {
      Swal.fire({
        title: 'No se puede borrar el evento',
        text: 'El evento tiene sesiones creadas y no se puede borrar.',
        icon: 'warning',
        confirmButtonText: 'Aceptar'
      })
      return
    }
    Swal.fire({
      title: '¿Quieres borrar el evento?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.eventsService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Evento borrado correctamente', '', 'success')
            this.getData()
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se ha podido borrar el evento', '', 'error')
          }
        })
      }
    })
  }

  gotoCreate() {
    this.router.navigate(['/home/events'])
  }

  gotoEdit(event: any) {
    this.router.navigate(['/home/event-details'], {
      state: { mode: 'edit', event: event }
    })
  }

  downloadExcell(element: any) {
    Swal.fire({
      title: '¿Quieres descargar los tickets del evento?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Descargar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.eventsService.getExcell(element.id)).subscribe({
          next: async (response: any) => {
            //Swal.fire('Tickets descargados', '', 'success')
            let date = new Date()
            let filename =
              element.name + date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear()
            const blob = new Blob([response.body], { type: response.headers.get('content-type') })
            const file = new File([blob], filename, { type: response.headers.get('content-type') })
            const url = window.URL.createObjectURL(file)
            // create <a> tag dinamically
            let fileLink = document.createElement('a')
            fileLink.href = url

            // it forces the name of the downloaded file
            fileLink.download = filename

            // triggers the click event
            fileLink.click()
            //window.open(url)
            // saveAs(file)
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se han podido descargar los tickets', '', 'error')
          }
        })
      }
    })
  }

  gotoWatch(event: any) {
    this.router.navigate(['/home/event-details'], {
      state: { mode: 'watch', event: event }
    })
  }

  getRoles() {
    const user = JSON.parse(localStorage.getItem('fndsadmin::current_user') || '{}')
    this.user = user.user
    this.roles = this.user.role
  }
}
