/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import * as d3 from 'd3'
import { access, toilets } from 'src/app/shared/constants/signs'
import { SeatMapsService } from 'src/app/shared/services/seat-maps.service'
import Swal from 'sweetalert2'
@Component({
  selector: 'app-seat-maps-toilets',
  templateUrl: './seat-maps-toilets.component.html',
  styleUrls: ['./seat-maps-toilets.component.scss']
})
export class SeatMapsToiletsComponent implements OnInit {
  public createToiletForm!: FormGroup
  loadedSeatMap: any
  mode = 'create'
  submitted = false
  loading = false
  polygon: any
  sectionColor = 'grey'
  toiletIndex = 0
  index = 0
  trapezoidType = 'H'

  constructor(
    private router: Router,
    private seatMapsService: SeatMapsService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.createToiletForm = this.fb.group({
      color: ['', Validators.required]
    })
    this.loadedSeatMap = history.state.seatMap
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    if (history.state.index) {
      this.index = history.state.index
    }
    history.pushState(null, '')

    if (this.mode === 'edit' || this.mode === 'watch') {
      this.setLoadedData()
    }
    this.renderCurrentSections()
  }

  setLoadedData() {
    //this.createToiletForm.controls['color'].setValue(this.loadedSeatMap.color)
  }

  private async renderCurrentSections() {
    if (this.loadedSeatMap.stage.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.stage.length; i++) {
        if (this.loadedSeatMap.stage[i].polygon[0] === 'C') {
          this.createStoredVerticalCurvedTrapezoid(
            this.loadedSeatMap.stage[i].polygon,
            this.loadedSeatMap.stage[i]?.name,
            this.loadedSeatMap.stage[i].color
          )
        } else if (this.loadedSeatMap.stage[i].polygon[0] === 'H') {
          this.createStoredCurvedTrapezoid(
            this.loadedSeatMap.stage[i].polygon,
            this.loadedSeatMap.stage[i]?.name,
            this.loadedSeatMap.stage[i].color
          )
        } else if (this.loadedSeatMap.stage[i].polygon[0] === 'R') {
          this.createCircle(
            this.loadedSeatMap.stage[i],
            this.loadedSeatMap.stage[i]?.name,
            i,
            'stage'
          )
        } else {
          this.createPolygon(
            this.loadedSeatMap.stage[i],
            this.loadedSeatMap.stage[i]?.name,
            i,
            'stage'
          )
        }
      }
    }
    if (this.loadedSeatMap.toilets.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.toilets.length; i++) {
        this.createToilet(this.loadedSeatMap.toilets[i], 'Aseo' + i)
      }
    }
    if (this.loadedSeatMap.access.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.access.length; i++) {
        this.createAccess(this.loadedSeatMap.access[i], 'Acceso' + i)
      }
    }
    if (this.loadedSeatMap.sections.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.sections.length; i++) {
        if (this.loadedSeatMap.sections[i].polygon[0] === 'C') {
          this.createStoredVerticalCurvedTrapezoid(
            this.loadedSeatMap.sections[i].polygon,
            this.loadedSeatMap.sections[i].name,
            this.loadedSeatMap.sections[i].color
          )
        } else if (this.loadedSeatMap.sections[i].polygon[0] === 'H') {
          this.createStoredCurvedTrapezoid(
            this.loadedSeatMap.sections[i].polygon,
            this.loadedSeatMap.sections[i].name,
            this.loadedSeatMap.sections[i].color
          )
        } else if (this.loadedSeatMap.sections[i].polygon[0] === 'R') {
          this.createCircle(
            this.loadedSeatMap.sections[i],
            this.loadedSeatMap.sections[i].name,
            i,
            'sections'
          )
        } else {
          this.createPolygon(
            this.loadedSeatMap.sections[i],
            this.loadedSeatMap.sections[i].name,
            i,
            'sections'
          )
        }
      }
    }
  }

  private createPolygon(sector: any, name: string, index: number, type: string) {
    if (this.mode === 'edit' && index === this.index && type === 'sections') {
      name = 'patata'
    }
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()
    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', sector.polygon)
      .attr('fill', sector.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  gotoList() {
    this.router.navigate(['/home/seat-map-general'], {
      state: { mode: 'edit', seatMap: this.loadedSeatMap }
    })
  }

  public async onViewChange() {
    // this.selectedView = $event
    await new Promise(f => setTimeout(f, 500))
    this.renderCurrentSections()
  }

  gotoEdit() {
    this.router.navigate(['/home/seat-map-general'], {
      state: { mode: 'edit', seatMap: this.loadedSeatMap }
    })
  }

  public createToilet(toilet: any, name: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    let newStr = toilet.polygon.slice(1)
    let i = parseInt(toilet.polygon[0])
    let svg = d3.select(document.querySelector('svg'))
    const idImage = 'url(#' + name + 'locked2)'
    let myimage = svg
      .append('defs')
      .append('pattern')
      .attr('id', name + 'locked2')
      //.attr('patternUnits', 'userSpaceOnUse')
      .attr('width', toilets[i].width)
      .attr('height', toilets[i].height)
      .append('image')
      .attr('href', toilets[i].url)
      .attr('width', toilets[i].width)
      .attr('height', toilets[i].height)
      .attr('id', name + 'locked2')
    /*.attr('transform', function () {
        return 'translate(5,5)'
      })*/

    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', newStr)
      //.attr('fill', this.createSectionForm.get('color')?.value)
      .attr('fill', idImage)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    //this.polygon = polygon
    // this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  public createStoredVerticalCurvedTrapezoid(path: string, name: string, color: string) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width / 2
    }
    if (Number.isNaN(k2)) {
      k2 = width / 2
    }
    const k = (x0 + width + k1) / 2
    const kb = (x0 + width + k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()
    this.trapezoidType = 'C'

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          k +
          ',' +
          (y0 + cp) +
          ' ' +
          (x0 + width + hlo) +
          ',' +
          (y0 + vro) +
          'L ' +
          (x0 + width - ro) +
          ',' +
          (y0 + height + hro) +
          ' Q ' +
          kb +
          ',' +
          (y0 + height + cp2) +
          ' ' +
          (x0 + lo) +
          ',' +
          (y0 + height + vlo) +
          ' Z'
        )
      })
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public createStoredCurvedTrapezoid(path: string, name: string, color: string) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp)) {
      cp = 100
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width
    }
    if (Number.isNaN(k2)) {
      k2 = width
    }
    const k = (x0 + width - k1) / 2
    const kb = (x0 + width - k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()
    this.trapezoidType = 'H'

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          (x0 + cp) +
          ',' +
          k +
          ' ' +
          (x0 + hro) +
          ',' +
          (y0 + height + hlo) +
          'L ' +
          (x0 - width - ro) +
          ',' +
          (y0 + height + vro) +
          ' Q ' +
          (x0 + cp2 - width) +
          ',' +
          kb +
          ' ' +
          (x0 - width - lo) +
          ',' +
          (y0 + lo + vlo) +
          ' Z'
        )
      })
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  private createCircle(sector: any, name: string, index: number, type: string) {
    if (this.mode === 'edit' && index === this.index && type === 'sections') {
      name = 'patata'
    }
    let newStr = sector.polygon.slice(1)
    let polygon = newStr.split(',')
    //d3.select('#patata').remove()
    d3.selectAll('circle').remove()
    d3.select(document.querySelector('svg'))
      .attr('fill', sector.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
      .append('circle')
      .style('stroke', 'gray')
      .attr('r', polygon[0])
      .attr('cx', polygon[1])
      .attr('cy', polygon[2])
  }

  public createPredefinedToilet(polygon: any, name: string, url: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    d3.select('#patata').remove()
    d3.select('#locked2').remove()
    d3.select('#bg').remove()
    let svg = d3.select(document.querySelector('svg'))

    let myimage = svg
      .append('defs')
      .append('pattern')
      .attr('id', 'locked2')
      //.attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 24)
      .attr('height', 24)
      .append('image')
      .attr('href', url)
      .attr('width', 24)
      .attr('height', 24)
      .attr('id', 'bg')

    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', polygon)
      //.attr('fill', this.createSectionForm.get('color')?.value)
      .attr('fill', 'url(#locked2)')
      .attr('id', 'patata')
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    this.polygon = polygon
    // this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  public createAccess(acc: any, name: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    let newStr = acc.polygon.slice(2)
    let i = parseInt(acc.polygon[0] + acc.polygon[1])
    let svg = d3.select(document.querySelector('svg'))
    const idImage = 'url(#' + name + 'locked2)'
    let myimage = svg
      .append('defs')
      .append('pattern')
      .attr('id', name + 'locked2')
      //.attr('patternUnits', 'userSpaceOnUse')
      .attr('width', access[i].width)
      .attr('height', access[i].height)
      .append('image')
      .attr('href', access[i].url)
      .attr('width', access[i].width)
      .attr('height', access[i].height)
      .attr('id', name + 'locked2')
    //.attr('transform', (200, 200))

    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', newStr)
      //.attr('fill', this.createSectionForm.get('color')?.value)
      .attr('fill', idImage)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    //this.polygon = polygon
    // this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  update($event: any) {
    this.polygon = $event
  }

  imageSelected(url: any, index: number) {
    if (this.mode === 'create') {
      this.toiletIndex = index
      this.createPredefinedToilet('0,0 0,24 24,24 24,0', 'name', url)
    }
  }

  public async onSubmit(): Promise<void> {
    //if (this.createToiletForm.valid) {
    const polygonSelection = d3.select('#patata')
    const svgPoints = polygonSelection.property('points')
    let coords = ''
    for (let i = 0; i < svgPoints.length; i++) {
      if (i === 0) {
        coords = this.toiletIndex.toString() + svgPoints[i].x + ',' + svgPoints[i].y
      } else {
        coords = coords + ' ' + svgPoints[i].x + ',' + svgPoints[i].y
      }
    }
    const toilets = {
      color: '',
      orientation: 'string',
      polygon: coords,
      position: {
        x: 0,
        y: 0
      }
    }
    if (this.mode === 'create') {
      this.loadedSeatMap.toilets.push(toilets)
    } else if (this.mode === 'edit') {
      this.loadedSeatMap.toilets[this.index] = toilets
    }
    const seatMap = {
      name: this.loadedSeatMap.name,
      stage: this.loadedSeatMap.stage,
      sections: this.loadedSeatMap.sections,
      access: this.loadedSeatMap.access,
      toilets: this.loadedSeatMap.toilets
    }
    this.submitted = true
    ;(await this.seatMapsService.updateOne(this.loadedSeatMap.id, seatMap)).subscribe({
      next: async response => {
        Swal.fire('Aseo creado correctamente', '', 'success')
        this.router.navigate(['/home/seat-map-general'], {
          state: { mode: 'edit', seatMap: response.seatMap }
        })
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
    //}
  }

  gotoEventSessions() {
    this.router.navigate(['/home/seat-map-list'])
  }
}
