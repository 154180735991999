<div class="banners">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear nuevo Banner</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar Banner</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar Banner</mat-card-title>
    <mat-card-content>
      <form [formGroup]="createBannerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <mat-form-field class="bannerName" appearance="standard">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" />
            <div *ngIf="submitted && ['f.name.errors']" class="invalid-feedback">
              <div *ngIf="['f.name.errors.required']">name is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          <div class="lined">
            <mat-checkbox formControlName="active"
              ><div class="checkbox-label">Activar este banner</div></mat-checkbox
            >
            <p></p>
            <p></p>
            <mat-form-field appearance="fill">
              <div class="checkbox-label">Rango de fechas</div>
              <p></p>
              <mat-date-range-input [formGroup]="createBannerForm" [rangePicker]="picker">
                <input matStartDate formControlName="since" placeholder="Desde" />
                <input matEndDate formControlName="until" placeholder="Hasta" />
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <div
              *ngIf="submitted && (['f.since.errors'] || ['f.until.errors'])"
              class="invalid-feedback">
              <div *ngIf="['f.since.errors.required'] || ['f.until.errors.required']">
                Dates is required
              </div>
            </div>
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="lined">
          <mat-card class="mat-card2">
            <div class="lined2">
              <mat-icon>desktop_windows</mat-icon>
              <div class="spanned">Desktop</div>
            </div>
            <mat-card class="mat-card5"
              ><div class="form-group">
                <mat-form-field class="halfWidth" (click)="fileInputDesktop.click()">
                  <mat-label *ngIf="selectedFileDesktop; else newFileDesktop">{{
                    selectedFileDesktop.originalName
                  }}</mat-label>
                  <ng-template #newFileDesktop>
                    <mat-label>Elija imagen</mat-label>
                  </ng-template>
                  <input matInput disabled />
                  <button type="button" mat-icon-button matSuffix>
                    <mat-icon>attach_file</mat-icon>
                  </button>
                  <input
                    hidden
                    (change)="selectFileDesktop($event)"
                    #fileInputDesktop
                    type="file"
                    id="file"
                    accept="image/png, image/jpeg" />
                </mat-form-field>
                <!-- <div>
                  <img [src]="selectedFileNameDesktop" class="preview" />
                </div> -->
              </div>
            </mat-card>
            <div class="card4">
              <div class="checkbox-label">Formatos aceptados: .jpg, .jpeg, .gif, .png.</div>
              <div class="checkbox-label">Tamaño recomendado: 1200px ancho x 800px alto.</div>
              <!-- <div class="checkbox-label">Tamaño Máximo: 150 Kb.</div> -->
            </div>
          </mat-card>
          <mat-card class="mat-card2">
            <div class="lined2">
              <mat-icon>stay_primary_portrait</mat-icon>
              <div class="spanned">Mobile</div>
            </div>
            <mat-card class="mat-card5">
              <div class="form-group">
                <mat-form-field class="halfWidth" (click)="fileInputMobile.click()">
                  <mat-label *ngIf="selectedFileMobile; else newFileMobile">{{
                    selectedFileMobile.originalName
                  }}</mat-label>
                  <ng-template #newFileMobile>
                    <mat-label>Elija imagen</mat-label>
                  </ng-template>
                  <input matInput disabled />
                  <button type="button" mat-icon-button matSuffix>
                    <mat-icon>attach_file</mat-icon>
                  </button>
                  <input
                    hidden
                    (change)="selectFileMobile($event)"
                    #fileInputMobile
                    type="file"
                    id="file"
                    accept="image/png, image/jpeg" />
                </mat-form-field>
                <!-- <div>
                  <img [src]="selectedFileNameMobile" class="preview" />
                </div> -->
              </div>
            </mat-card>
            <div class="card4">
              <div class="checkbox-label">
                ** Si dispone de una versión del banner adaptado a dispositivos móviles, súbalo
                aquí. De no ser así, el banner para desktop será usado.
              </div>
            </div>
            <div class="card4">
              <div class="checkbox-label">Formatos aceptados: .jpg, .jpeg, .gif, .png.</div>
              <div class="checkbox-label">Tamaño recomendado: 1080px ancho x 1080px alto.</div>
              <!-- <div class="checkbox-label">Tamaño Máximo: 150 Kb.</div> -->
            </div>
          </mat-card>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="bannerTargetUrl">
            <mat-label>Target Link Url</mat-label>
            <input matInput formControlName="url" />
            <div *ngIf="submitted && ['f.targetUrl.errors']" class="invalid-feedback">
              <div *ngIf="['f.targetUrl.errors.required']">Target Url is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-checkbox (change)="gotoWeb()">
            <div class="checkbox-label">Abrir enlace en ventana nueva</div></mat-checkbox
          >
          <p></p>
        </div>

        <mat-divider></mat-divider>
        <div class="subtitle">
          <h4>Localizar en páginas web</h4>
        </div>
        <div class="lined">
          <mat-card class="mat-card3">
            <mat-radio-button formControlName="all" (change)="allPages()" ngDefaultControl
              >Todas las páginas, todas las zonas</mat-radio-button
            >
            <div [ngClass]="{ disableDiv: allSelected }">
              <div class="subtitle">Home</div>
              <div class="card3">
                <div class="lined">
                  <div class="columned">
                    <mat-checkbox formControlName="homeTop">
                      <div class="checkbox-label">Home - zona superior</div>
                    </mat-checkbox>
                    <img
                      class="layout"
                      src="/assets/img/layout_inferior.jpg"
                      alt="layout superior" />
                  </div>
                  <div class="columned">
                    <mat-checkbox formControlName="homeDown">
                      <div class="checkbox-label">Home - zona inferior</div>
                    </mat-checkbox>
                    <img
                      class="layout"
                      src="/assets/img/layout_superior.jpg"
                      alt="layout inferior" />
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
          <mat-card class="mat-card3">
            <mat-radio-button formControlName="some" (change)="noPages()" ngDefaultControl
              >Seleccionar páginas, seleccionar zonas</mat-radio-button
            >
            <div [ngClass]="{ disableDiv: allSelected }">
              <div class="subtitle">Detalle del evento</div>
              <div class="card3">
                <div class="lined">
                  <div class="columned">
                    <mat-checkbox formControlName="eventTop">
                      <div class="checkbox-label">Detalle - zona superior</div>
                    </mat-checkbox>
                    <img
                      class="layout"
                      src="/assets/img/layout_inferior.jpg"
                      alt="layout superior" />
                  </div>
                  <div class="columned">
                    <mat-checkbox formControlName="envenDown">
                      <div class="checkbox-label">Detalle - zona inferior</div>
                    </mat-checkbox>
                    <img
                      class="layout"
                      src="/assets/img/layout_superior.jpg"
                      alt="layout inferior" />
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <p></p>
        <mat-divider></mat-divider>
        <div class="subtitle">
          <h4>Previsualización</h4>
        </div>
        <mat-tab-group>
          <mat-tab label="Desktop">
            <mat-card class="mat-card4">
              <img
                *ngIf="!imgDesktopLoaded"
                class="empty"
                src="/assets/img/fondo_vacio.png"
                alt="Desktop" />
              <div *ngIf="imgDesktopLoaded">
                <img [src]="selectedFileNameDesktop" class="empty" />
              </div>
            </mat-card>
          </mat-tab>
          <mat-tab label="Mobile"
            ><mat-card class="mat-card4">
              <img
                *ngIf="!imgMobileLoaded"
                class="empty"
                src="/assets/img/fondo_vacio.png"
                alt="Mobile" />
              <div *ngIf="imgMobileLoaded">
                <img [src]="selectedFileNameMobile" class="empty" />
              </div>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
            Banner
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Crear Banner
          </button>
          <button mat-raised-button (click)="gotoList()">Cancelar</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
