/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SeatMapsService } from 'src/app/shared/services/seat-maps.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-seat-maps-general',
  templateUrl: './seat-maps-general.component.html',
  styleUrls: ['./seat-maps-general.component.scss']
})
export class SeatMapsGeneralComponent implements OnInit {
  loadedseatMap: any
  mode = 'create'
  constructor(private router: Router, private seatMapsService: SeatMapsService) {}

  ngOnInit(): void {
    this.loadedseatMap = history.state.seatMap
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
  }

  gotoList() {
    this.router.navigate(['/home/seat-map-list'])
  }

  gotoEdit() {
    if (this.mode === 'edit') {
      this.router.navigate(['/home/seat-map-new'], {
        state: { mode: 'edit', seatMap: this.loadedseatMap }
      })
    } else if (this.mode === 'watch') {
      this.router.navigate(['/home/seat-map-new'], {
        state: { mode: 'watch', seatMap: this.loadedseatMap }
      })
    }
  }

  createStage(mode: string) {
    this.router.navigate(['/home/seat-map-stage'], {
      state: { mode: mode, seatMap: this.loadedseatMap }
    })
  }

  createSection(mode: string) {
    this.router.navigate(['/home/seat-map-sections'], {
      state: { mode: mode, seatMap: this.loadedseatMap }
    })
  }

  createAccess(mode: string) {
    this.router.navigate(['/home/seat-map-access'], {
      state: { mode: mode, seatMap: this.loadedseatMap }
    })
  }

  createToilet(mode: string) {
    this.router.navigate(['/home/seat-map-toilets'], {
      state: { mode: mode, seatMap: this.loadedseatMap }
    })
  }

  editSeatMap(index: number) {
    this.router.navigate(['/home/seats-editor'], {
      state: { mode: 'edit', seatMap: this.loadedseatMap, index: index }
    })
  }

  editSection(index: number) {
    this.router.navigate(['/home/seat-map-sections'], {
      state: { mode: 'edit', seatMap: this.loadedseatMap, index: index }
    })
  }

  editToilet(index: number) {
    this.router.navigate(['/home/seat-map-toilets'], {
      state: { mode: 'edit', seatMap: this.loadedseatMap, index: index }
    })
  }

  editAccess(index: number) {
    this.router.navigate(['/home/seat-map-access'], {
      state: { mode: 'edit', seatMap: this.loadedseatMap, index: index }
    })
  }

  editStage(index: number) {
    this.router.navigate(['/home/seat-map-stage'], {
      state: { mode: 'edit', seatMap: this.loadedseatMap, index: index }
    })
  }

  async delete(type: string, i: number) {
    Swal.fire({
      title: '¿Quieres borrar el elemento?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        if (type === 'toilet') {
          this.loadedseatMap.toilets.splice(i, 1)
        }
        if (type === 'stage') {
          this.loadedseatMap.stage.splice(i, 1)
        }
        if (type === 'sections') {
          this.loadedseatMap.sections.splice(i, 1)
        }
        if (type === 'access') {
          this.loadedseatMap.access.splice(i, 1)
        }
        const seatMap = {
          name: this.loadedseatMap.name,
          stage: this.loadedseatMap.stage,
          sections: this.loadedseatMap.sections,
          access: this.loadedseatMap.access,
          toilets: this.loadedseatMap.toilets
        }
        ;(await this.seatMapsService.updateOne(this.loadedseatMap.id, seatMap)).subscribe({
          next: async response => {
            Swal.fire('Elemento borrado correctamente', '', 'success')
            this.loadedseatMap = response.seatMap
          },
          complete: () => {},
          error: error => {
            console.log(error)
          }
        })
      }
    })
  }
}
