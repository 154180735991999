<div class="banners-list">
  <mat-card>
    <mat-card-title>Todos los Banners</mat-card-title>
    <p class="margin30"></p>
    <mat-card-subtitle>
      Banners
      <mat-divider></mat-divider>
    </mat-card-subtitle>

    <mat-card-subtitle
      ><button mat-raised-button color="primary" (click)="gotoCreate()">
        Crear nuevo Banner
      </button></mat-card-subtitle
    >
    <mat-card-content>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef>Imagen</th>
          <td mat-cell *matCellDef="let element">
            <img [src]="element.imgDesktop.url" alt="image Banner" class="image-list" />
          </td>
        </ng-container>
        <ng-container matColumnDef="since">
          <th mat-header-cell *matHeaderCellDef>Desde</th>
          <td mat-cell *matCellDef="let element">{{ element.since }}</td>
        </ng-container>
        <ng-container matColumnDef="until">
          <th mat-header-cell *matHeaderCellDef>Hasta</th>
          <td mat-cell *matCellDef="let element">{{ element.until }}</td>
        </ng-container>
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef>Activo</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [checked]="element.active" [disabled]="true"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="homeTop">
          <th mat-header-cell *matHeaderCellDef>Home Superior</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [checked]="element.homeTop" [disabled]="true"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="homeDown">
          <th mat-header-cell *matHeaderCellDef>Home Inferior</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [checked]="element.homeDown" [disabled]="true"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="eventTop">
          <th mat-header-cell *matHeaderCellDef>Eventos Superior</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [checked]="element.eventTop" [disabled]="true"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="envenDown">
          <th mat-header-cell *matHeaderCellDef>Eventos Inferior</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [checked]="element.envenDown" [disabled]="true"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef>Url</th>
          <td mat-cell *matCellDef="let element">{{ element.url }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row; let element">
            <button mat-icon-button aria-label="Visibility" (click)="gotoWatch(element)">
              <mat-icon>visibility</mat-icon>
            </button>
            <button mat-icon-button aria-label="Edit" (click)="gotoEdit(element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete" (click)="delete(element.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
