<div class="sections">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear sección</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar sección</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar sección</mat-card-title>
    <mat-card-content>
      <!--<h4>Activar creación de polígono</h4>
      <mat-button-toggle-group [(ngModel)]="svgSelected" aria-label="Font Style">
        <mat-button-toggle value="svg" aria-label="svg" (change)="onValChange()">
          Activado
        </mat-button-toggle>
        <mat-button-toggle value="svgclick" aria-label="svgclick" (change)="onValChange()">
          Desactivado
        </mat-button-toggle>
      </mat-button-toggle-group>-->
      <form [formGroup]="createSectionForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          Nombre:
          <mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" />
            <div *ngIf="submitted && ['f.rows.errors']" class="invalid-feedback">
              <div *ngIf="['f.name.errors.required']">name is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          Color:
          <!--<mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Número de filas</mat-label>
            <input type="number" matInput formControlName="rows" />
            <div *ngIf="submitted && ['f.rows.errors']" class="invalid-feedback">
              <div *ngIf="['f.rows.errors.required']">rows is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Número de columnas</mat-label>
            <input type="number" matInput formControlName="columns" />
            <div *ngIf="submitted && ['f.columns.errors']" class="invalid-feedback">
              <div *ngIf="['f.columns.errors.required']">columns is required</div>
            </div>
          </mat-form-field>-->
          <mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Color</mat-label>
            <input type="color" matInput formControlName="color" />
            <div *ngIf="submitted && ['f.color.errors']" class="invalid-feedback">
              <div *ngIf="['f.color.errors.required']">color is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="selectdiv">
          Seleccionar forma:
          <mat-form-field appearance="fill" class="stageWidth">
            <mat-label>Opción</mat-label>
            <mat-select [(value)]="selectedView" (selectionChange)="onViewChange()">
              <mat-option [value]="option" [compareWith]="compareFn" *ngFor="let option of views">{{
                option.viewValue
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--<div class="form-group">
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición X</mat-label>
            <input type="number" matInput formControlName="x" />
            <div *ngIf="submitted && ['f.number.errors']" class="invalid-feedback">
              <div *ngIf="['f.x.errors.required']">x is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición Y</mat-label>
            <input type="number" matInput formControlName="y" />
            <div *ngIf="submitted && ['f.y.errors']" class="invalid-feedback">
              <div *ngIf="['f.y.errors.required']">y is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición X2</mat-label>
            <input type="number" matInput formControlName="x2" />
            <div *ngIf="submitted && ['f.x2.errors']" class="invalid-feedback">
              <div *ngIf="['f.x2.errors.required']">x is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición Y2</mat-label>
            <input type="number" matInput formControlName="y2" />
            <div *ngIf="submitted && ['f.y2.errors']" class="invalid-feedback">
              <div *ngIf="['f.y2.errors.required']">y is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición X3</mat-label>
            <input type="number" matInput formControlName="x3" />
            <div *ngIf="submitted && ['f.x3.errors']" class="invalid-feedback">
              <div *ngIf="['f.x3fa-flip-vertical.errors.required']">x is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición Y3</mat-label>
            <input type="number" matInput formControlName="y3" />
            <div *ngIf="submitted && ['f.y3.errors']" class="invalid-feedback">
              <div *ngIf="['f.y3.errors.required']">y is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición X4</mat-label>
            <input type="number" matInput formControlName="x4" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="positionWidth">
            <mat-label>Posición Y4</mat-label>
            <input type="number" matInput formControlName="y4" />
          </mat-form-field>'200,10 250,190 160,210'
        </div>-->

        <div *ngIf="selectedView?.viewValue === 'Rectángulo'">
          <!--<button
            mat-stroked-button
            type="button"
            (click)="createPredefinedPolygon('0,0 0,50 50,50 50,0', 'name')">
            Crear rectángulo
          </button>-->
          Rectángulo:
          <mat-icon (click)="modifyRect('v-')" style="color: #3f51b5"
            >remove_circle_outline</mat-icon
          >
          Alto
          <mat-icon (click)="modifyRect('v+')" style="color: #3f51b5">add_circle_outline</mat-icon>
          <!--<button mat-mini-fab color="primary" type="button" class="vr" (click)="modifyRect('v+')">
            <mat-icon>add</mat-icon>
          </button>-->
          <mat-icon (click)="modifyRect('h-')" style="color: #3f51b5"
            >remove_circle_outline</mat-icon
          >
          Ancho
          <mat-icon (click)="modifyRect('h+')" style="color: #3f51b5">add_circle_outline</mat-icon>
        </div>
        <div *ngIf="selectedView?.viewValue === 'Círculo'">
          Círculo:
          <mat-icon (click)="modifyCircle('r-')" style="color: #3f51b5"
            >remove_circle_outline</mat-icon
          >
          Radio
          <mat-icon (click)="modifyCircle('r+')" style="color: #3f51b5"
            >add_circle_outline</mat-icon
          >
        </div>
        <div *ngIf="selectedView?.viewValue === 'Círculo'">
          <mat-icon (click)="modifyCircle('down')" style="color: #3f51b5"
            >remove_circle_outline</mat-icon
          >
          Posición vertical
          <mat-icon (click)="modifyCircle('up')" style="color: #3f51b5"
            >add_circle_outline</mat-icon
          >
          <mat-icon (click)="modifyCircle('left')" style="color: #3f51b5"
            >remove_circle_outline</mat-icon
          >
          Posición horizontal
          <mat-icon (click)="modifyCircle('right')" style="color: #3f51b5"
            >add_circle_outline</mat-icon
          >
        </div>
        <div *ngIf="selectedView?.viewValue === 'Curva'">
          Curva vertical:
          <div class="form-group">
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Punto de inicio horizontal</mat-label>
              <input type="number" matInput formControlName="x0" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Anchura</mat-label>
              <input type="number" matInput formControlName="width" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Punto de inicio vertical</mat-label>
              <input type="number" matInput formControlName="y0" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Altura</mat-label>
              <input type="number" matInput formControlName="height" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Punto de control superior</mat-label>
              <input type="number" matInput formControlName="cp" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Punto de control inferior</mat-label>
              <input type="number" matInput formControlName="cp2" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset derecho</mat-label>
              <input type="number" matInput formControlName="ro" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset izquierdo</mat-label>
              <input type="number" matInput formControlName="lo" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset vertical derecho</mat-label>
              <input type="number" matInput formControlName="vro" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset vertical izquierdo</mat-label>
              <input type="number" matInput formControlName="vlo" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset horizontal izquierdo</mat-label>
              <input type="number" matInput formControlName="vhlo" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset horizontal derecho</mat-label>
              <input type="number" matInput formControlName="vhro" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset curva superior</mat-label>
              <input type="number" matInput formControlName="vk1" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset curva inferior</mat-label>
              <input type="number" matInput formControlName="vk2" />
            </mat-form-field>
            <button
              mat-stroked-button
              class="light-button"
              type="button"
              (click)="createVerticalCurvedTrapezoid()">
              Crear Curva
            </button>
          </div>
          Curva horizontal:
          <div class="form-group">
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Punto de inicio horizontal</mat-label>
              <input type="number" matInput formControlName="hx0" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Anchura</mat-label>
              <input type="number" matInput formControlName="hwidth" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Punto de inicio vertical</mat-label>
              <input type="number" matInput formControlName="hy0" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Altura</mat-label>
              <input type="number" matInput formControlName="hHeight" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Punto de control superior</mat-label>
              <input type="number" matInput formControlName="hcp" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Punto de control inferior</mat-label>
              <input type="number" matInput formControlName="hcp2" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset derecho</mat-label>
              <input type="number" matInput formControlName="hro" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset izquierdo</mat-label>
              <input type="number" matInput formControlName="hlo" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset vertical derecho</mat-label>
              <input type="number" matInput formControlName="hvro" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset vertical izquierdo</mat-label>
              <input type="number" matInput formControlName="hvlo" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset horizontal izquierdo</mat-label>
              <input type="number" matInput formControlName="hhlo" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset horizontal derecho</mat-label>
              <input type="number" matInput formControlName="hhro" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset curva superior</mat-label>
              <input type="number" matInput formControlName="k1" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="positionWidth">
              <mat-label>Offset curva inferior</mat-label>
              <input type="number" matInput formControlName="k2" />
            </mat-form-field>
            <button
              mat-stroked-button
              class="light-button"
              type="button"
              (click)="createCurvedTrapezoid()">
              Crear Curva
            </button>
          </div>
        </div>
        <div class="form-group">
          <form [formGroup]="adhocForm">
            <mat-form-field appearance="standard" class="fullWidth">
              <mat-label>Polígono adhoc</mat-label>
              <input matInput formControlName="adhoc" />
            </mat-form-field>
          </form>
          <button mat-raised-button color="primary" type="button" (click)="createAdHocPolygon()">
            Actualizar
          </button>
        </div>
        <svg
          #svg
          appSvgInit
          (newItemEvent)="update($event)"
          style="
            border: 1px solid black;
            background-image: url('../../../../../assets/img/grid.png');
          "
          stroke="none"
          width="1000"
          height="700"
          xmlns="<http://www.w3.org/2000/svg>"
          *ngIf="selectedView?.value === 'svg'">
          <!--<polygon
              *ngFor="let item of items"
              [attr.points]="item.points"
              [fill]="item.color"
              style="stroke: purple; stroke-width: 1" />-->
          <!--<defs>
            <pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse">
              <path d="M 8 0 L 0 0 0 8" fill="none" stroke="gray" stroke-width="0.5" />
            </pattern>
            <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
              <rect width="80" height="80" fill="url(#smallGrid)" />
              <path d="M 80 0 L 0 0 0 80" fill="none" stroke="gray" stroke-width="1" />
            </pattern>
          </defs>

          <rect width="100%" height="100%" fill="url(#grid)" />-->
          <polygon [fill]="sectionColor" stroke="black" stroke-width="0.2"></polygon>
        </svg>
        <svg
          #svg
          appSvg
          (newItemEvent)="update($event)"
          style="
            border: 1px solid black;
            background-image: url('../../../../../assets/img/grid.png');
          "
          stroke="none"
          width="1000"
          height="700"
          xmlns="<http://www.w3.org/2000/svg>"
          *ngIf="selectedView?.value === 'svgclick'">
          <polygon [fill]="sectionColor" stroke="black" stroke-width="0.2"></polygon>
        </svg>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-stroked-button class="light-button" type="button" (click)="gotoEdit()">
            Volver
          </button>
          <button mat-stroked-button class="light-button" type="button" (click)="onViewChange()">
            Limpiar
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
