import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { EventsService } from 'src/app/shared/services/events.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-entradas',
  templateUrl: './entradas.component.html',
  styleUrls: ['./entradas.component.scss']
})
export class EntradasComponent implements OnInit {
  displayedColumns: string[] = ['name', 'actions']
  dataSource: any
  @ViewChild(MatPaginator)
  paginator!: MatPaginator
  user: any
  roles: any
  constructor(private eventsService: EventsService, private router: Router) {}

  ngOnInit(): void {
    this.getRoles()
    this.getData()
  }

  async getDataAll() {
    ;(await this.eventsService.getAll()).subscribe({
      next: async (response: any) => {
        console.log(response)
        this.dataSource = new MatTableDataSource(response.event)
        this.dataSource.paginator = this.paginator
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }
  async getData() {
    if (this.roles.includes('admin')) {
      await this.getDataAll()
    } else {
      ;(await this.eventsService.getEventsByTaquillero(this.user.id)).subscribe({
        next: async (response: any) => {
          console.log(response)
          this.dataSource = new MatTableDataSource(response.event)
          this.dataSource.paginator = this.paginator
        },
        complete: () => {},
        error: (error: any) => {
          console.log(error)
        }
      })
    }
  }

  delete(id: string) {
    Swal.fire({
      title: '¿Quieres borrar el evento?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.eventsService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Evento borrado correctamente', '', 'success')
            this.getData()
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se ha podido borrar el evento', '', 'error')
          }
        })
      }
    })
  }

  gotoCreate() {
    this.router.navigate(['/home/events'])
  }

  gotoEdit(event: any) {
    this.router.navigate(['/home/event-sessions-details'], {
      state: { mode: 'edit', event: event }
    })
  }

  gotoWatch(event: any) {
    this.router.navigate(['/home/event-details'], {
      state: { mode: 'watch', event: event }
    })
  }

  async getRoles() {
    const user = JSON.parse(localStorage.getItem('fndsadmin::current_user') || '{}')
    this.user = user.user
    this.roles = this.user.role
  }
}
