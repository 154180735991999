/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { IEnclosure } from 'src/app/shared/models/enclosures.model'
import { AccountService } from 'src/app/shared/services/account.service'
import { EnclosuresService } from 'src/app/shared/services/enclosures.service'
import { FilesService } from 'src/app/shared/services/files.service'
import { GeolocationService } from 'src/app/shared/services/geolocation.service'
import { environment } from 'src/environments/environment'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-enclosures',
  templateUrl: './enclosures.component.html',
  styleUrls: ['./enclosures.component.scss']
})
export class EnclosuresComponent implements OnInit {
  private loadedEnclosure: any
  mode: string = 'create'
  public createEnclosuresForm!: FormGroup
  loading = false
  submitted = false
  selectedFile: any
  selectedFileName: string = ''
  public newEnclosure!: IEnclosure
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private geolocationsrv: GeolocationService,
    private enclosuresService: EnclosuresService,
    private filesService: FilesService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.createEnclosuresForm = this.fb.group({
      address: ['', Validators.required],
      name: ['', Validators.required],
      number: ['', Validators.required],
      floor: [''],
      zip: [''],
      city: ['', Validators.required]
    })
    this.loadedEnclosure = history.state.enclosure
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit') {
      this.editMode()
    } else if (this.mode === 'watch') {
      this.watchMode()
    }
  }

  editMode() {
    this.createEnclosuresForm.controls['address'].setValue(this.loadedEnclosure.address.address)
    this.createEnclosuresForm.controls['name'].setValue(this.loadedEnclosure.name)
    this.createEnclosuresForm.controls['number'].setValue(this.loadedEnclosure.address.number)
    this.createEnclosuresForm.controls['floor'].setValue(this.loadedEnclosure.address.floor)
    this.createEnclosuresForm.controls['zip'].setValue(this.loadedEnclosure.address.zip)
    this.createEnclosuresForm.controls['city'].setValue(this.loadedEnclosure.address.city)
    this.selectedFileName = this.loadedEnclosure.picture?.url
  }

  watchMode() {
    this.createEnclosuresForm.controls['address'].setValue(this.loadedEnclosure.address.address)
    this.createEnclosuresForm.controls['name'].setValue(this.loadedEnclosure.name)
    this.createEnclosuresForm.controls['number'].setValue(this.loadedEnclosure.address.number)
    this.createEnclosuresForm.controls['floor'].setValue(this.loadedEnclosure.address.address.floor)
    this.createEnclosuresForm.controls['zip'].setValue(this.loadedEnclosure.address.zip)
    this.createEnclosuresForm.controls['city'].setValue(this.loadedEnclosure.address.city)
    this.selectedFileName = this.loadedEnclosure.picture?.url
  }

  public async onSubmit(): Promise<void> {
    if (this.createEnclosuresForm.valid) {
      const address =
        this.createEnclosuresForm.get('address')?.value +
        ', ' +
        this.createEnclosuresForm.get('number')?.value +
        ', ' +
        this.createEnclosuresForm.get('city')?.value
      const url =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        address +
        environment.googleApiKey
      let locc
      this.http.post(url, locc).subscribe((res: any) => {
        if (res.status !== 'OK') {
          Swal.fire('Dirección errónea', '', 'error')
        } else {
          const location = res.results['0'].geometry.location
          this.create(location)
        }
      })
    }
  }

  public async create(location: any): Promise<void> {
    if (this.mode === 'edit') {
      this.edit(location)
    } else {
      const newEnclosure = {
        name: this.createEnclosuresForm.get('name')?.value,
        address: {
          address: this.createEnclosuresForm.get('address')?.value,
          number: this.createEnclosuresForm.get('number')?.value,
          floor: this.createEnclosuresForm.get('floor')?.value,
          zip: this.createEnclosuresForm.get('zip')?.value,
          city: this.createEnclosuresForm.get('city')?.value,
          geolocation: {
            lon: location.lng,
            lat: location.lat
          },
          picture: this.selectedFile
        }
      }
      this.submitted = true
      ;(await this.enclosuresService.create(newEnclosure)).subscribe({
        next: async response => {
          Swal.fire('Recinto creado correctamente', '', 'success')
          this.router.navigate(['/home/enclosures-list'])
        },
        complete: () => {},
        error: error => {
          console.log(error)
        }
      })
    }
  }

  public async edit(location: any) {
    let editedEnclosure: any
    if (this.selectedFile) {
      editedEnclosure = {
        name: this.createEnclosuresForm.get('name')?.value,
        address: {
          address: this.createEnclosuresForm.get('address')?.value,
          number: this.createEnclosuresForm.get('number')?.value,
          floor: this.createEnclosuresForm.get('floor')?.value,
          zip: this.createEnclosuresForm.get('zip')?.value,
          city: this.createEnclosuresForm.get('city')?.value,
          geolocation: {
            lon: location.lng,
            lat: location.lat
          }
        },
        picture: this.loadedEnclosure.picture
      }
    } else {
      editedEnclosure = {
        name: this.createEnclosuresForm.get('name')?.value,
        address: {
          address: this.createEnclosuresForm.get('address')?.value,
          number: this.createEnclosuresForm.get('number')?.value,
          floor: this.createEnclosuresForm.get('floor')?.value,
          zip: this.createEnclosuresForm.get('zip')?.value,
          city: this.createEnclosuresForm.get('city')?.value,
          geolocation: {
            lon: location.lng,
            lat: location.lat
          }
        },
        picture: this.loadedEnclosure.picture
      }
    }
    this.submitted = true
    ;(await this.enclosuresService.updateOne(this.loadedEnclosure.id, editedEnclosure)).subscribe({
      next: async response => {
        Swal.fire('Recinto editado correctamente', '', 'success')

        this.router.navigate(['/home/enclosures-list'])
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  gotoList() {
    this.router.navigate(['/home/enclosures-list'])
  }

  async selectFile(event: any) {
    this.selectedFile = event.target.files
    const file: File = this.selectedFile.item(0)
    ;(await this.filesService.create(file)).subscribe({
      next: async response => {
        Swal.fire('Imagen subida correctamente', '', 'success')
        this.selectedFile = response.file
        this.selectedFileName = response.file.url
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }
}
