/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { AccountService } from 'src/app/shared/services/account.service'
import { EnclosuresService } from 'src/app/shared/services/enclosures.service'
import { GeolocationService } from 'src/app/shared/services/geolocation.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'role', 'userType', 'actions']
  dataSource: any
  @ViewChild(MatPaginator)
  paginator!: MatPaginator

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private geolocationsrv: GeolocationService,
    private enclosuresService: EnclosuresService
  ) {}

  ngOnInit(): void {
    this.getData()
  }

  async getData() {
    ;(await this.accountService.getAllManagers()).subscribe({
      next: async (response: any) => {
        this.dataSource = new MatTableDataSource(response.users)
        this.dataSource.paginator = this.paginator
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  delete(user: any) {
    if (user.email === 'raul.zori@ara-tech.es') {
      Swal.fire('Error, este usuario no se puede borrar', '', 'error')
    } else {
      Swal.fire({
        title: '¿Quieres borrar el usuario?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Borrar',
        denyButtonText: 'Cancelar'
      }).then(async result => {
        if (result.isConfirmed) {
          ;(await this.accountService.delete(user.id)).subscribe({
            next: async (response: any) => {
              Swal.fire('Usuario eliminado correctamente', '', 'success')
              this.getData()
            },
            complete: () => {},
            error: (error: any) => {
              console.log(error)
            }
          })
        }
      })
    }
  }
  gotoCreate() {
    this.router.navigate(['/home/users'])
  }

  gotoEdit(user: any) {
    this.router.navigate(['/home/users'], {
      state: { mode: 'edit', user: user }
    })
  }

  gotoWatch(user: any) {
    this.router.navigate(['/home/users'], {
      state: { mode: 'watch', user: user }
    })
  }
}
