<div class="enclosures-list">
  <mat-card>
    <mat-card-title>Listado de sesiones</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
    <mat-card-content>
      <table mat-table [dataSource]="sessionsList">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Fecha inicio</th>
          <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd/MM/yyyy' }}</td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>Fecha finalización</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input
                matInput
                placeholder="Fecha de finalización"
                matTooltip="Introduzca la fecha en este fornato: dd/MM/yyyy"
                [value]="element.endDate"
                [ngModel]="element.endDate | date: 'dd/MM/yyyy'"
                (ngModelChange)="endDateChange($event)" />
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="initHour">
          <th mat-header-cell *matHeaderCellDef>Horario de inicio</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input
                matInput
                placeholder="Name"
                [value]="element.initHour"
                [(ngModel)]="element.initHour" />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="endHour">
          <th mat-header-cell *matHeaderCellDef>Horario de finalización</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input
                matInput
                placeholder="Name"
                [value]="element.endHour"
                [(ngModel)]="element.endHour" />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container
          matColumnDef="actions"
          *ngIf="roles.includes('ventaTaquilla') || roles.includes('admin')">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row; let element">
            <button mat-icon-button aria-label="Visibility" *ngIf="roles.includes('admin')">
              <mat-icon>visibility</mat-icon>
            </button>
            <button
              *ngIf="roles.includes('ventaTaquilla') || roles.includes('admin')"
              mat-icon-button
              aria-label="event_seat"
              (click)="editSectionsGrid(element)">
              <mat-icon>event_seat</mat-icon>
            </button>
            <button
              mat-icon-button
              aria-label="Edit"
              (click)="editSession(element)"
              *ngIf="roles.includes('admin')">
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              aria-label="Precios"
              (click)="editPrices(element)"
              *ngIf="roles.includes('admin')">
              <mat-icon> attach_money</mat-icon>
            </button>

            <button
              mat-icon-button
              aria-label="Delete"
              (click)="delete(element.id)"
              *ngIf="roles.includes('admin')">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
      <div>
        <button mat-raised-button (click)="gotoList()">Volver</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
