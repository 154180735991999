import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import { AccountService } from './account.service'
@Injectable({
  providedIn: 'root'
})
export class EnclosuresService {
  constructor(private _http: HttpClient, private accountService: AccountService) {}

  async create(enclosure: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.post(environment.apiUrl + '/admin/enclosures', enclosure, { headers })
  }

  async getAll(): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/enclosures', { headers })
  }

  async delete(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.delete(environment.apiUrl + '/admin/enclosures/' + id, { headers })
  }

  async updateOne(id: string, enclosure: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.put(environment.apiUrl + '/admin/enclosures/' + id, enclosure, { headers })
  }
}
