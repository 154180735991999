/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { firstValueFrom } from 'rxjs'
import { EventsService } from 'src/app/shared/services/events.service'
import { SectionsPricesService } from 'src/app/shared/services/sectionPrices.service'
import { SessionsService } from 'src/app/shared/services/sessions.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-event-sessions-details',
  templateUrl: './event-sessions-details.component.html',
  styleUrls: ['./event-sessions-details.component.scss']
})
export class EventSessionsDetailsComponent implements OnInit {
  private loadedEvent: any
  mode: string = 'create'
  displayedColumns: string[] = ['date', 'endDate', 'initHour', 'endHour', 'actions']
  sessionsList: any
  @ViewChild(MatPaginator)
  paginator!: MatPaginator
  user: any
  roles: any
  endDate = ''

  constructor(
    private sessionsService: SessionsService,
    private eventsService: EventsService,
    private router: Router,
    private sectionsPricesService: SectionsPricesService
  ) {}

  ngOnInit(): void {
    this.getRoles()
    this.loadedEvent = history.state.event
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit') {
      this.editMode()
    } else if (this.mode === 'watch') {
      this.watchMode()
    }
    this.getSessions()
  }

  editMode() {
    console.log('edit mode')
  }

  watchMode() {
    console.log('watch mode')
  }

  async getSessions() {
    ;(await this.eventsService.getOne(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        console.log('response: ', response)
        this.sessionsList = new MatTableDataSource(response.event.sessions)
        this.sessionsList.paginator = this.paginator
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
    /*
    ;(await this.sessionsService.getAllbyEventId(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        this.sessionsList = new MatTableDataSource(response.sessions)
        this.sessionsList.paginator = this.paginator
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })*/
  }

  public async editSectionsGrid(element: any) {
    console.log('element: ', element)
    this.router.navigate(['/home/event-sessions-sectiongrids'], {
      state: { mode: 'edit', session: element, event: this.loadedEvent }
    })
  }

  delete(id: string) {
    Swal.fire({
      title: '¿Quieres borrar la sesión?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.sessionsService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Sesión borrada correctamente', '', 'success')
            this.getSessions()
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se ha podido borrar la sesión', '', 'error')
          }
        })
      }
    })
  }
  gotoCreate() {
    this.router.navigate(['/home/enclosures'])
  }

  gotoEdit(enclosure: any) {
    this.router.navigate(['/home/enclosures'], {
      state: { mode: 'edit', enclosure: enclosure }
    })
  }

  gotoList() {
    if (this.roles.includes('admin')) {
      this.router.navigate(['/home/event-details'], {
        state: { mode: 'edit', event: this.loadedEvent }
      })
    } else {
      this.router.navigate(['/home/entradas'])
    }
  }

  public async editSession(element: any): Promise<void> {
    console.log('element: ', element)
    if (this.endDate === '') {
      this.endDate = element.endDate
    }
    const newSessions = {
      name: 'string',
      event: this.loadedEvent.id,
      date: element.date,
      endDate: this.endDate,
      initHour: element.initHour,
      endHour: element.endHour,
      seatMap: element.seatMap,
      sectionsPrices: element.sectionsPrices
    }
    const sectionsPrices2Send: any[] = []
    for (const sp of element.sectionsPrices) {
      firstValueFrom(await this.sectionsPricesService.getOne(sp)).then((response: any) => {
        sectionsPrices2Send.push(response.sectionPrice)
      })
    }
    newSessions.sectionsPrices = sectionsPrices2Send
    ;(await this.sessionsService.updateOne(element.id, newSessions)).subscribe({
      next: async response => {
        Swal.fire('Sesión actualizada correctamente', '', 'success')
        this.getSessions()
      },
      complete: () => {},
      error: error => {
        Swal.fire(
          'La fecha de finalización debe estar en este formato: 2023-07-19T12:00:00.000Z',
          '',
          'error'
        )
        console.log(error)
      }
    })
  }

  gotoWatch(enclosure: any) {
    this.router.navigate(['/home/enclosures'], {
      state: { mode: 'watch', enclosure: enclosure }
    })
  }

  editPrices(session: any) {
    this.router.navigate(['/home/session-prices'], {
      state: { mode: 'watch', session: session }
    })
  }

  getRoles() {
    const user = JSON.parse(localStorage.getItem('fndsadmin::current_user') || '{}')
    this.user = user.user
    this.roles = this.user.role
  }

  endDateChange(event: any) {
    console.log(event)
    this.endDate =
      event.slice(6, 10) + '-' + event.slice(3, 5) + '-' + event.slice(0, 2) + 'T12:00:00.000Z'
  }
}
