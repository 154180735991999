import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { SubcategoriesService } from 'src/app/shared/services/subcategories.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-subcategories-list',
  templateUrl: './subcategories-list.component.html',
  styleUrls: ['./subcategories-list.component.scss']
})
export class SubcategoriesListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'category', 'actions']
  dataSource: any
  @ViewChild(MatPaginator)
  paginator!: MatPaginator

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface

  constructor(private subcategoriesService: SubcategoriesService, private router: Router) {}

  ngOnInit(): void {
    this.getData()
  }

  async getData() {
    ;(await this.subcategoriesService.getAll()).subscribe({
      next: async (response: any) => {
        this.dataSource = new MatTableDataSource(response.subcategory)
        this.dataSource.paginator = this.paginator
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  delete(id: string) {
    Swal.fire({
      title: '¿Quieres borrar la subcategoría?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.subcategoriesService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Subcategoría borrada correctamente', '', 'success')
            this.getData()
          },
          complete: () => {},
          error: (error: any) => {
            console.log(error)
          }
        })
      }
    })
  }

  gotoCreate() {
    this.router.navigate(['/home/subcategories'])
  }

  gotoEdit(subcategory: any) {
    this.router.navigate(['/home/subcategories'], {
      state: { mode: 'edit', subcategory: subcategory }
    })
  }

  gotoWatch(subcategory: any) {
    this.router.navigate(['/home/subcategories'], {
      state: { mode: 'watch', subcategory: subcategory }
    })
  }
}
