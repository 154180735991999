import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AccountService } from 'src/app/shared/services/account.service'
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor(private _http: HttpClient, private accountService: AccountService) {}

  async generatePdf(orderNumber: string): Promise<Observable<any>> {
    /* const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    } */
    return this._http.get(
      environment.apiUrl + '/pdf/' + orderNumber,
      { responseType: 'blob' as 'json' } /* , { headers } */
    )
  }
}
