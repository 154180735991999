/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import * as d3 from 'd3'
import { access, toilets } from 'src/app/shared/constants/signs'
import { SeatMapsService } from 'src/app/shared/services/seat-maps.service'
import Swal from 'sweetalert2'

interface View {
  value: string
  viewValue: string
}
@Component({
  selector: 'app-seat-maps-stage',
  templateUrl: './seat-maps-stage.component.html',
  styleUrls: ['./seat-maps-stage.component.scss']
})
export class SeatMapsStageComponent implements OnInit {
  public createStageForm!: FormGroup
  views: View[] = [
    { viewValue: 'Rectángulo', value: 'svgclick' },
    { viewValue: 'Polígono', value: 'svg' },
    { viewValue: 'Curva', value: 'svgclick' },
    { viewValue: 'Círculo', value: 'svgclick' }
  ]
  selectedView: any
  public adhocForm!: FormGroup
  public points: Array<[number, number]> = []
  loadedSeatMap: any
  mode = 'create'
  sectionColor = '#FFFFFF'
  polygon: any
  submitted = false
  loading = false
  index = 0
  trapezoidType = ''

  constructor(
    private router: Router,
    private seatMapsService: SeatMapsService,
    private fb: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.createStageForm = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required],
      x0: [50],
      width: [100],
      y0: [100],
      height: [100],
      ro: [0],
      lo: [0],
      vro: [0],
      vlo: [0],
      cp: [100],
      cp2: [100],
      hwidth: [100],
      hx0: [400],
      hy0: [100],
      hHeight: [100],
      hro: [0],
      hlo: [0],
      hvro: [0],
      hvlo: [0],
      hhro: [0],
      hhlo: [0],
      vhro: [0],
      vhlo: [0],
      hcp: [100],
      hcp2: [100],
      k1: [200],
      k2: [200],
      vk1: [50],
      vk2: [50]
    })
    this.adhocForm = this.fb.group({
      adhoc: ['']
    })
    this.loadedSeatMap = history.state.seatMap
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    if (history.state.index) {
      this.index = history.state.index
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      this.views[1].value = 'svgclick'
      if (this.loadedSeatMap.stage[this.index].polygon[0] === 'C') {
        this.selectedView = this.views[2]
      } else if (this.loadedSeatMap.stage[this.index].polygon[0] === 'H') {
        this.selectedView = this.views[2]
      } else if (this.loadedSeatMap.stage[this.index].polygon[0] === 'R') {
        this.selectedView = this.views[3]
      } else {
        this.selectedView = this.views[1]
      }
      await new Promise(f => setTimeout(f, 500))
      this.setLoadedData()
    } else {
      this.selectedView = this.views[1]
      await new Promise(f => setTimeout(f, 500))
    }
    this.renderCurrentSections()
  }

  setLoadedData() {
    this.createStageForm.controls['name'].setValue(this.loadedSeatMap.stage[this.index].name)
    this.createStageForm.controls['color'].setValue(this.loadedSeatMap.stage[this.index].color)
    this.adhocForm.controls['adhoc'].setValue(this.loadedSeatMap.stage[this.index].polygon)
    let newStr = this.loadedSeatMap.stage[this.index].polygon.slice(1)
    let polygon = newStr.split(',')
    if (this.loadedSeatMap.stage[this.index].polygon[0] === 'C') {
      this.createStageForm.controls['x0'].setValue(parseFloat(polygon[0]))
      this.createStageForm.controls['y0'].setValue(parseFloat(polygon[1]))
      this.createStageForm.controls['width'].setValue(parseFloat(polygon[2]))
      this.createStageForm.controls['height'].setValue(parseFloat(polygon[3]))
      this.createStageForm.controls['ro'].setValue(parseFloat(polygon[4]))
      this.createStageForm.controls['lo'].setValue(parseFloat(polygon[5]))
      this.createStageForm.controls['vro'].setValue(parseFloat(polygon[6]))
      this.createStageForm.controls['vlo'].setValue(parseFloat(polygon[7]))
      this.createStageForm.controls['cp'].setValue(parseFloat(polygon[8]))
      this.createStageForm.controls['vhro'].setValue(parseFloat(polygon[9]))
      this.createStageForm.controls['vhlo'].setValue(parseFloat(polygon[10]))
      this.createStageForm.controls['cp2'].setValue(parseFloat(polygon[11]))
      this.createStageForm.controls['vk1'].setValue(parseFloat(polygon[12]))
      this.createStageForm.controls['vk2'].setValue(parseFloat(polygon[13]))
    } else if (this.loadedSeatMap.sections[this.index].polygon[0] === 'H') {
      this.createStageForm.controls['hx0'].setValue(parseFloat(polygon[0]))
      this.createStageForm.controls['hy0'].setValue(parseFloat(polygon[1]))
      this.createStageForm.controls['hwidth'].setValue(parseFloat(polygon[2]))
      this.createStageForm.controls['hHeight'].setValue(parseFloat(polygon[3]))
      this.createStageForm.controls['hro'].setValue(parseFloat(polygon[4]))
      this.createStageForm.controls['hlo'].setValue(parseFloat(polygon[5]))
      this.createStageForm.controls['hvro'].setValue(parseFloat(polygon[6]))
      this.createStageForm.controls['hvlo'].setValue(parseFloat(polygon[7]))
      this.createStageForm.controls['hcp'].setValue(parseFloat(polygon[8]))
      this.createStageForm.controls['hhro'].setValue(parseFloat(polygon[9]))
      this.createStageForm.controls['hhlo'].setValue(parseFloat(polygon[10]))
      this.createStageForm.controls['hcp2'].setValue(parseFloat(polygon[11]))
      this.createStageForm.controls['k1'].setValue(parseFloat(polygon[12]))
      this.createStageForm.controls['k2'].setValue(parseFloat(polygon[13]))
    }
  }

  gotoList() {
    this.router.navigate(['/home/seat-map-general'], {
      state: { mode: 'edit', seatMap: this.loadedSeatMap }
    })
  }

  update($event: any) {
    this.polygon = $event
    this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  private async renderCurrentSections() {
    if (this.loadedSeatMap.stage.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.stage.length; i++) {
        if (this.loadedSeatMap.stage[i].polygon[0] === 'C') {
          this.createStoredVerticalCurvedTrapezoid(
            this.loadedSeatMap.stage[i].polygon,
            this.loadedSeatMap.stage[i]?.name,
            this.loadedSeatMap.stage[i].color
          )
        } else if (this.loadedSeatMap.stage[i].polygon[0] === 'H') {
          this.createStoredCurvedTrapezoid(
            this.loadedSeatMap.stage[i].polygon,
            this.loadedSeatMap.stage[i]?.name,
            this.loadedSeatMap.stage[i].color
          )
        } else if (this.loadedSeatMap.stage[i].polygon[0] === 'R') {
          this.createCircle(
            this.loadedSeatMap.stage[i],
            this.loadedSeatMap.stage[i]?.name,
            i,
            'stage'
          )
        } else {
          this.createPolygon(
            this.loadedSeatMap.stage[i],
            this.loadedSeatMap.stage[i]?.name,
            i,
            'stage'
          )
        }
      }
    }
    if (this.loadedSeatMap.toilets.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.toilets.length; i++) {
        this.createToilet(this.loadedSeatMap.toilets[i], 'Aseo' + i)
      }
    }
    if (this.loadedSeatMap.access.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.access.length; i++) {
        this.createAccess(this.loadedSeatMap.access[i], 'Acceso' + i)
      }
    }
    if (this.loadedSeatMap.sections.length > 0) {
      for (let i = 0; i < this.loadedSeatMap.sections.length; i++) {
        if (this.loadedSeatMap.sections[i].polygon[0] === 'C') {
          this.createStoredVerticalCurvedTrapezoid(
            this.loadedSeatMap.sections[i].polygon,
            this.loadedSeatMap.sections[i].name,
            this.loadedSeatMap.sections[i].color
          )
        } else if (this.loadedSeatMap.sections[i].polygon[0] === 'H') {
          this.createStoredCurvedTrapezoid(
            this.loadedSeatMap.sections[i].polygon,
            this.loadedSeatMap.sections[i].name,
            this.loadedSeatMap.sections[i].color
          )
        } else if (this.loadedSeatMap.sections[i].polygon[0] === 'R') {
          this.createCircle(
            this.loadedSeatMap.sections[i],
            this.loadedSeatMap.sections[i].name,
            i,
            'sections'
          )
        } else {
          this.createPolygon(
            this.loadedSeatMap.sections[i],
            this.loadedSeatMap.sections[i].name,
            i,
            'sections'
          )
        }
      }
    }
  }

  public createToilet(toilet: any, name: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    let newStr = toilet.polygon.slice(1)
    let i = parseInt(toilet.polygon[0])
    let svg = d3.select(document.querySelector('svg'))
    const idImage = 'url(#' + name + 'locked2)'
    let myimage = svg
      .append('defs')
      .append('pattern')
      .attr('id', name + 'locked2')
      //.attr('patternUnits', 'userSpaceOnUse')
      .attr('width', toilets[i].width)
      .attr('height', toilets[i].height)
      .append('image')
      .attr('href', toilets[i].url)
      .attr('width', toilets[i].width)
      .attr('height', toilets[i].height)
      .attr('id', name + 'locked2')
    //.attr('transform', translate(20, 20))

    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', newStr)
      //.attr('fill', this.createSectionForm.get('color')?.value)
      .attr('fill', idImage)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    //this.polygon = polygon
    // this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  public createAccess(acc: any, name: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    let newStr = acc.polygon.slice(2)
    let i = parseInt(acc.polygon[0] + acc.polygon[1])
    let svg = d3.select(document.querySelector('svg'))
    const idImage = 'url(#' + name + 'locked2)'
    let myimage = svg
      .append('defs')
      .append('pattern')
      .attr('id', name + 'locked2')
      //.attr('patternUnits', 'userSpaceOnUse')
      .attr('width', access[i].width)
      .attr('height', access[i].height)
      .append('image')
      .attr('href', access[i].url)
      .attr('width', access[i].width)
      .attr('height', access[i].height)
      .attr('id', name + 'locked2')
    //.attr('transform', (200, 200))

    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', newStr)
      //.attr('fill', this.createSectionForm.get('color')?.value)
      .attr('fill', idImage)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    //this.polygon = polygon
    // this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  private createPolygon(sector: any, name: string, index: number, type: string) {
    if (this.mode === 'edit' && index === this.index && type === 'stage') {
      name = 'patata'
    }
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()
    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', sector.polygon)
      .attr('fill', sector.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  private createCircle(sector: any, name: string, index: number, type: string) {
    if (this.mode === 'edit' && index === this.index && type === 'stage') {
      name = 'patata'
    }
    let newStr = sector.polygon.slice(1)
    let polygon = newStr.split(',')
    //d3.select('#patata').remove()
    d3.selectAll('circle').remove()
    d3.select(document.querySelector('svg'))
      .attr('fill', sector.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
      .append('circle')
      .style('stroke', 'gray')
      .attr('r', polygon[0])
      .attr('cx', polygon[1])
      .attr('cy', polygon[2])
  }

  private createPredefinedCircle() {
    d3.select('#patata').remove()
    //d3.selectAll('circle').remove()
    d3.select(document.querySelector('svg'))
      .attr('fill', this.createStageForm.get('color')?.value)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
      .append('circle')
      .style('stroke', 'gray')
      .attr('id', 'patata')
      .attr('r', 100)
      .attr('cx', 200)
      .attr('cy', 200)
  }

  public createPredefinedPolygon(polygon: any, name: string) {
    console.log(polygon)
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    d3.select('#patata').remove()
    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', polygon)
      .attr('fill', this.createStageForm.get('color')?.value)
      .attr('id', 'patata')
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    this.polygon = polygon
    this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  public createStoredVerticalCurvedTrapezoid(path: string, name: string, color: string) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width / 2
    }
    if (Number.isNaN(k2)) {
      k2 = width / 2
    }
    const k = (x0 + width + k1) / 2
    const kb = (x0 + width + k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()
    this.trapezoidType = 'C'

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          k +
          ',' +
          (y0 + cp) +
          ' ' +
          (x0 + width + hlo) +
          ',' +
          (y0 + vro) +
          'L ' +
          (x0 + width - ro) +
          ',' +
          (y0 + height + hro) +
          ' Q ' +
          kb +
          ',' +
          (y0 + height + cp2) +
          ' ' +
          (x0 + lo) +
          ',' +
          (y0 + height + vlo) +
          ' Z'
        )
      })
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public createStoredCurvedTrapezoid(path: string, name: string, color: string) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp)) {
      cp = 100
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width
    }
    if (Number.isNaN(k2)) {
      k2 = width
    }
    const k = (x0 + width - k1) / 2
    const kb = (x0 + width - k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()
    this.trapezoidType = 'H'

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          (x0 + cp) +
          ',' +
          k +
          ' ' +
          (x0 + hro) +
          ',' +
          (y0 + height + hlo) +
          'L ' +
          (x0 - width - ro) +
          ',' +
          (y0 + height + vro) +
          ' Q ' +
          (x0 + cp2 - width) +
          ',' +
          kb +
          ' ' +
          (x0 - width - lo) +
          ',' +
          (y0 + lo + vlo) +
          ' Z'
        )
      })
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public createVerticalCurvedTrapezoid() {
    let data = [3]
    const value = this.adhocForm.get('adhoc')?.value
    let polygon = value.split(' ').join(',').split(',')
    const name = 'patata'
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = this.createStageForm.get('x0')?.value
    const y0 = this.createStageForm.get('y0')?.value
    const width = this.createStageForm.get('width')?.value
    const height = this.createStageForm.get('height')?.value
    const ro = this.createStageForm.get('ro')?.value
    const lo = this.createStageForm.get('lo')?.value
    const vro = this.createStageForm.get('vro')?.value
    const vlo = this.createStageForm.get('vlo')?.value
    const cp = this.createStageForm.get('cp')?.value
    const cp2 = this.createStageForm.get('cp2')?.value
    const hro = this.createStageForm.get('vhro')?.value
    const hlo = this.createStageForm.get('vhlo')?.value
    const k1 = this.createStageForm.get('vk1')?.value
    const k2 = this.createStageForm.get('vk2')?.value
    const k = (parseFloat(x0) + parseFloat(width) + parseFloat(k1)) / 2
    const kb = (parseFloat(x0) + parseFloat(width) + parseFloat(k2)) / 2
    d3.select('#' + name).remove()
    this.trapezoidType = 'C'
    /*const x0 = 100
    const y0 = 200
    const width = 100
    const height = 200
    const ro = 0
    const lo = 0
    const k = x0 + width / 2*/
    /*let path = d3.path()
    path.moveTo(100, 300)
    path.quadraticCurveTo(150, 200, 200, 300)
    path.lineTo(200, 400)
    path.quadraticCurveTo(150, 300, 100, 400)
    path.closePath()
    //d3.select('.path2').attr('d', path)*/
    // 'M100,250 Q250,300 400,250 L250,400 Q250,500 100,400 Z'
    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          k +
          ',' +
          (y0 + cp) +
          ' ' +
          (x0 + width + hlo) +
          ',' +
          (y0 + vro) +
          'L ' +
          (x0 + width - ro) +
          ',' +
          (y0 + height + hro) +
          ' Q ' +
          kb +
          ',' +
          (y0 + height + cp2) +
          ' ' +
          (x0 + lo) +
          ',' +
          (y0 + height + vlo) +
          ' Z'
        )
      })
      .attr('fill', this.createStageForm.get('color')?.value)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public createCurvedTrapezoid() {
    let data = [3]
    const value = this.adhocForm.get('adhoc')?.value
    let polygon = value.split(' ').join(',').split(',')
    const name = 'patata'
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = this.createStageForm.get('hx0')?.value
    const y0 = this.createStageForm.get('hy0')?.value
    const width = this.createStageForm.get('hwidth')?.value
    const height = this.createStageForm.get('hHeight')?.value
    const ro = this.createStageForm.get('hro')?.value
    const lo = this.createStageForm.get('hlo')?.value
    const vro = this.createStageForm.get('hvro')?.value
    const vlo = this.createStageForm.get('hvlo')?.value
    const hro = this.createStageForm.get('hhro')?.value
    const hlo = this.createStageForm.get('hhlo')?.value
    const cp2 = this.createStageForm.get('hcp2')?.value
    const cp = this.createStageForm.get('hcp')?.value
    const k1 = this.createStageForm.get('k1')?.value
    const k2 = this.createStageForm.get('k2')?.value
    const k = (parseFloat(x0) + parseFloat(width) - parseFloat(k1)) / 2
    const kb = (parseFloat(x0) + parseFloat(width) - parseFloat(k2)) / 2
    d3.select('#' + name).remove()
    this.trapezoidType = 'H'
    /*const x0 = 100
    const y0 = 200
    const width = 100
    const height = 200
    const ro = 0
    const lo = 0
    const k = x0 + width / 2*/
    /*let path = d3.path()
    path.moveTo(100, 300)
    path.quadraticCurveTo(150, 200, 200, 300)
    path.lineTo(200, 400)
    path.quadraticCurveTo(150, 300, 100, 400)
    path.closePath()
    //d3.select('.path2').attr('d', path)*/

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        // return 'M 400,100 Q9,150 400,200 L 100,200 Q9,150 100,100 Z'
        return (
          // 'M 400,100 Q9,250 500,200 L 100,300 Q9,250 100,200 Z'

          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          (x0 + cp) +
          ',' +
          k +
          ' ' +
          (x0 + hro) +
          ',' +
          (y0 + height + hlo) +
          'L ' +
          (x0 - width - ro) +
          ',' +
          (y0 + height + vro) +
          ' Q ' +
          (x0 + cp2 - width) +
          ',' +
          kb +
          ' ' +
          (x0 - width - lo) +
          ',' +
          (y0 + lo + vlo) +
          ' Z'
        )
      })
      .attr('fill', this.createStageForm.get('color')?.value)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public modifyCircle(opt: string) {
    // d3.selectAll('circle')[0][0].attributes.cx.value
    let cx = 0
    let cy = 0
    let r = 0
    d3.select('circle').each(function (d, i) {
      var elt = d3.select(this)
      cx = parseFloat(elt.attr('cx'))
      cy = parseFloat(elt.attr('cy'))
      r = parseFloat(elt.attr('r'))
      console.log('dentro del for')
      if (elt.attr('id') === '#patata') {
        console.log(elt.attr('cx'))
      }
    })

    //const svgPoints = polygonSelection.getAttribute('cx')
    let coords = ''
    /* for (let i = 0; i < svgPoints.length; i++) {
      if (i === 0) {
        coords = svgPoints[i].x + ',' + svgPoints[i].y
      } else {
        coords = coords + ' ' + svgPoints[i].x + ',' + svgPoints[i].y
      }
    }*/
    let sector = {
      polygon: ''
    }
    let polygon = coords.split(' ').join(',').split(',')
    let result = ''
    console.log(polygon)
    switch (opt) {
      case 'up':
        cy = cy - 2
        sector.polygon = 'R' + r.toString() + ',' + cx.toString() + ',' + cy.toString()
        this.createCircle(sector, 'patata', 0, 'null')
        break
      case 'down':
        cy = cy + 2
        sector.polygon = 'R' + r.toString() + ',' + cx.toString() + ',' + cy.toString()
        this.createCircle(sector, 'patata', 0, 'null')
        break
      case 'left':
        cx = cx - 2
        sector.polygon = 'R' + r.toString() + ',' + cx.toString() + ',' + cy.toString()
        this.createCircle(sector, 'patata', 0, 'null')
        break
      case 'right':
        cx = cx + 2
        sector.polygon = 'R' + r.toString() + ',' + cx.toString() + ',' + cy.toString()
        this.createCircle(sector, 'patata', 0, 'null')
        break
      case 'r+':
        r = r + 2
        sector.polygon = 'R' + r.toString() + ',' + cx.toString() + ',' + cy.toString()
        this.createCircle(sector, 'patata', 0, 'null')
        break
      case 'r-':
        r = r - 2
        sector.polygon = 'R' + r.toString() + ',' + cx.toString() + ',' + cy.toString()
        this.createCircle(sector, 'patata', 0, 'null')
        break
    }
    //this.createPredefinedPolygon(result, 'name')
  }

  public modifyRect(opt: string) {
    const polygonSelection = d3.select('#patata')
    const svgPoints = polygonSelection.property('points')
    let coords = ''
    for (let i = 0; i < svgPoints.length; i++) {
      if (i === 0) {
        coords = svgPoints[i].x + ',' + svgPoints[i].y
      } else {
        coords = coords + ' ' + svgPoints[i].x + ',' + svgPoints[i].y
      }
    }

    let polygon = coords.split(' ').join(',').split(',')
    let result = ''
    console.log(polygon)
    switch (opt) {
      case 'h+':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 4 || i === 6) {
            let newCoords = parseInt(polygon[i]) + 2
            result = result + newCoords.toString()
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'h-':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 4 || i === 6) {
            let newCoords = parseInt(polygon[i]) - 2
            result = result + newCoords.toString()
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'v+':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 3 || i === 5) {
            let newCoords = parseInt(polygon[i]) + 2
            result = result + ',' + newCoords.toString() + ' '
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'v-':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 3 || i === 5) {
            let newCoords = parseInt(polygon[i]) - 2
            result = result + ',' + newCoords.toString() + ' '
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
    }
    this.createPredefinedPolygon(result, 'name')
  }

  public createAdHocPolygon() {
    const value = this.adhocForm.get('adhoc')?.value
    console.log(11, value)
    this.createPredefinedPolygon(value, 'name')
  }

  public modifyRectByOnePoint(opt: string, symbol: string) {
    let patata = d3.select('#patata')
    let polygon = this.polygon.split(' ').join(',').split(',')
    let result = ''
    switch (opt) {
      case 'upperLeft':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 4 || i === 6) {
            let newCoords = parseInt(polygon[i]) + 2
            result = result + newCoords.toString()
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'lowerLeft':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 4 || i === 6) {
            let newCoords = parseInt(polygon[i]) - 2
            result = result + newCoords.toString()
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'upperRight':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 3 || i === 5) {
            let newCoords = parseInt(polygon[i]) + 2
            result = result + ',' + newCoords.toString() + ' '
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
      case 'lowerRight':
        for (let i = 0; i < polygon.length; i++) {
          if (i === 3 || i === 5) {
            let newCoords = parseInt(polygon[i]) - 2
            result = result + ',' + newCoords.toString() + ' '
          } else {
            if (i === 0) {
              result = result + polygon[i]
            } else if (i % 2 === 0) {
              result = result + polygon[i]
            } else {
              result = result + ',' + polygon[i] + ' '
            }
          }
        }
        break
    }
    this.createPredefinedPolygon(result, 'name')
  }

  public async onViewChange() {
    // this.selectedView = $event
    console.log(this.selectedView)
    await new Promise(f => setTimeout(f, 500))

    this.renderCurrentSections()
    if (this.selectedView.value === 'svgclick' && this.selectedView.viewValue === 'Rectángulo') {
      this.createPredefinedPolygon('0,0 0,50 50,50 50,0', 'name')
    }
    if (this.selectedView.value === 'svgclick' && this.selectedView.viewValue === 'Círculo') {
      this.createPredefinedCircle()
    }
  }

  gotoEdit() {
    this.router.navigate(['/home/seat-map-general'], {
      state: { mode: 'edit', seatMap: this.loadedSeatMap }
    })
  }

  public async onSubmit(): Promise<void> {
    if (this.createStageForm.valid) {
      let coords = ''
      if (this.selectedView.viewValue === 'Curva' && this.trapezoidType === 'C') {
        coords =
          this.trapezoidType +
          this.createStageForm.get('x0')?.value +
          ',' +
          this.createStageForm.get('y0')?.value +
          ',' +
          this.createStageForm.get('width')?.value +
          ',' +
          this.createStageForm.get('height')?.value +
          ',' +
          this.createStageForm.get('ro')?.value +
          ',' +
          this.createStageForm.get('lo')?.value +
          ',' +
          this.createStageForm.get('vro')?.value +
          ',' +
          this.createStageForm.get('vlo')?.value +
          ',' +
          this.createStageForm.get('cp')?.value +
          ',' +
          this.createStageForm.get('vhro')?.value +
          ',' +
          this.createStageForm.get('vhlo')?.value +
          ',' +
          this.createStageForm.get('cp2')?.value +
          ',' +
          this.createStageForm.get('vk1')?.value +
          ',' +
          this.createStageForm.get('vk2')?.value
      } else if (this.selectedView.viewValue === 'Curva' && this.trapezoidType === 'H') {
        coords =
          this.trapezoidType +
          this.createStageForm.get('hx0')?.value +
          ',' +
          this.createStageForm.get('hy0')?.value +
          ',' +
          this.createStageForm.get('hwidth')?.value +
          ',' +
          this.createStageForm.get('hHeight')?.value +
          ',' +
          this.createStageForm.get('hro')?.value +
          ',' +
          this.createStageForm.get('hlo')?.value +
          ',' +
          this.createStageForm.get('hvro')?.value +
          ',' +
          this.createStageForm.get('hvlo')?.value +
          ',' +
          this.createStageForm.get('hcp')?.value +
          ',' +
          this.createStageForm.get('hhro')?.value +
          ',' +
          this.createStageForm.get('hhlo')?.value +
          ',' +
          this.createStageForm.get('hcp2')?.value +
          ',' +
          this.createStageForm.get('k1')?.value +
          ',' +
          this.createStageForm.get('k2')?.value
      } else if (this.selectedView.viewValue === 'Círculo') {
        let cx = ''
        let cy = ''
        let r = ''
        d3.select('circle').each(function (d, i) {
          var elt = d3.select(this)
          cx = elt.attr('cx')
          cy = elt.attr('cy')
          r = elt.attr('r')
        })
        coords = 'R' + r + ',' + cx + ',' + cy
      } else {
        const polygonSelection = d3.select('#patata')
        const svgPoints = polygonSelection.property('points')

        for (let i = 0; i < svgPoints.length; i++) {
          if (i === 0) {
            coords = svgPoints[i].x + ',' + svgPoints[i].y
          } else {
            coords = coords + ' ' + svgPoints[i].x + ',' + svgPoints[i].y
          }
        }
      }
      const stage = {
        name: this.createStageForm.get('name')?.value,
        rows: 0,
        cols: 0,
        color: this.createStageForm.get('color')?.value,
        orientation: 'string',
        polygon: coords,
        position: {
          x: 0,
          y: 0
        }
      }
      if (this.mode === 'edit') {
        this.loadedSeatMap.stage[this.index] = stage
      } else {
        this.loadedSeatMap.stage.push(stage)
      }
      const seatMap = {
        name: this.loadedSeatMap.name,
        stage: this.loadedSeatMap.stage,
        sections: this.loadedSeatMap.sections,
        access: this.loadedSeatMap.access,
        toilets: this.loadedSeatMap.toilets
      }
      this.submitted = true
      ;(await this.seatMapsService.updateOne(this.loadedSeatMap.id, seatMap)).subscribe({
        next: async response => {
          Swal.fire('Mapa de asientos creado correctamente', '', 'success')
          this.router.navigate(['/home/seat-map-general'], {
            state: { mode: 'edit', seatMap: response.seatMap }
          })
        },
        complete: () => {},
        error: error => {
          console.log(error)
        }
      })
    }
  }

  gotoEventSessions() {
    this.router.navigate(['/home/seat-map-list'])
  }
}
