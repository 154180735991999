/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AccountService } from 'src/app/shared/services/account.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(private accountService: AccountService, private router: Router) {}

  ngOnInit(): void {}

  isLoggedIn() {
    return localStorage.getItem('fndsadmin::web_token') ? true : false
  }

  logout() {
    this.accountService.logOut()
    this.router.navigate(['/login'])
  }
}
