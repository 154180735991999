<section>
  <mat-card>
    <mat-card-title class="title" *ngIf="mode === 'create'"
      ><strong>Crear asientos </strong> <span>- {{ eventReceived?.name }}</span></mat-card-title
    >
    <mat-card-title class="title" *ngIf="mode === 'edit'"
      ><strong>Reserva de asientos </strong><span>- {{ eventReceived.name }}</span>
      <p></p>
      <h3><strong>Recinto: </strong>{{ eventReceived.enclosure.name }}</h3>
      <p></p>
      <h3>{{ session.date }}</h3>
      <p></p>
      <h3>{{ session.initHour }} - {{ session.endHour }}</h3>
    </mat-card-title>
    <mat-card-title class="title" *ngIf="mode === 'watch'"
      ><strong>Revisar asientos </strong><span>- {{ eventReceived?.name }}</span></mat-card-title
    >
    <mat-card-content>
      <!--  <div class="form-group-buttons">
        <button
          mat-raised-button
          color="primary"
          [disabled]="loading"
          class="actionButton"
          (click)="saveSession()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
        </button>
        <button mat-raised-button (click)="gotoList()" type="button">Volver</button>
      </div> -->
      <div></div>
    </mat-card-content>
    <mat-card-footer>
      <mat-grid-list cols="12" rowHeight="7.5vh">
        <mat-grid-tile [colspan]="11" [rowspan]="10">
          <mat-spinner class="centered__spinner" *ngIf="pdfLoading"></mat-spinner>
          <div class="view-seatmap__textscrollsvg" *ngIf="!showSeats">
            <div>
              <svg
                #svg
                style="border: 1px solid black"
                stroke="none"
                width="1000"
                height="700"
                xmlns="<http://www.w3.org/2000/svg>"
                title="Mapa de asientos">
                <!--<polygon
                  *ngFor="let sect of sections"
                  [attr.points]="sect.polygon"
                  [style.fill]="sect.color"
                  [attr.name]="sect.name"
                  matTooltip="{{ sect.name }}"
                  [matTooltipPositionAtOrigin]="true"
                  style="stroke: rgb(0, 0, 0); stroke-width: 1">
                  <text>{{ sect.name }}</text>
                </polygon>-->
                <polygon
                  id="svg"
                  *ngFor="let sect of sectionsPol"
                  [attr.points]="sect.points"
                  [style.fill]="sect.color"
                  [attr.name]="sect.id"
                  matTooltip="{{ sect.name }}"
                  (click)="this.showSection(sect)"
                  style="stroke: rgb(0, 0, 0); stroke-width: 1" />
                <path
                  id="svg"
                  *ngFor="let sectPath of sectionsPath"
                  [attr.d]="sectPath.path || sectPath.d"
                  [style.fill]="sectPath.color"
                  [attr.name]="sectPath.name"
                  matTooltip="{{ sectPath.name }}"
                  (click)="this.showSection(sectPath)"
                  style="stroke: rgb(0, 0, 0); stroke-width: 1" />
                <circle
                  id="svg"
                  *ngFor="let sect of sectionsC"
                  [attr.r]="sect.points[0]"
                  [attr.cx]="sect.points[1]"
                  [attr.cy]="sect.points[2]"
                  [style.fill]="sect.color"
                  [attr.name]="sect.name"
                  matTooltip="{{ sect.name }}"
                  (click)="this.showSection(sect)"
                  style="stroke: rgb(0, 0, 0); stroke-width: 1" />
                <image
                  id="svg"
                  *ngFor="let sect of sections"
                  [attr.id]="sect.id"
                  [attr.width]="sect.width"
                  [attr.height]="sect.height"
                  [attr.href]="sect.href"
                  [attr.x]="sect.x"
                  [attr.y]="sect.y"
                  style="stroke: rgb(0, 0, 0); stroke-width: 1" />
              </svg>
            </div>
            <div class="centered">
              <button mat-raised-button (click)="gotoSections2()" type="button">Volver</button>
            </div>
          </div>
          <div class="view-seatmap__textscrollsvg" *ngIf="showSeats">
            <div class="view-seatmap__column" *ngIf="showSeatsNoGeneral">
              <div class="view-seatmap__rowseats">
                <div class="view-seatmap__text2">Seleccionar asientos de {{ section?.name }}</div>
              </div>
              <div class="view-seatmap__rowseats">
                <div *ngFor="let seatrow of seatsRows">
                  <div class="seatrow container">
                    <div *ngIf="seatChartConfig?.showRowsLabel">
                      <div class="row-label">{{ seatrow.seatRowLabel }}</div>
                    </div>
                    <div *ngFor="let seatobj of seatrow.seats">
                      <div
                        class="seat-item seat-space"
                        style="color: white; background-color: white"
                        [style.margin-top.rem]="seatobj.seatMargin"
                        *ngIf="seatobj.seatLabel === ''">
                        &nbsp;
                      </div>
                      <div
                        matTooltip="Fila : {{ seatrow.seatRowLabel }} Asiento: {{
                          seatobj.seatNo
                        }}  Precio : {{ seatobj.price }}"
                        [matTooltipPosition]="'above'"
                        placement="top"
                        [ngClass]="{
                          'seat-item': true,
                          'seat-available': seatobj.status === 'available',
                          'seat-booked': seatobj.status === 'booked',
                          'seat-unavailable': seatobj.status === 'unavailable',
                          'seat-reserved': seatobj.status === 'reserved',
                          'seat-blocked': seatobj.status === 'blocked'
                        }"
                        (click)="selectSeat(seatobj)"
                        [style.margin-top.rem]="seatobj.seatMargin"
                        *ngIf="
                          seatobj.status === 'available' ||
                          seatobj.status === 'booked' ||
                          seatobj.status === 'reserved' ||
                          (seatobj.status === 'blocked' && seatobj.seatLabel !== '')
                        ">
                        {{ seatobj.seatNo }}
                      </div>

                      <div
                        matTooltip="Seat not available"
                        [matTooltipPosition]="'above'"
                        placement="top"
                        [style.margin-top.rem]="seatobj.seatMargin"
                        [ngClass]="{ 'seat-item': true, 'seat-unavailable': true }"
                        *ngIf="seatobj.status === 'unavailable' && seatobj.seatLabel !== ''">
                        {{ seatobj.seatNo }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="centered">
                  <button
                    *ngIf="selectedSeats.length > 0 && !blockedSeats && roles.includes('admin')"
                    class="blue-button"
                    mat-raised-button
                    color="primary"
                    (click)="confirmBlockSeat(this.layout, section)"
                    type="button">
                    Bloquear asiento
                  </button>
                  <button
                    *ngIf="
                      selectedSeats.length > 0 &&
                      !blockedSeats &&
                      (roles.includes('admin') || roles.includes('ventaTaquilla'))
                    "
                    class="blue-button"
                    mat-raised-button
                    color="primary"
                    (click)="buyTicketOffice(this.layout, section)"
                    type="button">
                    Compra en taquilla
                  </button>
                  <form
                    [formGroup]="createCustomPriceForm"
                    *ngIf="
                      selectedSeats.length > 0 &&
                      (roles.includes('admin') || roles.includes('ventaTaquilla'))
                    ">
                    <div class="form-group">
                      <mat-form-field appearance="standard" class="stageWidth">
                        <mat-label>Precio</mat-label>
                        <input matInput type="number" min="0" formControlName="seatCustomPrice" />
                      </mat-form-field>
                      <button
                        class="blue-button"
                        mat-raised-button
                        color="primary"
                        (click)="editPrice(this.layout, section)"
                        type="button">
                        Editar precio
                      </button>
                    </div>
                  </form>
                  <!-- <button
                    *ngIf="
                      blockedSeats && (roles.includes('admin') || roles.includes('ventaTaquilla'))
                    "
                    class="blue-button"
                    mat-raised-button
                    color="secondary"
                    (click)="printTickets()"
                    type="button">
                    Imprimir tickets
                  </button> -->
                </div>
              </div>
              <div class="view-seatmap__rowseats">
                <!-- <button
                  *ngIf="
                    selectedSeats.length > 0 &&
                    !blockedSeats &&
                    (roles.includes('admin') || roles.includes('ventaTaquilla'))
                  "
                  class="blue-button"
                  mat-raised-button
                  color="primary"
                  (click)="buyTicketOffice(this.layout, section)"
                  type="button">
                  Compra en taquilla
                </button> -->
                <button
                  class="blue-button"
                  mat-raised-button
                  (click)="gotoSections()"
                  type="button">
                  Volver
                </button>
                <!-- <button
                  mat-flat-button
                  color="success"
                  class="grey-button2"
                  (click)="confirmSeat(this.layout, section)">
                  Confirmar
                </button> -->
              </div>
            </div>
            <div class="view-seatmap__column" *ngIf="showSeatsNoGeneral === false">
              <div class="view-seatmap__rowseats">
                <div class="view-seatmap__text2">Seleccionar asientos de {{ section?.name }}</div>
                <div class="view-seatmap__text2">
                  Número de asientos disponibles {{ section?.seatsWithoutSeats }}
                </div>
                <p>Elige el número de tickets</p>
                <input
                  class="mat-input"
                  id="generalSeats"
                  name="generalSeats"
                  type="number"
                  min="0"
                  max="5"
                  style="width: 10%; border-color: black; height: 100%"
                  (input)="selectSeatGeneral($event)" />
                <button
                  *ngIf="inputValue > 0"
                  class="blue-button"
                  mat-raised-button
                  color="primary"
                  (click)="confirm()"
                  type="button">
                  Confirmar
                </button>
                <button
                  *ngIf="imprimir"
                  class="blue-button"
                  mat-raised-button
                  color="primary"
                  (click)="buyTicketOffice(this.layout, section)"
                  type="button">
                  Compra en taquilla
                </button>
              </div>
              <div class="view-seatmap__rowseats">
                <!-- <button
                  *ngIf="
                    (selectedSeats.length > 0 &&
                      (roles.includes('admin') || roles.includes('ventaTaquilla'))) ||
                    imprimir
                  "
                  class="blue-button"
                  mat-raised-button
                  color="primary"
                  (click)="buyTicketOffice(this.layout, section)"
                  type="button">
                  Compra en taquilla
                </button> -->
                <button
                  class="blue-button"
                  mat-raised-button
                  (click)="gotoSections()"
                  type="button">
                  Volver
                </button>
              </div>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="8" [rowspan]="1" *ngIf="showSeats">
          <div class="view-seatmap__text1">
            <div class="view-seatmap__row">
              <div id="circleblue"></div>
              <div class="grey-text">Disponibles</div>
              <div id="circlegrey"></div>
              <div class="grey-text">Ocupado</div>
              <div id="circlegreen"></div>
              <div class="grey-text">Seleccionado</div>
              <div id="circleyellow"></div>
              <div class="grey-text">Bloqueado</div>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-footer>

    <!-- <div class="bottom">
    <app-footer></app-footer>
  </div> -->
  </mat-card>
  <ng-template #tooltipTpl>
    <div style="background: gray">
      <img
        src="https://icons-for-free.com/download-icon-angular-1321215611022593855_256.png"
        alt="" />
      <div>This is a template!</div>
      <div>Ceci est un modèle!</div>
      <div>这是一个模板!</div>
      <div>これはテンプレートです!</div>
      <div class="text-right">هذا قالب!</div>
    </div>
  </ng-template>
</section>
