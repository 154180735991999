/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CategoriesService } from 'src/app/shared/services/categories.service'
import { FilesService } from 'src/app/shared/services/files.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  public createCategoryForm!: FormGroup
  loading = false
  submitted = false
  loadedCategory: any
  selectedFile: any
  selectedFileName: string = ''
  preview: any
  mode = 'create'
  constructor(
    private categoriesService: CategoriesService,
    private router: Router,
    private fb: FormBuilder,
    private filesService: FilesService
  ) {}

  ngOnInit(): void {
    this.createCategoryForm = this.fb.group({
      name: ['', Validators.required]
    })
    this.loadedCategory = history.state.category
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      this.setLoadedData()
    }
  }

  setLoadedData() {
    this.createCategoryForm.controls['name'].setValue(this.loadedCategory.name)
    this.selectedFileName = this.loadedCategory.icon?.url
  }

  public async onSubmit(): Promise<void> {
    if (this.createCategoryForm.valid) {
      if (this.mode === 'edit') {
        this.edit()
      } else {
        const newCategory = {
          name: this.createCategoryForm.get('name')?.value,
          icon: this.selectedFile
        }
        this.submitted = true
        ;(await this.categoriesService.create(newCategory)).subscribe({
          next: async response => {
            Swal.fire('Categoría creada correctamente', '', 'success')
            this.router.navigate(['/home/categories-list'])
          },
          complete: () => {},
          error: error => {
            console.log(error)
          }
        })
      }
    }
  }

  public async edit(): Promise<void> {
    let updatedCategory: any
    if (this.selectedFile) {
      updatedCategory = {
        name: this.createCategoryForm.get('name')?.value,
        icon: this.selectedFile
      }
    } else {
      updatedCategory = {
        name: this.createCategoryForm.get('name')?.value
      }
    }
    this.submitted = true
    ;(await this.categoriesService.updateOne(this.loadedCategory.id, updatedCategory)).subscribe({
      next: async response => {
        Swal.fire('Categoría editada correctamente', '', 'success')
        this.router.navigate(['/home/categories-list'])
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  gotoList() {
    this.router.navigate(['/home/categories-list'])
  }

  async selectFile(event: any) {
    this.selectedFile = event.target.files
    const file: File = this.selectedFile.item(0)
    ;(await this.filesService.create(file)).subscribe({
      next: async response => {
        Swal.fire('Imagen subida correctamente', '', 'success')
        this.selectedFile = response.file
        this.selectedFileName = response.file.url
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }
}
