<div class="events-list" *ngIf="roles.includes('admin')">
  <mat-card>
    <mat-card-title>Listado de eventos</mat-card-title>
    <mat-card-subtitle
      ><button mat-raised-button color="primary" (click)="gotoCreate()">
        Crear nuevo Evento
      </button></mat-card-subtitle
    >
    <mat-card-content>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="enclosure">
          <th mat-header-cell *matHeaderCellDef>Recinto</th>
          <td mat-cell *matCellDef="let element">{{ element.enclosure.name }}</td>
        </ng-container>
        <ng-container matColumnDef="organizator">
          <th mat-header-cell *matHeaderCellDef>Organizador</th>
          <td mat-cell *matCellDef="let element">{{ element.user.name }}</td>
        </ng-container>
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Categoría</th>
          <td mat-cell *matCellDef="let element">{{ element.category.name }}</td>
        </ng-container>
        <ng-container matColumnDef="initDate">
          <th mat-header-cell *matHeaderCellDef>Fecha inicio</th>
          <td mat-cell *matCellDef="let element">{{ element.initDate | date: 'dd/MM/yyyy' }}</td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>Fecha fin</th>
          <td mat-cell *matCellDef="let element">{{ element.endDate | date: 'dd/MM/yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row; let element">
            <button mat-icon-button aria-label="Visibility" (click)="gotoWatch(element)">
              <mat-icon>visibility</mat-icon>
            </button>
            <button mat-icon-button aria-label="Edit" (click)="gotoEdit(element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="cloud_download" (click)="downloadExcell(element)">
              <mat-icon>cloud_download</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete" (click)="delete(element.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
