<div class="toilets">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear aseo</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar aseo</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar aseo</mat-card-title>
    <mat-card-content>
      <form [formGroup]="createToiletForm" (ngSubmit)="onSubmit()">
        <!--<div class="form-group">
          <mat-form-field appearance="standard" class="stageWidth">
            <mat-label>Color</mat-label>
            <input type="color" matInput formControlName="color" />
            <div *ngIf="submitted && ['f.color.errors']" class="invalid-feedback">
              <div *ngIf="['f.color.errors.required']">color is required</div>
            </div>
          </mat-form-field>
        </div>-->
        <div>
          <img
            src="../../../../../assets/img/male.png"
            alt="No Image"
            width="24"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/male.png', 0)" />
          <img
            src="../../../../../assets/img/female.png"
            alt="No Image"
            width="24"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/female.png', 1)" />
          <img
            src="../../../../../assets/img/malefemale.png"
            alt="No Image"
            width="24"
            height="24"
            class="imageButton"
            (click)="imageSelected('../../../../../assets/img/malefemale.png', 2)" />
        </div>
        <!--<div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-raised-button (click)="gotoList()">Volver</button>
        </div>-->
        <div>
          <svg
            #svg
            appSvg
            (newItemEvent)="update($event)"
            style="
              border: 1px solid black;
              background-image: url('../../../../../assets/img/grid.png');
            "
            stroke="none"
            width="1000"
            height="700"
            xmlns="<http://www.w3.org/2000/svg>">
            <polygon [fill]="sectionColor" stroke="black" stroke-width="0.2"></polygon>
          </svg>
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-stroked-button class="light-button" type="button" (click)="gotoEdit()">
            Volver
          </button>
          <button mat-stroked-button class="light-button" type="button" (click)="onViewChange()">
            Limpiar
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
