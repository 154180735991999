/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Router } from '@angular/router'
import * as d3 from 'd3'
import { SvgDirective } from 'src/app/shared/directives/svg.directive'
import { SeatMapsService } from 'src/app/shared/services/seat-maps.service'
import { SessionsService } from 'src/app/shared/services/sessions.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-sessions-prices',
  templateUrl: './sessions-prices.component.html',
  styleUrls: ['./sessions-prices.component.scss']
})
export class SessionsPricesComponent implements OnInit {
  displayedColumns: string[] = ['name', 'color', 'price']
  loadedSeatMap: any
  mode = 'create'
  submitted = false
  loading = false
  sectionColor = 'grey'
  polygon: any
  loadedSession: any
  listPrices: any = []
  @ViewChild('svg', { static: true })
  svg!: SvgDirective
  constructor(
    private router: Router,
    private sessionsService: SessionsService,
    private seatMapsService: SeatMapsService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    const svg = document.querySelector('svg')

    // variable for the namespace
    const svgns = 'http://www.w3.org/2000/svg'
    this.loadedSession = history.state.session
    console.log('loadedSession: ', this.loadedSession)
    this.getData()

    /*for (let i = 0; i < this.loadedSession.seatMap.sections.length; i++) {
      this.createPolygon(
        this.loadedSession.seatMap.sections[i],
        this.loadedSession.seatMap.sections[i].name
      )
    }*/
  }

  async getData() {
    ;(await this.sessionsService.getOne(this.loadedSession.id)).subscribe({
      next: async response => {
        // Swal.fire('Sesión actualizada correctamente', '', 'success')
        this.loadedSession = response.session
        console.log('loadedSession: ', this.loadedSession)
        for (let i = 0; i < this.loadedSession.seatMap.sections.length; i++) {
          if (this.loadedSession.seatMap.sections[i].polygon[0] === 'C') {
            this.createStoredVerticalCurvedTrapezoid(
              this.loadedSession.seatMap.sections[i].polygon,
              this.loadedSession.seatMap.sections[i]?.name,
              this.loadedSession.seatMap.sections[i].color
            )
          } else if (this.loadedSession.seatMap.sections[i].polygon[0] === 'H') {
            this.createStoredCurvedTrapezoid(
              this.loadedSession.seatMap.sections[i].polygon,
              this.loadedSession.seatMap.sections[i]?.name,
              this.loadedSession.seatMap.sections[i].color
            )
          } else if (this.loadedSession.seatMap.sections[i].polygon[0] === 'R') {
            this.createCircle(
              this.loadedSession.seatMap.sections[i],
              this.loadedSession.seatMap.sections[i]?.name,
              i,
              'stage'
            )
          } else {
            this.createPolygon(
              this.loadedSession.seatMap.sections[i],
              this.loadedSession.seatMap.sections[i]?.name,
              i,
              'stage'
            )
          }

          /* this.createPolygon(
            this.loadedSession.seatMap.sections[i],
            this.loadedSession.seatMap.sections[i].name
          )*/
        }
      },
      complete: () => {
        /*  for (let i = 0; i < this.loadedSession.seatMap.sections.length; i++) {
          this.loadedSession.sectionPrices[i].color = this.loadedSession.seatMap.sections[i].color
          console.log('loadedSessionsectionprices: ', this.loadedSession.sectionsPrices)
        } */
        this.getList()
      },
      error: error => {
        console.log(error)
      }
    })
  }

  getList() {
    this.listPrices = []
    const sectionsPrices = this.loadedSession.sectionsPrices
    const list = []
    for (let i = 0; i < sectionsPrices.length; i++) {
      for (let j = 0; j < this.loadedSession.seatMap.sections.length; j++) {
        if (sectionsPrices[i].name === this.loadedSession.seatMap.sections[j].name) {
          const colorReceived = this.loadedSession.seatMap.sections[j].color
          list.push({
            name: sectionsPrices[i].name,
            color: colorReceived,
            price: sectionsPrices[i].price
          })
          break
        }
      }
    }
    this.listPrices = list
    console.log('listPrices: ', this.listPrices)
  }

  gotoList() {
    this.router.navigate(['/home/event-details'], {
      state: { mode: 'edit', event: this.loadedSession.event }
    })
  }

  update($event: any) {
    this.polygon = $event
  }

  private createPolygon(sector: any, name: string, index: number, type: string) {
    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', sector.polygon)
      .attr('fill', sector.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  private createCircle(sector: any, name: string, index: number, type: string) {
    let newStr = sector.polygon.slice(1)
    let polygon = newStr.split(',')
    //d3.select('#patata').remove()
    d3.selectAll('circle').remove()
    d3.select(document.querySelector('svg'))
      .attr('fill', sector.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
      .append('circle')
      .style('stroke', 'gray')
      .attr('r', polygon[0])
      .attr('cx', polygon[1])
      .attr('cy', polygon[2])
  }

  public createStoredVerticalCurvedTrapezoid(path: string, name: string, color: string) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width / 2
    }
    if (Number.isNaN(k2)) {
      k2 = width / 2
    }
    const k = (x0 + width + k1) / 2
    const kb = (x0 + width + k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          k +
          ',' +
          (y0 + cp) +
          ' ' +
          (x0 + width + hlo) +
          ',' +
          (y0 + vro) +
          'L ' +
          (x0 + width - ro) +
          ',' +
          (y0 + height + hro) +
          ' Q ' +
          kb +
          ',' +
          (y0 + height + cp2) +
          ' ' +
          (x0 + lo) +
          ',' +
          (y0 + height + vlo) +
          ' Z'
        )
      })
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public createStoredCurvedTrapezoid(path: string, name: string, color: string) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp)) {
      cp = 100
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width
    }
    if (Number.isNaN(k2)) {
      k2 = width
    }
    const k = (x0 + width - k1) / 2
    const kb = (x0 + width - k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()

    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          (x0 + cp) +
          ',' +
          k +
          ' ' +
          (x0 + hro) +
          ',' +
          (y0 + height + hlo) +
          'L ' +
          (x0 - width - ro) +
          ',' +
          (y0 + height + vro) +
          ' Q ' +
          (x0 + cp2 - width) +
          ',' +
          kb +
          ' ' +
          (x0 - width - lo) +
          ',' +
          (y0 + lo + vlo) +
          ' Z'
        )
      })
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }

  public async save(): Promise<void> {
    this.listPrices.forEach((element: { price: string }) => {
      element.price = element.price.replace(',', '.')
    })
    const newSessions = {
      sectionsPrices: this.listPrices
    }
    console.log('newSessions: ', newSessions)
    ;(await this.sessionsService.updateOne(this.loadedSession.id, newSessions)).subscribe({
      next: async response => {
        console.log('resp: ', response)
        Swal.fire('Sesión actualizada correctamente', '', 'success')
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }
}
