<div class="users">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear nuevo usuario organizador</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar usuario organizador</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar usuario organizador</mat-card-title>
    <mat-card-content>
      <div>
        <form [formGroup]="typeForm">
          <mat-radio-group aria-label="" (change)="radioChange($event)" formControlName="type">
            <mat-radio-button [value]="'Empresa'">Empresa</mat-radio-button>
            <mat-radio-button [value]="'Particular'">Particular</mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
      <form [formGroup]="createBusinessUserForm" (ngSubmit)="onBusinessSubmit()" *ngIf="isBusiness">
        <mat-card-subtitle *ngIf="mode === 'create'">Nuevo Organizador (Empresa)</mat-card-subtitle>
        <mat-card-subtitle *ngIf="mode === 'edit'">Editar Organizador (Empresa)</mat-card-subtitle>
        <mat-card-subtitle *ngIf="mode === 'watch'"
          >Revisar Organizador (Empresa)</mat-card-subtitle
        >
        <div class="form-group">
          <mat-form-field appearance="standard" class="fullWidth">
            <mat-label>Nombre de la empresa</mat-label>
            <input matInput formControlName="name" />
            <div *ngIf="submitted && ['f.name.errors']" class="invalid-feedback">
              <div *ngIf="['f.name.errors.required']">name is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="fullWidth">
            <mat-label>Descripción</mat-label>
            <input matInput formControlName="businessName" />
            <div *ngIf="submitted && ['f.businessName.errors']" class="invalid-feedback">
              <div *ngIf="['f.businessName.errors.required']">businessName is required</div>
            </div>
          </mat-form-field>
        </div>
        <mat-card-subtitle>Datos de la empresa</mat-card-subtitle>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
            <div *ngIf="submitted && ['f.email.errors']" class="invalid-feedback">
              <div *ngIf="['f.email.errors.required']">email is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="password" />
            <div *ngIf="submitted && ['f.password.errors']" class="invalid-feedback">
              <div *ngIf="['f.password.errors.required']">password is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>CIF</mat-label>
            <input matInput formControlName="cif" />
            <div *ngIf="submitted && ['f.cif.errors']" class="invalid-feedback">
              <div *ngIf="['f.cif.errors.required']">cif is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Teléfono</mat-label>
            <input matInput formControlName="phone" />
            <div *ngIf="submitted && ['f.phone.errors']" class="invalid-feedback">
              <div *ngIf="['f.phone.errors.required']">phone is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Nombre de contacto</mat-label>
            <input matInput formControlName="surname1" />
            <div *ngIf="submitted && ['f.surname1.errors']" class="invalid-feedback">
              <div *ngIf="['f.surname1.errors.required']">organizador is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Sitio web</mat-label>
            <input matInput formControlName="web" />
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field class="halfWidth">
            <mat-label *ngIf="selectedFile; else newFile">{{
              selectedFile.item(0).name
            }}</mat-label>
            <ng-template #newFile>
              <mat-label>Elija imagen</mat-label>
            </ng-template>
            <input matInput disabled />
            <button type="button" mat-icon-button matSuffix (click)="fileInput.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input
              hidden
              (change)="selectFile($event)"
              #fileInput
              type="file"
              id="file"
              accept="image/png, image/jpeg" />
          </mat-form-field>
          <div>
            <img [src]="selectedFileName" class="preview" />
          </div>
        </div>
        <mat-card-subtitle>Dirección</mat-card-subtitle>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Dirección</mat-label>
            <input matInput formControlName="address" />
          </mat-form-field>
          <div *ngIf="submitted && ['f.address.errors']" class="invalid-feedback">
            <div *ngIf="['f.address.errors.required']">address is required</div>
          </div>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>Número</mat-label>
            <input matInput formControlName="number" />
            <div *ngIf="submitted && ['f.number.errors']" class="invalid-feedback">
              <div *ngIf="['f.number.errors.required']">number is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>Piso</mat-label>
            <input matInput formControlName="floor" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>CP</mat-label>
            <input matInput formControlName="zip" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>Ciudad</mat-label>
            <input matInput formControlName="city" />
            <div *ngIf="submitted && ['f.city.errors']" class="invalid-feedback">
              <div *ngIf="['f.city.errors.required']">city is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-raised-button (click)="gotoList()">Volver</button>
        </div>
      </form>
      <form [formGroup]="createUserForm" (ngSubmit)="onSubmit()" *ngIf="!isBusiness">
        <mat-card-subtitle *ngIf="mode === 'create'"
          >Nuevo Organizador (Particular)</mat-card-subtitle
        >
        <mat-card-subtitle *ngIf="mode === 'edit'"
          >Editar Organizador (Particular)</mat-card-subtitle
        >
        <mat-card-subtitle *ngIf="mode === 'watch'"
          >Revisar Organizador (Particular)</mat-card-subtitle
        >
        <div class="form-group">
          <mat-form-field appearance="standard" class="fullWidth">
            <mat-label>Nombre del organizador</mat-label>
            <input matInput formControlName="name" />
            <div *ngIf="submitted && ['f.name.errors']" class="invalid-feedback">
              <div *ngIf="['f.name.errors.required']">name is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Apellido 1</mat-label>
            <input matInput formControlName="surname1" />
            <div *ngIf="submitted && ['f.surname1.errors']" class="invalid-feedback">
              <div *ngIf="['f.surname1.errors.required']">surname1 is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Apellido 2</mat-label>
            <input matInput formControlName="surname2" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="fullWidth">
            <mat-label>Descripción</mat-label>
            <input matInput formControlName="businessName" />
            <div *ngIf="submitted && ['f.businessName.errors']" class="invalid-feedback">
              <div *ngIf="['f.businessName.errors.required']">businessName is required</div>
            </div>
          </mat-form-field>
        </div>
        <mat-card-subtitle>Datos del particular Organizador de eventos</mat-card-subtitle>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
            <div *ngIf="submitted && ['f.email.errors']" class="invalid-feedback">
              <div *ngIf="['f.email.errors.required']">email is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="password" />
            <div *ngIf="submitted && ['f.password.errors']" class="invalid-feedback">
              <div *ngIf="['f.password.errors.required']">password is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>DNI</mat-label>
            <input matInput formControlName="cif" />
            <div *ngIf="submitted && ['f.cif.errors']" class="invalid-feedback">
              <div *ngIf="['f.cif.errors.required']">cif is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Teléfono</mat-label>
            <input matInput formControlName="phone" />
            <div *ngIf="submitted && ['f.phone.errors']" class="invalid-feedback">
              <div *ngIf="['f.phone.errors.required']">phone is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Sitio web</mat-label>
            <input matInput formControlName="web" />
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field class="halfWidth">
            <mat-label *ngIf="selectedFile; else newFile">{{
              selectedFile.item(0).name
            }}</mat-label>
            <ng-template #newFile>
              <mat-label>Elija imagen</mat-label>
            </ng-template>
            <input matInput disabled />
            <button type="button" mat-icon-button matSuffix (click)="fileInput.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input
              hidden
              (change)="selectFile($event)"
              #fileInput
              type="file"
              id="file"
              accept="image/png, image/jpeg" />
          </mat-form-field>
          <div>
            <img [src]="selectedFileName" class="preview" />
          </div>
        </div>
        <mat-card-subtitle>Dirección</mat-card-subtitle>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Dirección</mat-label>
            <input matInput formControlName="address" />
          </mat-form-field>
          <div *ngIf="submitted && ['f.address.errors']" class="invalid-feedback">
            <div *ngIf="['f.address.errors.required']">address is required</div>
          </div>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>Número</mat-label>
            <input matInput formControlName="number" />
            <div *ngIf="submitted && ['f.number.errors']" class="invalid-feedback">
              <div *ngIf="['f.number.errors.required']">number is required</div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>Piso</mat-label>
            <input matInput formControlName="floor" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>CP</mat-label>
            <input matInput formControlName="zip" />
          </mat-form-field>
          <mat-form-field appearance="standard" class="quarterWidth">
            <mat-label>Ciudad</mat-label>
            <input matInput formControlName="city" />
            <div *ngIf="submitted && ['f.city.errors']" class="invalid-feedback">
              <div *ngIf="['f.city.errors.required']">city is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-raised-button (click)="gotoList()">Volver</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
