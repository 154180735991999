<div class="seats-general1"></div>
<div class="seats-general">
  <mat-card (click)="gotoEdit()" class="clickable">
    <mat-card-title>Datos de mapa de asientos {{ loadedseatMap.name }}</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content></mat-card-content
  ></mat-card>
</div>
<div class="seats-general">
  <mat-card
    (click)="createStage('create')"
    class="clickable"
    *ngIf="loadedseatMap.stage.length === 0">
    <mat-card-title>Escenario</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content> Aún no hay escenario creado</mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-footer>
      <button mat-raised-button color="primary" (click)="createStage('create')">
        Crear nuevo escenario
      </button></mat-card-footer
    ></mat-card
  >

  <mat-card *ngIf="loadedseatMap.stage.length > 0">
    <mat-card-title>Escenario</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div *ngFor="let item of loadedseatMap.stage; let i = index">
        <button mat-raised-button [ngStyle]="{ 'background-color': item.color }">
          {{ item.name }}
        </button>
        <button mat-icon-button aria-label="edit" (click)="editStage(i); $event.stopPropagation()">
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="Delete"
          (click)="delete('stage', i); $event.stopPropagation()">
          <mat-icon>delete</mat-icon>
        </button>
      </div></mat-card-content
    ><mat-divider></mat-divider>
    <mat-card-footer>
      <button mat-raised-button color="primary" (click)="createStage('create')">
        Crear nuevo escenario
      </button></mat-card-footer
    ></mat-card
  >
</div>

<div class="seats-general">
  <mat-card
    (click)="createSection('create')"
    class="clickable"
    *ngIf="loadedseatMap.sections.length === 0">
    <mat-card-title>Secciones</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content> Aún no hay secciones creadas</mat-card-content></mat-card
  >
  <mat-card *ngIf="loadedseatMap.sections.length > 0">
    <mat-card-title>Secciones</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div *ngFor="let item of loadedseatMap.sections; let i = index">
        <button mat-raised-button [ngStyle]="{ 'background-color': item.color }">
          {{ item.name }}
        </button>
        <button
          mat-icon-button
          aria-label="edit"
          (click)="editSection(i); $event.stopPropagation()">
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="event_seat"
          (click)="editSeatMap(i); $event.stopPropagation()">
          <mat-icon>event_seat</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="Delete"
          (click)="delete('sections', i); $event.stopPropagation()">
          <mat-icon>delete</mat-icon>
        </button>
      </div></mat-card-content
    ><mat-divider></mat-divider>
    <mat-card-footer>
      <button mat-raised-button color="primary" (click)="createSection('create')">
        Crear nueva sección
      </button>
    </mat-card-footer></mat-card
  >
</div>
<div class="seats-general">
  <mat-card *ngIf="loadedseatMap.access.length === 0">
    <mat-card-title>Accesos</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content> Aún no hay accesos creados</mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-footer>
      <button mat-raised-button color="primary" (click)="createAccess('create')">
        Crear nuevo acceso
      </button>
    </mat-card-footer></mat-card
  >
  <mat-card
    (click)="createAccess('create')"
    class="clickable"
    *ngIf="loadedseatMap.access.length > 0">
    <mat-card-title>Accesos</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div *ngFor="let item of loadedseatMap.access; let i = index">
        <button mat-raised-button [ngStyle]="{ 'background-color': item.color }">
          Acceso {{ i }}
        </button>
        <button mat-icon-button aria-label="edit" (click)="editAccess(i); $event.stopPropagation()">
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="Delete"
          (click)="delete('access', i); $event.stopPropagation()">
          <mat-icon>delete</mat-icon>
        </button>
      </div></mat-card-content
    >
    <mat-divider></mat-divider>
    <mat-card-footer>
      <button mat-raised-button color="primary" (click)="createAccess('create')">
        Crear nuevo acceso
      </button>
    </mat-card-footer></mat-card
  >
</div>
<div class="seats-general">
  <mat-card *ngIf="loadedseatMap.toilets.length === 0">
    <mat-card-title>Aseos</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content> Aún no hay aseos creados</mat-card-content>
    <mat-divider></mat-divider
    ><mat-card-footer>
      <button mat-raised-button color="primary" (click)="createToilet('create')">
        Crear nuevo aseo
      </button>
    </mat-card-footer></mat-card
  >
  <mat-card *ngIf="loadedseatMap.toilets.length > 0">
    <mat-card-title>Aseos</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div *ngFor="let item of loadedseatMap.toilets; let i = index">
        <button mat-raised-button [ngStyle]="{ 'background-color': item.color }">
          Aseo {{ i }}
        </button>
        <button mat-icon-button aria-label="edit" (click)="editToilet(i); $event.stopPropagation()">
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="Delete"
          (click)="delete('toilet', i); $event.stopPropagation()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider
      ><mat-card-footer>
        <button mat-raised-button color="primary" (click)="createToilet('create')">
          Crear nuevo aseo
        </button>
      </mat-card-footer></mat-card-content
    ></mat-card
  >
</div>
<div class="seats-general3">
  <button mat-raised-button color="primary" (click)="gotoList()">Cancelar</button>
</div>
