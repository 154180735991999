/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { PerpetualService } from 'src/app/shared/services/perpetual.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-event-perpetual-sessions-list',
  templateUrl: './event-perpetual-sessions-list.component.html',
  styleUrls: ['./event-perpetual-sessions-list.component.scss']
})
export class EventPerpetualSessionsListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'description', 'price', 'actions']
  perpetualList: any
  loadedEvent: any
  mode: any
  @ViewChild(MatPaginator)
  paginator!: MatPaginator
  constructor(private perpetualService: PerpetualService, private router: Router) {}

  ngOnInit(): void {
    this.loadedEvent = history.state.event
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    this.getPerpetuals()
  }

  async getPerpetuals() {
    ;(await this.perpetualService.getAllbyEventId(this.loadedEvent.id)).subscribe({
      next: async (response: any) => {
        this.perpetualList = new MatTableDataSource(response.perpetualSessions)
        this.perpetualList.paginator = this.paginator
      },
      complete: () => {},
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  delete(id: string) {
    Swal.fire({
      title: '¿Quieres borrar la sesión?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        ;(await this.perpetualService.delete(id)).subscribe({
          next: async (response: any) => {
            Swal.fire('Sesión borrada correctamente', '', 'success')
            this.getPerpetuals()
          },
          complete: () => {},
          error: (error: any) => {
            Swal.fire('Error, no se ha podido borrar la sesión', '', 'error')
          }
        })
      }
    })
  }
  gotoCreate() {
    this.router.navigate(['/home/perpetual-sessions-details'], {
      state: { mode: 'create', event: this.loadedEvent }
    })
  }

  gotoEdit(perpetual: any) {
    this.router.navigate(['/home/perpetual-sessions-details'], {
      state: { mode: 'edit', event: this.loadedEvent, perpetual: perpetual }
    })
  }

  gotoWatch(perpetual: any) {
    this.router.navigate(['/home/perpetual-sessions-details'], {
      state: { mode: 'watch', event: this.loadedEvent, perpetual: perpetual }
    })
  }
}
