import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AccountService } from 'src/app/shared/services/account.service'
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class BannersService {
  constructor(private _http: HttpClient, private accountService: AccountService) {}

  async create(banner: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.post(environment.apiUrl + '/banner', banner, { headers })
  }

  async getAll(): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/banner', { headers })
  }

  async getOne(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/banner/' + id, { headers })
  }

  async getConflicts(
    homeTop: boolean,
    homeDown: boolean,
    eventTop: boolean,
    envenDown: boolean
  ): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    const body = {
      homeTop,
      homeDown,
      eventTop,
      envenDown
    }

    return this._http.post(environment.apiUrl + '/banner/findconflicts', body, { headers })
  }

  async updateOne(id: string, banner: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    console.log('banner: ', banner)
    return this._http.put(environment.apiUrl + '/banner/' + id, banner, { headers })
  }

  async delete(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.delete(environment.apiUrl + '/banner/' + id, { headers })
  }
}
