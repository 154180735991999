<div class="events">
  <mat-card>
    <mat-card-title *ngIf="mode === 'create'">Crear nuevo Evento</mat-card-title>
    <mat-card-title *ngIf="mode === 'edit'">Editar Evento</mat-card-title>
    <mat-card-title *ngIf="mode === 'watch'">Revisar Evento</mat-card-title>
    <mat-card-subtitle>Información general</mat-card-subtitle>
    <mat-card-content>
      <form [formGroup]="createEventForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <mat-form-field class="eventName" appearance="standard">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" />
            <div *ngIf="submitted && ['f.name.errors']" class="invalid-feedback">
              <div *ngIf="['f.name.errors.required']">name is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="eventDescription">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" />
            <div *ngIf="submitted && ['f.description.errors']" class="invalid-feedback">
              <div *ngIf="['f.description.errors.required']">Description is required</div>
            </div>
          </mat-form-field>
        </div>
        <mat-card-subtitle>Datos del evento</mat-card-subtitle>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Fecha de inicio</mat-label>
            <input matInput [matDatepicker]="initDatePicker" formControlName="initDate" />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="initDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #initDatePicker></mat-datepicker>
          </mat-form-field>
          <div *ngIf="submitted && ['f.initDate.errors']" class="invalid-feedback">
            <div *ngIf="['f.initDate.errors.required']">initDate is required</div>
          </div>
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Fecha de finalización</mat-label>
            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
          <div *ngIf="submitted && ['f.endDate.errors']" class="invalid-feedback">
            <div *ngIf="['f.endDate.errors.required']">endDate is required</div>
          </div>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill" class="select">
            <mat-label>Elija categoría</mat-label>
            <mat-select
              formControlName="category"
              (selectionChange)="onCategoryChange($event)"
              [compareWith]="compareFn">
              <mat-option *ngFor="let option of categoriesList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="select">
            <mat-label>Elija subcategoría</mat-label>
            <mat-select formControlName="subcategory" [compareWith]="compareFn">
              <mat-option *ngFor="let option of subCategoriesList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill" class="select">
            <mat-label>Elija recinto</mat-label>
            <mat-select formControlName="enclosure" [compareWith]="compareFn">
              <mat-option *ngFor="let option of enclosuresList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="select">
            <mat-label>Elija usuario organizador</mat-label>
            <mat-select formControlName="user" [compareWith]="compareFn">
              <mat-option *ngFor="let option of userList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill" class="select">
            <mat-label>Usuarios validadores</mat-label>
            <mat-select formControlName="validator" [compareWith]="compareFn" multiple>
              <mat-option *ngFor="let option of validatorsList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="select">
            <mat-label>Usuarios taquilleros</mat-label>
            <mat-select formControlName="taquillero" [compareWith]="compareFn" multiple>
              <mat-option *ngFor="let option of taquillerosList" [value]="option">{{
                option.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="standard" class="halfWidth">
            <mat-label>Gastos de gestión</mat-label>
            <input type="number" matInput formControlName="administrationFees" min="0" />
            <div *ngIf="submitted && ['f.administrationFees.errors']" class="invalid-feedback">
              <div *ngIf="['f.administrationFees.errors.required']">
                administrationFees is required
              </div>
            </div>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="halfWidth">
            <mat-label *ngIf="selectedFile; else newFile">{{
              selectedFile.item(0).name
            }}</mat-label>
            <ng-template #newFile>
              <mat-label>Elija imagen</mat-label>
            </ng-template>
            <input matInput disabled />
            <button type="button" mat-icon-button matSuffix (click)="fileInput.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input
              hidden
              (change)="selectFile($event)"
              #fileInput
              type="file"
              id="file"
              accept="image/png, image/jpeg" />
          </mat-form-field>
          <!--<mat-form-field class="halfWidth">
            <mat-label *ngIf="selectedFile; else newPdf">{{ selectedFile.item(0).name }}</mat-label>
            <ng-template #newPdf>
              <mat-label>Elija programa</mat-label>
            </ng-template>
            <input matInput disabled />
            <button type="button" mat-icon-button matSuffix (click)="pdfInput.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input
              hidden
              (change)="selectFile($event)"
              #pdfInput
              type="file"
              id="file"
              accept="application/pdf" />
          </mat-form-field>-->
        </div>
        <div>
          <img [src]="selectedFileName" class="preview" />
        </div>
        <div class="form-group">
          <mat-form-field class="halfWidth">
            <mat-label *ngIf="selectedProgram; else newProgram">{{
              selectedProgram.item(0).name
            }}</mat-label>
            <ng-template #newProgram>
              <mat-label>Elija programa</mat-label>
            </ng-template>
            <input matInput disabled />
            <button type="button" mat-icon-button matSuffix (click)="programInput.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input hidden (change)="selectProgram($event)" #programInput type="file" id="file" />
          </mat-form-field>
          <div>
            <img [src]="selectedProgramName" class="preview" />
          </div>
        </div>
        <div class="form-group-buttons">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'edit'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Editar
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="loading"
            class="actionButton"
            *ngIf="mode === 'create'">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Guardar
          </button>
          <button mat-raised-button (click)="gotoList()">Volver</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
