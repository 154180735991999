import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { EnclosuresListComponent } from 'src/app/modules/home/components/enclosures-list/enclosures-list.component'
import { EnclosuresComponent } from 'src/app/modules/home/components/enclosures/enclosures.component'
import { UsersListComponent } from 'src/app/modules/home/components/users-list/users-list.component'
import { UsersComponent } from 'src/app/modules/home/components/users/users.component'
import { BannersListComponent } from './components/banners-list/banners-list.component'
import { BannersComponent } from './components/banners/banners.component'
import { CategoriesListComponent } from './components/categories-list/categories-list.component'
import { CategoriesComponent } from './components/categories/categories.component'
import { DiscountsRatesComponent } from './components/discounts-rates/discounts-rates.component'
import { EntradasComponent } from './components/entradas/entradas.component'
import { EventSessionsDetailsComponent } from './components/event-sessions-details/event-sessions-details.component'
import { EventSessionsSectiongridsComponent } from './components/event-sessions-sectiongrids/event-sessions-sectiongrids.component'
import { EventSessionsComponent } from './components/event-sessions/event-sessions.component'
import { EventPerpetualSessionsDetailsComponent } from './components/event-perpetual-sessions-details/event-perpetual-sessions-details.component'
import { EventPerpetualSessionsListComponent } from './components/event-perpetual-sessions-list/event-perpetual-sessions-list.component'
import { EventsDetailComponent } from './components/events-detail/events-detail.component'
import { EventsListComponent } from './components/events-list/events-list.component'
import { EventsComponent } from './components/events/events.component'
import { LectorQrComponent } from './components/lector-qr/lector-qr.component'
import { SeatMapListComponent } from './components/seat-map-list/seat-map-list.component'
import { SeatMapsAccessComponent } from './components/seat-maps-access/seat-maps-access.component'
import { SeatMapsGeneralComponent } from './components/seat-maps-general/seat-maps-general.component'
import { SeatMapsNewComponent } from './components/seat-maps-new/seat-maps-new.component'
import { SeatMapsSectionsComponent } from './components/seat-maps-sections/seat-maps-sections.component'
import { SeatMapsStageComponent } from './components/seat-maps-stage/seat-maps-stage.component'
import { SeatMapsToiletsComponent } from './components/seat-maps-toilets/seat-maps-toilets.component'
import { SeatsEditorComponent } from './components/seats-editor/seats-editor.component'
import { SessionsPricesComponent } from './components/sessions-prices/sessions-prices.component'
import { SubcategoriesListComponent } from './components/subcategories-list/subcategories-list.component'
import { SubcategoriesComponent } from './components/subcategories/subcategories.component'
import { ZonesComponent } from './components/zones/zones.component'
import { HomeComponent } from './home.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'enclosures',
        component: EnclosuresComponent
      },
      {
        path: 'enclosures-list',
        component: EnclosuresListComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'users-list',
        component: UsersListComponent
      },
      {
        path: 'categories-list',
        component: CategoriesListComponent
      },
      {
        path: 'categories',
        component: CategoriesComponent
      },
      {
        path: 'subcategories-list',
        component: SubcategoriesListComponent
      },
      {
        path: 'subcategories',
        component: SubcategoriesComponent
      },
      {
        path: 'events-list',
        component: EventsListComponent
      },
      {
        path: 'events',
        component: EventsComponent
      },
      {
        path: 'event-details',
        component: EventsDetailComponent
      },
      {
        path: 'event-sessions',
        component: EventSessionsComponent
      },
      {
        path: 'event-sessions-details',
        component: EventSessionsDetailsComponent
      },
      {
        path: 'event-sessions-sectiongrids',
        component: EventSessionsSectiongridsComponent
      },
      {
        path: 'seat-map-list',
        component: SeatMapListComponent
      },
      {
        path: 'seat-map-access',
        component: SeatMapsAccessComponent
      },
      {
        path: 'seat-map-general',
        component: SeatMapsGeneralComponent
      },
      {
        path: 'seat-map-new',
        component: SeatMapsNewComponent
      },
      {
        path: 'seat-map-sections',
        component: SeatMapsSectionsComponent
      },
      {
        path: 'seat-map-stage',
        component: SeatMapsStageComponent
      },
      {
        path: 'seat-map-toilets',
        component: SeatMapsToiletsComponent
      },
      {
        path: 'discounts-rates',
        component: DiscountsRatesComponent
      },
      {
        path: 'zones',
        component: ZonesComponent
      },
      {
        path: 'session-prices',
        component: SessionsPricesComponent
      },
      {
        path: 'seats-editor',
        component: SeatsEditorComponent
      },
      {
        path: 'banners',
        component: BannersComponent
      },
      {
        path: 'banners-list',
        component: BannersListComponent
      },
      {
        path: 'entradas',
        component: EntradasComponent
      },
      {
        path: 'lector-qr',
        component: LectorQrComponent
      },
      {
        path: 'perpetual-sessions-details',
        component: EventPerpetualSessionsDetailsComponent
      },
      {
        path: 'perpetual-list',
        component: EventPerpetualSessionsListComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
