import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import * as d3 from 'd3'
import { firstValueFrom } from 'rxjs'
import { access, toilets } from 'src/app/shared/constants/signs'
import { AccountService } from 'src/app/shared/services/account.service'
import { EventsService } from 'src/app/shared/services/events.service'
import { FilesService } from 'src/app/shared/services/files.service'
import { OrdersService } from 'src/app/shared/services/orders.service'
import { PdfService } from 'src/app/shared/services/pdf.service'
import { SeatMapsService } from 'src/app/shared/services/seat-maps.service'
import { SectionsPricesService } from 'src/app/shared/services/sectionPrices.service'
import { SessionsService } from 'src/app/shared/services/sessions.service'
import { TicketsService } from 'src/app/shared/services/tickets.service'
import { environment } from 'src/environments/environment'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-event-sessions-sectiongrids',
  templateUrl: './event-sessions-sectiongrids.component.html',
  styleUrls: ['./event-sessions-sectiongrids.component.scss']
})
export class EventSessionsSectiongridsComponent implements OnInit {
  public event: any
  public createCustomPriceForm!: FormGroup
  //public featuredEvents = FAKE_EVENTS
  public session1: any
  public eventReceived: any
  public seatsRows: any
  public rows: any
  public user: any
  public order: any
  public featuredEvents: Array<any> = []
  public billingForm: FormGroup | undefined
  public discount: FormGroup | undefined
  //public readonly APP_IMAGES = APP_IMAGES
  public seatChartConfig = {
    showRowsLabel: true,
    showRowWisePricing: true,
    newSeatNoForRow: true
  }
  layout: any
  layouts2Update: any = []
  showSeats: boolean = false
  showSeatsNoGeneral = true
  showSectionsFilter: boolean = false
  showPricesFilter: boolean = false
  totalPrice: number = 0
  interval: any
  timeLeft: number = 300
  minutes: number = 5
  seconds: number | string = '00'
  discountCode: boolean = false
  discountValue = 0
  billingGestionValue = 0
  timeInterval: string | undefined
  session: any
  sessionId: string = ''
  weekDay: string | undefined
  weekDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
  weekday = 'Domingo'
  ocupation: number = 0
  selectedSeats: any = []
  confirmedSeats: boolean = false
  public generalSeats = 0
  public inputValue: number = 0
  public imprimir: boolean = false

  public createSectionForm!: FormGroup
  public createSeatsForm!: FormGroup
  public points: Array<[number, number]> = []
  loadedSeatMap: any
  seatConfig: any
  index = 0
  mode = 'watch'
  sectionColor = '#FFFFFF'
  polygon: any
  submitted = false
  loading = false
  view = false
  section: any
  sections: any = []
  sectionsPol: any = []
  sectionsPath: any = []
  sectionsC: any = []
  sectionsI: any = []
  sectionPrices: any = []
  andrew = '200,10 250,190 160,210'
  color = '#000000'
  colorProgressBar = '#748CC5'
  colorTicketernal = '#5db2e4'
  confirmed: boolean = false
  formatLabel: any
  sections2Filter: any = []
  oldSections: any = []
  maxPrice: number = 0
  minPrice: number = 0
  price2Search: number = 0
  numberOfPrices: any
  oldColor: any
  loadedEvent: any
  tickets: any = []
  userAnonymous = ''
  ticket!: {
    price: any
    session: any
    event: any
    order: any
    typeTicket: string
    fila: any
    butaca: any
    section: string
  }
  ticket1!: {
    price: any
    session: any
    event: any
    order: any
    typeTicket: string
    fila: any
    butaca: any
    section: string
    date: string
  }
  blockedSeats: boolean = false
  pdfLoading: boolean = false
  seatMap: any
  priceSection = 0
  roles: any
  imageSeat: any

  constructor(
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public eventsService: EventsService,
    private userService: AccountService,
    private seatMapService: SeatMapsService,
    private sessionsService: SessionsService,
    private sectionPriceService: SectionsPricesService,
    private ordersService: OrdersService,
    private ticketsService: TicketsService,
    private pdfService: PdfService,
    private fb: FormBuilder,
    private router: Router,
    private fileService: FilesService
  ) {}

  public async ngOnInit() {
    console.log('REINICIO')
    /* this.activatedRoute.queryParams.subscribe(params => {
      this.sessionId = JSON.parse(params['session'])
    }) */
    //this.sessionId = history.state.session.id
    this.getRoles()
    this.loadedEvent = history.state.event
    this.eventReceived = history.state.event
    this.mode = history.state.mode
    this.sections = []
    this.sectionsPol = []
    this.sectionsPath = []
    this.sectionsC = []
    this.sectionsI = []
    await this.getSession()
    //this.session = history.state.session
    const svg = document.querySelector('svg')
    /*if (svg) {
      svg.addEventListener('click', async (e: any) => {
        console.log(e.target)
        await this.showSection(await this.getSection(e.target.id))
      })
    }*/
    // variable for the namespace
    //const svgns = 'http://www.w3.org/2000/svg'
    this.createSectionForm = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required]
    })
    this.createSeatsForm = this.fb.group({
      rows: ['', Validators.required],
      cols: ['', Validators.required],
      number: ['', Validators.required],
      curve: [0, Validators.required]
    })
    this.createCustomPriceForm = this.fb.group({
      seatCustomPrice: [0]
    })
    this.setLoadedData()
  }

  setLoadedData() {
    this.createSectionForm.controls['name'].setValue(this.loadedSeatMap?.name)
    this.createSectionForm.controls['color'].setValue(this.loadedSeatMap?.color)
  }

  public async getSection(sectionName: string) {
    this.section = await this.sections.find(
      (section: { name: string }) => section.name === sectionName
    )
    await this.getPriceFromSectionPrice(sectionName)
    return this.section
  }

  async getPriceFromSectionPrice(name: string) {
    const sectionPrices2show = this.session.sectionsPrices
    if (sectionPrices2show.length > 0) {
      for (const sectionPrice of sectionPrices2show) {
        if (sectionPrice.name === name) {
          this.priceSection = sectionPrice.price
          console.log(999, this.priceSection)
        }
      }
    }
  }

  public async getSession() {
    firstValueFrom(await this.sessionsService.getOne(history.state.session.id)).then(
      async response => {
        this.session = response.session
        console.log(11, response.session)
        this.session.date = this.formatSessionDate(this.session)
        console.log(22, this.session.date)
        this.loadedSeatMap = this.session.seatMap
        this.seatMap = this.session.seatMap
        console.log('sesion: ', this.session)
        await this.getSeatMapInfo()
      }
    )
  }

  private async getEventInfo() {
    const eventId = this.loadedEvent.id
    //this.eventReceived = history.state.event
    const evento = this.eventReceived
    const event2Show = {
      id: evento.id,
      imageUrl: evento.picture?.url || '',
      headerUrl: evento.picture?.url || '',
      title: evento.name,
      dateText: new Date(evento.initDate).toLocaleDateString('es-ES', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }),
      date: new Date(evento.initDate).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }),
      time: evento.initTime,
      place: evento.enclosure.name,
      city: evento.enclosure.address.city,
      address: evento.enclosure.address.address,
      priceFrom: 10,
      tags: evento.tags,
      description: evento.description,
      cp: evento.enclosure.address.zip,
      sessions: evento.sessions
    }
    this.event = event2Show
  }

  private async getSeatMapInfo() {
    if (this.seatMap.stage.length > 0) {
      for (let i = 0; i < this.seatMap.stage.length; i++) {
        if (this.seatMap.stage[i].polygon[0] === 'C') {
          this.createStoredVerticalCurvedTrapezoid(
            this.seatMap.stage[i].polygon,
            this.seatMap.stage[i]?.name,
            this.seatMap.stage[i].color,
            this.seatMap.stage[i],
            'stage'
          )
        } else if (this.seatMap.stage[i].polygon[0] === 'H') {
          this.createStoredCurvedTrapezoid(
            this.seatMap.stage[i].polygon,
            this.seatMap.stage[i]?.name,
            this.seatMap.stage[i].color,
            this.seatMap.stage[i],
            'stage'
          )
        } else if (this.seatMap.stage[i].polygon[0] === 'R') {
          this.createCircle(this.seatMap.stage[i], this.seatMap.stage[i]?.name, i, 'stage')
        } else {
          this.createPolygon(this.seatMap.stage[i], this.seatMap.stage[i]?.name, i, 'stage')
        }
      }
    }
    if (this.seatMap.toilets.length > 0) {
      for (let i = 0; i < this.seatMap.toilets.length; i++) {
        console.log('this.seatMap.toilets[i]: ', this.seatMap.toilets[i])
        this.createToilet(this.seatMap.toilets[i], 'Aseo' + i)
      }
    }
    if (this.seatMap.access.length > 0) {
      for (let i = 0; i < this.seatMap.access.length; i++) {
        console.log('this.seatMap.access[i]: ', this.seatMap.access[i])
        this.createAccess(this.seatMap.access[i], 'Acceso' + i)
      }
    }
    if (this.seatMap.sections.length > 0) {
      for (let i = 0; i < this.seatMap.sections.length; i++) {
        if (this.seatMap.sections[i].polygon[0] === 'C') {
          this.createStoredVerticalCurvedTrapezoid(
            this.seatMap.sections[i].polygon,
            this.seatMap.sections[i].name,
            this.seatMap.sections[i].color,
            this.seatMap.sections[i],
            'section'
          )
        } else if (this.seatMap.sections[i].polygon[0] === 'H') {
          this.createStoredCurvedTrapezoid(
            this.seatMap.sections[i].polygon,
            this.seatMap.sections[i].name,
            this.seatMap.sections[i].color,
            this.seatMap.sections[i],
            'section'
          )
        } else if (this.seatMap.sections[i].polygon[0] === 'R') {
          this.createCircle(this.seatMap.sections[i], this.seatMap.sections[i].name, i, 'sections')
        } else {
          this.createPolygon(this.seatMap.sections[i], this.seatMap.sections[i].name, i, 'sections')
        }
      }
    }
    await this.getEventInfo()
  }

  public createToilet(toilet: any, name: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    let newStr = toilet.polygon.slice(1)
    let i = parseInt(toilet.polygon[0])
    let svg = d3.select(document.querySelector('svg'))
    const idImage = 'url(#' + name + 'locked2)'
    let myimage = svg
      .append('defs')
      .append('pattern')
      .attr('id', name + 'locked2')
      //.attr('patternUnits', 'userSpaceOnUse')
      .attr('width', toilets[i].width)
      .attr('height', toilets[i].height)
      .append('image')
      .attr('href', toilets[i].url)
      .attr('width', toilets[i].width)
      .attr('height', toilets[i].height)
      .attr('id', name + 'locked2')
    //.attr('transform', translate(20, 20))

    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', newStr)
      //.attr('fill', this.createSectionForm.get('color')?.value)
      .attr('fill', idImage)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    //this.polygon = polygon
    // this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  public createAccess(acc: any, name: string) {
    // d3.selectAll('predefinedPolygon').remove()
    // let svg = d3.select(document.querySelector('svg'))
    // svg.selectAll('predefinedPolygon').remove()
    let newStr = acc.polygon.slice(2)
    let i = parseInt(acc.polygon[0] + acc.polygon[1])
    let svg = d3.select(document.querySelector('svg'))
    const idImage = 'url(#' + name + 'locked2)'
    let myimage = svg
      .append('defs')
      .append('pattern')
      .attr('id', name + 'locked2')
      //.attr('patternUnits', 'userSpaceOnUse')
      .attr('width', access[i].width)
      .attr('height', access[i].height)
      .append('image')
      .attr('href', access[i].url)
      .attr('width', access[i].width)
      .attr('height', access[i].height)
      .attr('id', name + 'locked2')
    //.attr('transform', (200, 200))

    d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', newStr)
      //.attr('fill', this.createSectionForm.get('color')?.value)
      .attr('fill', idImage)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
    //this.polygon = polygon
    // this.adhocForm.controls['adhoc'].setValue(this.polygon)
  }

  public createStoredVerticalCurvedTrapezoid(
    path: string,
    name: string,
    color: string,
    section: any,
    type: string
  ) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width / 2
    }
    if (Number.isNaN(k2)) {
      k2 = width / 2
    }
    const k = (x0 + width + k1) / 2
    const kb = (x0 + width + k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()

    const funcion =
      'M ' +
      x0 +
      ',' +
      y0 +
      ' Q ' +
      k +
      ',' +
      (y0 + cp) +
      ' ' +
      (x0 + width + hlo) +
      ',' +
      (y0 + vro) +
      'L ' +
      (x0 + width - ro) +
      ',' +
      (y0 + height + hro) +
      ' Q ' +
      kb +
      ',' +
      (y0 + height + cp2) +
      ' ' +
      (x0 + lo) +
      ',' +
      (y0 + height + vlo) +
      ' Z'

    /*svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')
      .attr('id', name)
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('d', funcion)
      .attr('stroke-width', '0.2')*/

    this.sections.push({
      d: funcion,
      points: section.polygon,
      type: type,
      color: section.color,
      name: section.name,
      grid: section.grid,
      curve: section.curve,
      rows: section.rows,
      cols: section.cols
    })
    let sectionFixed = {}
    for (let i = 0; i < this.sections.length; i++) {
      if (this.session.sectionsGrids) {
        for (const sgrid of this.session.sectionsGrids) {
          if (sgrid.nameSection && sgrid.nameSection === this.sections[i].name) {
            sectionFixed = {
              name: this.sections[i].name,
              color: this.sections[i].color,
              points: this.sections[i].points,
              path: this.sections[i].d,
              grid: this.sections[i].grid,
              curve: this.sections[i].curve,
              rows: this.sections[i].rows,
              cols: this.sections[i].cols,
              occupiedSeats: sgrid.occupiedSeats,
              totalSeats: sgrid.totalSeats,
              type: this.sections[i].type,
              occupiedSeatsPercen: sgrid.occupiedSeatsPercen,
              seatsWithoutSeats: sgrid.seatsWithoutSeats
            }
            if (this.sections[i].d) {
              this.sections[i] = sectionFixed
            }
            //console.log('sectionFixed: ', sectionFixed)
            //this.sections[i] = sectionFixed
          }
        }
      }

      for (const sprice of this.session.sectionsPrices) {
        if (sprice.name && sprice.name === this.sections[i].name) {
          sectionFixed = {
            ...this.sections[i],
            price: sprice.price
          }
          this.sections[i] = sectionFixed
          this.sectionsPath.push(sectionFixed)
        }
      }
    }
    this.oldSections = this.sections
  }

  /*public createStoredCurvedTrapezoid(path: string, name: string, color: string) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(cp)) {
      cp = 0
    }
    const k = y0 + height / 2
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()
    svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')

      .attr('id', name)
      .attr('d', function (d) {
        return (
          'M ' +
          x0 +
          ',' +
          y0 +
          ' Q ' +
          (x0 + cp) +
          ',' +
          k +
          ' ' +
          (x0 + vro) +
          ',' +
          (y0 + height + ro) +
          'L ' +
          (x0 - width - ro) +
          ',' +
          (y0 + height + ro) +
          ' Q ' +
          (x0 + cp - width) +
          ',' +
          k +
          ' ' +
          (x0 - width - lo) +
          ',' +
          (y0 + lo + vlo) +
          ' Z'
        )
      })
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
  }*/

  private createPolygon(section: any, name: string, index: number, type: string) {
    /* if (this.mode === 'edit' && index === this.index && type === 'sections') {
      name = 'patata'
    } */
    //d3.select('#' + name).remove()
    if (name === undefined) {
      name = 'Escenario'
    }
    /*d3.select(document.querySelector('svg'))
      .append('polygon')
      .attr('points', section.polygon)
      .attr('fill', section.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')*/

    this.sections.push({
      points: section.polygon,
      color: section.color,
      name: section.name,
      grid: section.grid,
      curve: section.curve,
      rows: section.rows,
      cols: section.cols
    })
    let sectionFixed = {}
    for (let i = 0; i < this.sections.length; i++) {
      if (this.session.sectionsGrids) {
        for (const sgrid of this.session.sectionsGrids) {
          if (sgrid.nameSection && sgrid.nameSection === this.sections[i].name) {
            sectionFixed = {
              name: this.sections[i].name,
              color: this.sections[i].color,
              points: this.sections[i].points,
              grid: this.sections[i].grid,
              curve: this.sections[i].curve,
              rows: this.sections[i].rows,
              cols: this.sections[i].cols,
              occupiedSeats: sgrid.occupiedSeats,
              totalSeats: sgrid.totalSeats,
              occupiedSeatsPercen: sgrid.occupiedSeatsPercen,
              seatsWithoutSeats: sgrid.seatsWithoutSeats
            }
            //console.log('sectionFixed: ', sectionFixed)
            this.sections[i] = sectionFixed
          }
        }
      }

      for (const sprice of this.session.sectionsPrices) {
        if (sprice.name && sprice.name === this.sections[i].name) {
          sectionFixed = {
            ...this.sections[i],
            price: sprice.price
          }
          this.sections[i] = sectionFixed
          this.sectionsPol.push(sectionFixed)
        }
      }
    }
    this.oldSections = this.sections
  }

  private createCircle(section: any, name: string, index: number, type: string) {
    /* if (this.mode === 'edit' && index === this.index && type === 'sections') {
      name = 'patata'
    } */
    let newStr = section.polygon.slice(1)
    let polygon = newStr.split(',')
    //d3.select('#patata').remove()

    d3.selectAll('circle').remove()
    d3.select(document.querySelector('svg'))
      .attr('fill', section.color)
      .attr('id', name)
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2')
      .append('circle')
      .style('stroke', 'gray')
      .attr('r', polygon[0])
      .attr('cx', polygon[1])
      .attr('cy', polygon[2])

    this.sections.push({
      points: section.polygon,
      r: polygon[0],
      cx: polygon[1],
      cy: polygon[2],
      color: section.color,
      name: section.name,
      grid: section.grid,
      curve: section.curve,
      rows: section.rows,
      cols: section.cols
    })
    let sectionFixed = {}
    for (let i = 0; i < this.sections.length; i++) {
      if (this.session.sectionsGrids) {
        for (const sgrid of this.session.sectionsGrids) {
          if (sgrid.nameSection && sgrid.nameSection === this.sections[i].name) {
            /*  let newStr = this.sections[i].points.slice(1)
            let polygon = newStr?.split(',') */
            sectionFixed = {
              name: this.sections[i].name,
              color: this.sections[i].color,
              points: polygon,
              r: polygon[0],
              cx: polygon[1],
              cy: polygon[2],
              grid: this.sections[i].grid,
              curve: this.sections[i].curve,
              rows: this.sections[i].rows,
              cols: this.sections[i].cols,
              occupiedSeats: sgrid.occupiedSeats,
              totalSeats: sgrid.totalSeats,
              occupiedSeatsPercen: sgrid.occupiedSeatsPercen,
              seatsWithoutSeats: sgrid.seatsWithoutSeats
            }
            //console.log('sectionFixed: ', sectionFixed)
            this.sections[i] = sectionFixed
          }
        }
      }

      for (const sprice of this.session.sectionsPrices) {
        if (sprice.name && sprice.name === this.sections[i].name) {
          sectionFixed = {
            ...this.sections[i],
            price: sprice.price
          }
          this.sections[i] = sectionFixed
          this.sectionsC.push(sectionFixed)
        }
      }
    }
    this.oldSections = this.sections
  }

  public createStoredCurvedTrapezoid(
    path: string,
    name: string,
    color: string,
    section: any,
    type: string
  ) {
    let data = [3]
    let newStr = path.slice(1)
    let polygon = newStr.split(',')
    let svgContainer = d3.select(document.querySelector('svg')).append('svg')
    const x0 = parseFloat(polygon[0])
    const y0 = parseFloat(polygon[1])
    const width = parseFloat(polygon[2])
    const height = parseFloat(polygon[3])
    const ro = parseFloat(polygon[4])
    const lo = parseFloat(polygon[5])
    let vro = parseFloat(polygon[6])
    let vlo = parseFloat(polygon[7])
    let cp = parseFloat(polygon[8])
    let hro = parseFloat(polygon[9])
    let hlo = parseFloat(polygon[10])
    let cp2 = parseFloat(polygon[11])
    let k1 = parseFloat(polygon[12])
    let k2 = parseFloat(polygon[13])
    if (Number.isNaN(vro)) {
      vro = 0
    }
    if (Number.isNaN(vlo)) {
      vlo = 0
    }
    if (Number.isNaN(hlo)) {
      hlo = 0
    }
    if (Number.isNaN(hro)) {
      hro = 0
    }
    if (Number.isNaN(cp)) {
      cp = 100
    }
    if (Number.isNaN(cp2)) {
      cp2 = 100
    }
    if (Number.isNaN(k1)) {
      k1 = width
    }
    if (Number.isNaN(k2)) {
      k2 = width
    }
    const k = (x0 + width - k1) / 2
    const kb = (x0 + width - k2) / 2
    if (name === undefined) {
      name = 'Escenario'
    }
    name = name.replace(/ /g, '')
    d3.select('#' + name).remove()

    const funcion =
      'M ' +
      x0 +
      ',' +
      y0 +
      ' Q ' +
      (x0 + cp) +
      ',' +
      k +
      ' ' +
      (x0 + hro) +
      ',' +
      (y0 + height + hlo) +
      'L ' +
      (x0 - width - ro) +
      ',' +
      (y0 + height + vro) +
      ' Q ' +
      (x0 + cp2 - width) +
      ',' +
      kb +
      ' ' +
      (x0 - width - lo) +
      ',' +
      (y0 + lo + vlo) +
      ' Z'

    /*svgContainer
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')
      .attr('id', name)
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('d', funcion)
      .attr('stroke-width', '0.2')*/

    this.sections.push({
      d: funcion,
      points: section.polygon,
      color: section.color,
      name: section.name,
      grid: section.grid,
      curve: section.curve,
      rows: section.rows,
      cols: section.cols,
      type: type
    })
    let sectionFixed = {}
    for (let i = 0; i < this.sections.length; i++) {
      if (this.session.sectionsGrids) {
        for (const sgrid of this.session.sectionsGrids) {
          if (sgrid.nameSection && sgrid.nameSection === this.sections[i].name) {
            sectionFixed = {
              name: this.sections[i].name,
              color: this.sections[i].color,
              points: this.sections[i].points,
              grid: this.sections[i].grid,
              curve: this.sections[i].curve,
              rows: this.sections[i].rows,
              cols: this.sections[i].cols,
              type: this.sections[i].type,
              path: this.sections[i].d,
              occupiedSeats: sgrid.occupiedSeats,
              totalSeats: sgrid.totalSeats,
              occupiedSeatsPercen: sgrid.occupiedSeatsPercen,
              seatsWithoutSeats: sgrid.seatsWithoutSeats
            }
            //console.log('sectionFixed: ', sectionFixed)

            if (this.sections[i].d) {
              this.sections[i] = sectionFixed
            }
          }
        }
      }

      for (const sprice of this.session.sectionsPrices) {
        if (sprice.name && sprice.name === this.sections[i].name) {
          sectionFixed = {
            ...this.sections[i],
            price: sprice.price
          }
          this.sections[i] = sectionFixed
          this.sectionsPath.push(sectionFixed)
        }
      }
    }
    this.oldSections = this.sections
  }

  public async showSection(section: any) {
    this.tickets = []
    await this.createOrder()
    if (this.order === undefined) {
      // await this.createOrder()
    }
    if (section?.name) {
      this.createSeatsForm.controls['rows'].setValue(section.rows)
      this.createSeatsForm.controls['cols'].setValue(section.cols)
      this.createSeatsForm.controls['number'].setValue(section.rows)
      this.createSeatsForm.controls['curve'].setValue(section?.curve)
      this.section = section
      this.section.price = await this.getPriceFromSectionPrice(section.name)
      this.confirmed = false
      await this.generateSeats(section)
      this.switchShowSeats()
    } else if (section !== undefined) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: true,
        timerProgressBar: true,
        width: '50%',
        buttonsStyling: true,
        confirmButtonColor: '#5db2e4'
      })

      Toast.fire({
        title: 'Sección no disponible',
        text: 'Lo sentimos, en esta sección no hay asientos.',
        confirmButtonText: 'Continuar'
      })
    }
  }

  public async generateSeats(section: any) {
    this.layout = []
    const nameSection = section.name
    /* if (this.createSeatsForm.valid) { */
    this.layout = section.grid
    if (this.session.sectionsGrids) {
      for (const sectionGrid of this.session.sectionsGrids) {
        if (sectionGrid.nameSection == nameSection) {
          this.layout = sectionGrid.rowSection
        }
      }
    }
    this.seatsRows = []
    if (this.layout) {
      await this.processSeatChart(this.layout, section)
    }
    /* } */
  }
  public async selectSeatGeneral(event: any) {
    this.selectedSeats = []
    console.log(888, this.priceSection)
    const inputValue2 = (event.target as HTMLInputElement).value
    this.inputValue = parseInt(inputValue2, 10)

    // if (inputValue > 0) {
    //   for (let i = 0; i < inputValue; i++) {
    //     this.ticket = {
    //       price: this.priceSection,
    //       session: this.session.id,
    //       event: this.loadedEvent.id,
    //       order: this.order.id,
    //       typeTicket: 'Entrada por taquilla',
    //       fila: 'ND',
    //       butaca: 'ND',
    //       section: this.section.name
    //     }
    //     console.log(this.ticket)
    //      await this.createTicket(this.ticket)
    //   }
    // }
  }
  public async confirm() {
    console.log(this.session)
    const fechaOriginal = this.session.date
    const partesFecha = fechaOriginal.split('/')
    const fechaJS = new Date(Date.UTC(partesFecha[2], partesFecha[1] - 1, partesFecha[0]))
    const fechaFormateada = fechaJS.toISOString()

    for (let i = 0; i < this.inputValue; i++) {
      this.ticket1 = {
        price: Number(this.priceSection),
        session: this.session.id,
        event: this.loadedEvent.id,
        order: this.order.id,
        typeTicket: 'Entrada por taquilla',
        fila: 'ND',
        butaca: 'ND',
        section: this.section.name,
        date: fechaFormateada
      }
      console.log(this.ticket1)
      await this.createTicket(this.ticket1)
    }
    this.inputValue = 0
    setTimeout(() => {
      this.imprimir = true
    }, 3000)
  }

  public async processSeatChart(layout: any[], section: any) {
    console.log('hola5')
    console.log(section)
    console.log(this.event)
    console.log(section.seatsWithoutSeats)
    if (section.seatsWithoutSeats !== null) {
      console.log(22222)
      console.log(this.showSeatsNoGeneral)
      this.showSeatsNoGeneral = false
      console.log(this.showSeatsNoGeneral)
    } else {
      this.showSeatsNoGeneral = true
    }

    this.seatsRows = []
    if (layout.length > 0) {
      let seatNoCounter = 1
      let row_label = ''
      let item_map = layout
      //Get the label name and price
      row_label = 'Row ' + item_map[0].seat_label + ' - '
      if (item_map[item_map.length - 1].seat_label != ' ') {
        row_label += item_map[item_map.length - 1].seat_label
      } else {
        row_label += item_map[item_map.length - 2].seat_label
      }
      item_map.forEach(async (map_element: any) => {
        let mapObj = {
          seatRowLabel: map_element.seat_label,
          seats: [] as any,
          seatPricingInformation: row_label,
          price: this.priceSection,
          images: map_element.images
        }
        row_label = ''
        let seatValArr = map_element.layout.split('')
        if (this.seatChartConfig.newSeatNoForRow) {
          seatNoCounter = 1
        }
        let totalItemCounter = 1
        const selectedCurve = section?.curve
        const columns = section.cols
        const z = columns % 2
        const middle = Math.floor(columns / 2)
        const maxCurve = selectedCurve * 0.4
        const step = maxCurve / middle
        let nextStep = 0
        seatValArr.forEach((item: any) => {
          let price = 0
          if (map_element?.prices !== undefined && map_element?.prices?.length > 0) {
            price = map_element.prices[totalItemCounter - 1]
          } else {
            price = section.price
          }
          // map_element.prices[totalItemCounter - 1]
          let seatObj = {
            key: map_element.seat_label + '_' + totalItemCounter,
            status: 'available',
            seatLabel: '',
            seatNo: '',
            seatMargin: 0,
            price: price
          }
          if (item === '0') {
            seatObj.status = 'unavailable'
          }
          if (item === '1') {
            seatObj.status = 'available'
          }
          if (item === '2') {
            seatObj.status = 'booked'
          }
          if (item === '3' || item === '5') {
            seatObj.status = 'reserved'
          }
          if (item === '4') {
            seatObj.status = 'blocked'
          }
          seatObj.seatLabel = map_element.seat_label + ' ' + seatNoCounter
          if (seatNoCounter < 10) {
            seatObj.seatNo = '0' + seatNoCounter
          } else {
            seatObj['seatNo'] = '' + seatNoCounter
          }
          if (item > 0) {
            seatNoCounter++
          }

          if (selectedCurve === 0) {
            seatObj.seatMargin = 0
          } else if (totalItemCounter <= middle) {
            seatObj.seatMargin = step * (totalItemCounter - 1)
            nextStep = step * (totalItemCounter - 1)
          } else {
            if (totalItemCounter === middle + 1 && z === 0) {
              seatObj.seatMargin = nextStep
            } else if (totalItemCounter === middle + 1 && z !== 0) {
              seatObj.seatMargin = step * (totalItemCounter - 1)
              nextStep = step * (totalItemCounter - 1)
            } else {
              seatObj.seatMargin = nextStep - step
              nextStep = nextStep - step
            }
          }

          totalItemCounter++
          mapObj.seats.push(seatObj)
        })
        this.seatsRows.push(mapObj)
      })
    }
  }

  public async selectSeat(seatObject: any) {
    console.log('hola4')
    console.log('seatObe: ', seatObject)
    // this.selectedSeats = []
    const selectedSeat = seatObject.seatLabel.split(' ')
    const row = Number(selectedSeat[0])
    const seat = Number(selectedSeat[1])
    const layout2Find = this.layout[row - 1].layout
    console.log('layout2Find: ', layout2Find)
    const zeros = this.calculateUnavailableSeats(layout2Find, +seatObject.key.split('_')[1])
    const imageId = this.section.grid[row - 1]?.images[seat - 1]
    console.log('imageId: ', imageId)
    let price = 0
    if (!this.layout[row - 1]?.prices || this.layout[row - 1]?.prices.length === 0) {
      price = Number(this.priceSection)
    } else {
      let seatData = seatObject.key.split('_')
      const row = Number(seatData[0])
      const seat = Number(seatData[1])
      price = Number(this.layout[row - 1].prices[seat - 1])
    }
    if (imageId) {
      firstValueFrom(await this.getImage(imageId)).then(async (image: any) => {
        if (image.file.url !== undefined) {
          this.imageSeat = image.file.url
        } else {
          this.imageSeat =
            'https://cdn.ticketernal.com/files/files/2023/5/f2bc747c-2ec7-43d2-b6d2-3103fa4d6639.png'
        }
        // this.ticket = {
        //   price: price,
        //   session: this.session.id,
        //   event: this.loadedEvent.id,
        //   order: this.order.id,
        //   typeTicket: 'Entrada por taquilla',
        //   fila: row.toString(),
        //   butaca: seat.toString(),
        //   section: this.section.name
        // }
        console.log('ticket: ', this.ticket)
        if (seatObject.status === 'available') {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: true,
            timerProgressBar: true,
            width: 'auto',
            buttonsStyling: true,
            confirmButtonColor: '#5db2e4'
          })
          Toast.fire({
            title: 'Butaca',
            html: 'Fila: ' + row + '<br>Butaca: ' + seat + '<br>Precio: ' + price + '€',
            confirmButtonText: 'Ok',
            position: 'top',
            imageUrl: this.imageSeat,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: 'Custom image'
          })
        }

        if (seatObject.status === 'available') {
          const newLine =
            this.layout[row - 1].layout.substring(0, seat - 1 + zeros) +
            '2' +
            this.layout[row - 1].layout.substring(seat + zeros)
          this.layout[row - 1].layout = newLine
          seatObject.status = 'blocked'
          this.selectedSeats.push(seatObject)
          await this.createTicket(this.ticket)
        } else if (seatObject.status === 'booked' /* ||  seatObject.status === 'blocked' */) {
          const newLine =
            this.layout[row - 1].layout.substring(0, seat - 1 + zeros) +
            '1' +
            this.layout[row - 1].layout.substring(seat + zeros)
          this.layout[row - 1].layout = newLine
          seatObject.status = 'available'
          this.selectedSeats.splice(this.selectedSeats.indexOf(seatObject), 1)
          this.tickets.splice(this.tickets.indexOf(this.ticket), 1)
        } else if (seatObject.status === 'blocked') {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: true,
            timerProgressBar: true,
            width: 'auto',
            buttonsStyling: true,
            confirmButtonColor: '#5db2e4'
          })
          Toast.fire({
            title: '¿Estas seguro de que quieres desbloquear los asientos?',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            position: 'center',
            showCancelButton: true
          }).then(async result => {
            if (result.isConfirmed) {
              const newLine =
                this.layout[row - 1].layout.substring(0, seat - 1 + zeros) +
                '1' +
                this.layout[row - 1].layout.substring(seat + zeros)
              this.layout[row - 1].layout = newLine
              seatObject.status = 'available'
              await this.confirmSeat(this.layout, this.section, '4')
              this.generateSeats(this.section)
              Toast.fire({
                title: 'Desbloqueo confirmado',
                icon: 'success',
                confirmButtonText: 'OK'
              })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Toast.fire({
                title: 'Desbloqueo cancelado',
                icon: 'error',
                confirmButtonText: 'OK'
              })
            }
          })
        }
        this.generateSeats(this.section)
        this.router.navigate(['/home/event-sessions-sectiongrids'])
      })
    } else {
      this.imageSeat =
        'https://cdn.ticketernal.com/files/files/2023/5/f2bc747c-2ec7-43d2-b6d2-3103fa4d6639.png'

      this.ticket = {
        price: price,
        session: this.session.id,
        event: this.loadedEvent.id,
        order: this.order.id,
        typeTicket: 'Entrada por taquilla',
        fila: row.toString(),
        butaca: seat.toString(),
        section: this.section.name
      }
      console.log('ticket: ', this.ticket)

      /* if (seatObject.status === 'available') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: true,
          timerProgressBar: true,
          width: 'auto',
          buttonsStyling: true,
          confirmButtonColor: '#5db2e4'
        })
        Toast.fire({
          title: 'Butaca',
          html: 'Fila: ' + row + '<br>Butaca: ' + seat + '<br>Precio: ' + price + '€',
          confirmButtonText: 'Ok',
          position: 'top'
        })
      } */

      if (seatObject.status === 'available') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: true,
          timerProgressBar: true,
          width: 'auto',
          buttonsStyling: true,
          confirmButtonColor: '#5db2e4'
        })
        Toast.fire({
          title: 'Butaca',
          html: 'Fila: ' + row + '<br>Butaca: ' + seat + '<br>Precio: ' + price + '€',
          confirmButtonText: 'Ok',
          position: 'top'
        })
        const newLine =
          this.layout[row - 1].layout.substring(0, seat - 1 + zeros) +
          '2' +
          this.layout[row - 1].layout.substring(seat + zeros)
        this.layout[row - 1].layout = newLine
        seatObject.status = 'blocked'
        this.selectedSeats.push(seatObject)
        console.log('selectedSeats: ', this.selectedSeats)
        this.ticket = {
          price: price,
          session: this.session.id,
          event: this.loadedEvent.id,
          order: this.order.id,
          typeTicket: 'Entrada por taquilla',
          fila: row.toString(),
          butaca: seat.toString(),
          section: this.section.name
        }
        await this.createTicket(this.ticket)
      } else if (seatObject.status === 'booked' /* ||  seatObject.status === 'blocked' */) {
        const newLine =
          this.layout[row - 1].layout.substring(0, seat - 1 + zeros) +
          '1' +
          this.layout[row - 1].layout.substring(seat + zeros)
        this.layout[row - 1].layout = newLine
        seatObject.status = 'available'
        this.selectedSeats.splice(this.selectedSeats.indexOf(seatObject), 1)
        this.tickets.splice(this.tickets.indexOf(this.ticket), 1)
      } else if (seatObject.status === 'blocked') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: true,
          timerProgressBar: true,
          width: 'auto',
          buttonsStyling: true,
          confirmButtonColor: '#5db2e4'
        })
        Toast.fire({
          title: '¿Estas seguro de que quieres desbloquear los asientos?',
          confirmButtonText: 'Continuar',
          cancelButtonText: 'Cancelar',
          position: 'center',
          showCancelButton: true
        }).then(async result => {
          if (result.isConfirmed) {
            const newLine =
              this.layout[row - 1].layout.substring(0, seat - 1 + zeros) +
              '1' +
              this.layout[row - 1].layout.substring(seat + zeros)
            this.layout[row - 1].layout = newLine
            seatObject.status = 'available'
            await this.confirmSeat(this.layout, this.section, '4')
            this.generateSeats(this.section)
            Toast.fire({
              title: 'Desbloqueo confirmado',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Toast.fire({
              title: 'Desbloqueo cancelado',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          }
        })
      }
      this.generateSeats(this.section)
      this.router.navigate(['/home/event-sessions-sectiongrids'])
    }
  }

  calculateUnavailableSeats(layout2Find: any, key: number): number {
    let zeros = 0
    for (let i = 0; i < layout2Find.length; i++) {
      if (layout2Find[i] === '0' && i < key) {
        zeros++
      } else {
        if (i >= key) {
          break
        }
      }
    }
    return zeros
  }

  private async createTicket(ticket: any): Promise<any> {
    if (this.order.id == null) {
      await this.createOrder()
    }
    firstValueFrom(await this.ticketsService.create(ticket)).then(res => {
      const ticket2Send = res.ticket
      this.tickets.push(ticket2Send)
      return ticket2Send
    })
  }

  private async updateOrder(order: any): Promise<any> {
    const tickets = { tickets: this.tickets }
    firstValueFrom(await this.ordersService.updateOne(order.id, tickets)).then(res => {
      console.log(res)
      const order2Send = res.order
      this.order = order2Send
      return order2Send
    })
  }
  private async updateOrder2(order: any): Promise<any> {
    firstValueFrom(await this.ordersService.updateOne(order.id, this.order)).then(res => {
      console.log(res)
    })
  }

  formatSessionDate(session: any) {
    const date = new Date(session.date).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })
    this.weekDay = this.weekDays[new Date(session.date).getDay()]
    return date
  }

  public async saveSession(typeBlock: string) {
    await this.updateSession(this.layouts2Update, typeBlock)
    //this.gotoList()
  }

  public async updateSession(layout2Update: any, typeBlock?: string) {
    let sectionsGrids = []
    for (const layout of layout2Update) {
      if (typeBlock === '3') {
        this.fix22x(layout.layout, typeBlock)
      }
      if (typeBlock === '4') {
        this.fix22x(layout.layout, typeBlock)
      }
      console.log(this.section)
      const sectionsGrid = {
        nameSection: layout.section.name,
        rowSection: layout.layout,
        seatsWithoutSeats: layout.section.seatsWithoutSeats,
        occupiedSeats: layout.section.occupiedSeats,
        occupiedSeatsPercen: layout.section.occupiedSeatsPercen,
        totalSeats: layout.section.totalSeats
      }
      sectionsGrids.push(sectionsGrid)
    }
    const sectionGrids2Send = { sectionsGrids }
    console.log(222, sectionGrids2Send)
    firstValueFrom(await this.sessionsService.updateOne(this.session.id, sectionGrids2Send)).then(
      async (res: any) => {
        this.session = res.session
        await this.getSession()
      }
    )
  }

  private async createOrder() {
    const order = {
      event: this.loadedEvent.id,
      user: environment.userAnonymous,
      tickets: this.tickets
      //total: this.total
    }
    firstValueFrom(await this.ordersService.create(order)).then(async (res: any) => {
      this.order = res.order
    })
  }

  fix22x(layout: any, x: string): any {
    layout.forEach((row: any) => {
      row.layout = row.layout.replace(/2/g, x)
    })
  }

  fix221(ticket2Fix: any): any {
    const layout2Find = this.layout[ticket2Fix.row - 1].layout
    const zeros = this.calculateUnavailableSeats(layout2Find, +ticket2Fix.key.split('_')[1])
    for (let l = 0; l < this.layouts2Update.length; l++) {
      if (this.layouts2Update[l].section.name === ticket2Fix.section) {
        let layouts2fix = this.layouts2Update[l].layout
        let row2fix = layouts2fix[ticket2Fix.row - 1]
        let newRowSplited = row2fix.layout.split('')
        let pos = ticket2Fix.seat - 1 + zeros
        newRowSplited[pos] = '1'
        let newRow = newRowSplited.join('')
        row2fix.layout = newRow
      }
    }
  }

  fixLayout(layout: any, grid: any) {
    let layout2Fix = []

    for (const lay of layout) {
      for (let i = 0; i < lay.layout.length; i++) {
        layout2Fix = []
        for (const gr of grid) {
          let layoutFixed = ''
          for (let glayout = 0; glayout < gr.layout.length; glayout++) {
            if (gr.layout[glayout] === '0') {
              layoutFixed = layoutFixed + gr.layout[glayout]
            } else {
              layoutFixed = layoutFixed + lay.layout[glayout]
            }
          }
          const layout2Build = {
            layout: layoutFixed,
            seat_label: gr.seat_label
          }
          layout2Fix.push(layout2Build)
        }
      }
    }

    this.layout = layout2Fix
  }

  switchShowSeats() {
    console.log('hola3')
    this.showSeats = !this.showSeats
  }

  async confirmBlockSeat(layout: any, section: any) {
    console.log('layout', layout)
    console.log('section', section)
    console.log('this.selectedSeats', this.selectedSeats)
    let Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: true,
      timerProgressBar: true,
      width: 'auto',
      buttonsStyling: true,
      confirmButtonColor: '#5db2e4'
    })
    let text2Swal = ''
    for (const selectedSeat of this.selectedSeats) {
      console.log('selectedSeat', selectedSeat)
      let price = 0
      const seatData = selectedSeat.key.split('_')
      const row = Number(seatData[0])
      const seat = Number(seatData[1])
      if (!layout[row - 1]?.prices || layout[row - 1]?.prices.length === 0) {
        price = this.priceSection
      } else {
        price = layout[row - 1].prices[seat - 1]
      }
      text2Swal +=
        '<p>' +
        'Seccion: ' +
        section.name +
        ' (Fila: ' +
        selectedSeat.key.split('_')[0] +
        ' Asiento: ' +
        selectedSeat.seatNo +
        ')' +
        ' Precio: ' +
        price +
        '€' +
        '</p>'
    }
    console.log('text2Swal', text2Swal)
    Toast.fire({
      title: '¿Estas seguro de que quieres bloquear los asientos?',
      html: text2Swal,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
      position: 'center',
      showCancelButton: true
    }).then(async result => {
      if (result.isConfirmed) {
        await this.confirmSeat(layout, section, '4')
        this.generateSeats(section)
        this.selectedSeats = []
        this.confirmedSeats = true
        this.tickets = []
        Toast.fire({
          title: 'Bloqueo confirmado',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(async () => {
          this.gotoSections()
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Toast.fire({
          title: 'Bloqueo cancelado',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    })
  }

  async confirmSeat(layout: any, section: any, typeBlock?: any) {
    this.section = section
    console.log(this.section)
    /*if (!layout[0].prices) {
      for (let i = 0; i < layout.length; i++) {
        let prices = []
        for (let j = 0; j < layout[i].layout.length; j++) {
          prices.push(this.priceSection)
        }
        layout[i]['prices'] = prices
      }
    }
    if (this.selectedSeats.length > 0) {
      for (let i = 0; i < this.selectedSeats.length; i++) {
        let seatData = this.selectedSeats[i].key.split('_')
        const row = Number(seatData[0])
        const seat = Number(seatData[1])
        layout[row].prices[seat] = this.createCustomPriceForm.get('seatCustomPrice')?.value
      }
    }*/
    const layout2Send = {
      layout: layout,
      section: section
    }
    this.layouts2Update.push(layout2Send)
    this.confirmed = !this.confirmed
    await this.saveSession(typeBlock)
  }

  async buyTicketOffice(layout: any, section: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: true,
      timerProgressBar: true,
      width: 'auto',
      buttonsStyling: true,
      confirmButtonColor: '#5db2e4'
    })

    let text2Swal = ''
    let textPago = 'Asegúrate de seleccionar el método de pago'

    for (const selectedSeat of this.selectedSeats) {
      let price = 0
      const seatData = selectedSeat.key.split('_')
      const row = Number(seatData[0])
      const seat = Number(seatData[1])
      if (!layout[row - 1]?.prices || layout[row - 1]?.prices.length === 0) {
        price = this.priceSection
      } else {
        price = layout[row - 1].prices[seat - 1]
      }
      text2Swal +=
        '<h3>' +
        'Seccion: ' +
        section.name +
        ' (Fila: ' +
        selectedSeat.key.split('_')[0] +
        ' Asiento: ' +
        selectedSeat.seatNo +
        ')' +
        ' - Precio: ' +
        price +
        '€' +
        '</h3>'
    }
    text2Swal += '<span>' + textPago + '</span>'

    Toast.fire({
      title: '¿Estas seguro de que quieres confirmar la compra en taquilla?',
      input: 'radio',
      inputOptions: {
        '1': 'Efectivo',
        '2': 'Tarjeta Bancaria'
      },
      inputValue: '2',
      html: text2Swal,
      position: 'center',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true
    }).then(async result => {
      if (result.isConfirmed) {
        if (result.value === '1') {
          console.log('entra???')
          this.order.payMode = 'Efectivo'
          this.order.status = 'Paid'
          await this.updateOrder2(this.order)
        } else if (result.value === '2') {
          this.order.payMode = 'Tarjeta Bancaria'
          this.order.status = 'Paid'
          await this.updateOrder(this.order)
        }
        await this.confirmSeat(layout, section, '3')
        await this.updateOrder(this.order)
        console.log(this.order)
        this.blockedSeats = true
        this.generateSeats(section)
        this.tickets = []
        this.showSeats = false

        Toast.fire({
          title: 'Compra confirmada',
          html:
            'Puedes descargar los tickets ' +
            `<a href="https://api.ticketernal.com/pdf/${this.order.orderNumber}">aquí</a>` +
            ' o después en el boton de Imprimir tickets',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(async () => {
          this.selectedSeats = []
          this.gotoInitial()
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Toast.fire({
          title: 'Compra cancelada',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    })
  }

  async printTickets() {
    this.pdfLoading = true
    firstValueFrom(await this.pdfService.generatePdf(this.order.orderNumber)).then(
      async (res: any) => {
        this.downloadFile(res)
        this.pdfLoading = false
        this.blockedSeats = false
        this.selectedSeats = []
      }
    )
  }

  downloadFile(data: Blob | MediaSource) {
    const url = window.URL.createObjectURL(data)
    window.open(url)
  }

  gotoList() {
    this.switchShowSeats()
    this.ngOnInit()
  }
  gotoSections2() {
    this.showSeatsNoGeneral = false
    console.log(this.section)
    this.imprimir = false
    history.state.event = this.loadedEvent
    history.state.session = this.session
    this.seatMap = this.session.seatMap
    this.getSeatMapInfo()
    this.switchShowSeats()
    //window.location.reload()
    this.router.navigate(['/home/event-sessions-details'], {
      state: { mode: 'edit', session: this.session, event: this.loadedEvent }
    })
  }
  gotoSections() {
    this.resetSelectedSeats()

    this.selectedSeats = []
    this.showSeatsNoGeneral = false
    this.imprimir = false
    console.log('hola2')
    this.showSeats = false
    // history.state.event = this.loadedEvent
    // history.state.session = this.session
    // this.seatMap = this.session.seatMap
    // this.getSeatMapInfo()
    // this.switchShowSeats()
    // //window.location.reload()
    // this.router.navigate(['/home/event-sessions-details'], {
    //   state: { mode: 'edit', session: this.session, event: this.loadedEvent }
    // })
  }
  resetSelectedSeats() {
    console.log(this.layout)
    console.log(this.selectedSeats)
    for (const seatObject of this.selectedSeats) {
      console.log(seatObject)
      const row = parseInt(seatObject.key.split('_')[0], 10)
      console.log(77, row)
      console.log(typeof row)
      if (!isNaN(row) && row >= 1 && row <= this.layout.length) {
        const keyParts = seatObject.key.split('_')
        const seatNumber = Number(keyParts[1])
        console.log(88, seatNumber)
        const layoutRow = this.layout[row - 1]
        console.log(99, layoutRow)
        if (
          layoutRow &&
          !isNaN(seatNumber) &&
          seatNumber >= 1 &&
          seatNumber <= layoutRow.layout.length
        ) {
          const zeros = this.calculateUnavailableSeats(layoutRow.layout, seatNumber)
          const newLine =
            layoutRow.layout.substring(0, seatNumber - 1 + zeros) +
            '1' +
            layoutRow.layout.substring(seatNumber + zeros)
          layoutRow.layout = newLine
          seatObject.status = 'available'
        }
      }
    }
  }
  gotoInitial() {
    this.router.navigate(['/home/event-sessions-details'], {
      state: { mode: 'edit', session: this.session, event: this.loadedEvent }
    })
  }

  getRoles() {
    const user = JSON.parse(localStorage.getItem('fndsadmin::current_user') || '{}')
    this.user = user.user
    this.roles = this.user.role
  }

  async getImage(id: string) {
    const url = await this.fileService.getImage(id)
    return url
  }

  async editPrice(layout: any, section: any, typeBlock?: any) {
    const customPrice = this.createCustomPriceForm.get('seatCustomPrice')?.value.toString()
    console.log(222, customPrice)
    if (this.selectedSeats.length === 0 || isNaN(customPrice)) {
      Swal.fire('No hay asientos seleccionados y/o precio configurado', '', 'error')
    } else {
      Swal.fire({
        title: '¿Quieres editar el precio?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Editar',
        denyButtonText: 'Cancelar'
      }).then(async result => {
        if (result.isConfirmed) {
          this.section = section
          console.log(777, this.section)
          if (!layout[0].prices) {
            for (let i = 0; i < layout.length; i++) {
              let prices = []
              for (let j = 0; j < layout[i].layout.length; j++) {
                prices.push(this.priceSection)
              }
              layout[i]['prices'] = prices
            }
          }
          if (this.selectedSeats.length > 0) {
            const seatsLength = this.selectedSeats.length
            for (let i = 0; i < seatsLength; i++) {
              let seatData = this.selectedSeats[i].key.split('_')
              const row = Number(seatData[0])
              const seat = Number(seatData[1])
              layout[row - 1].prices[seat - 1] = customPrice
              const selectedSeat = this.selectedSeats[i].seatLabel.split(' ')
              const layout2Find = this.layout[row - 1].layout
              const zeros = this.calculateUnavailableSeats(
                layout2Find,
                +this.selectedSeats[i].key.split('_')[1]
              )
              const newLine =
                this.layout[row - 1].layout.substring(0, seat - 1 + zeros) +
                '1' +
                this.layout[row - 1].layout.substring(seat + zeros)
              this.layout[row - 1].layout = newLine
              layout[row - 1].layout = newLine
              this.selectedSeats[i].status = 'available'
            }
            for (let i = 0; i < seatsLength; i++) {
              this.selectedSeats.splice(this.selectedSeats.indexOf(this.selectedSeats[i]), 1)
              this.tickets.splice(this.tickets.indexOf(this.ticket), 1)
            }
          }
          const layout2Send = {
            layout: layout,
            section: section
          }
          this.layouts2Update.push(layout2Send)
          this.confirmed = !this.confirmed
          let sectionsGrids = []
          for (const layout of this.layouts2Update) {
            const sectionsGrid = {
              nameSection: layout.section.name,
              rowSection: layout.layout,
              totalSeats: this.section.totalSeats,
              occupiedSeats: this.section.occupiedSeats,
              seatsWithoutSeats: this.section.seatsWithoutSeats,
              occupiedSeatsPercen: this.section.occupiedSeatsPercen
            }
            sectionsGrids.push(sectionsGrid)
          }
          const sectionGrids2Send = { sectionsGrids }
          console.log(88888, sectionGrids2Send)
          firstValueFrom(
            await this.sessionsService.updateOne(this.session.id, sectionGrids2Send)
          ).then(async (res: any) => {
            Swal.fire('Precio actualizado correctamente', '', 'success')
            this.session = res.session
            await this.getSession()
            await this.generateSeats(section)
            this.selectedSeats = []
            this.gotoInitial()
          })
          await this.saveSession(typeBlock)
        }
      })
    }
  }
}
