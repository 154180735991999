/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  chevronLeft = faChevronLeft
  showDashboardSubmenu: boolean = false
  isDashboardShowing: boolean = false
  isDashboardExpanded: boolean = true
  showSubSubMenu: boolean = false
  showUsersSubmenu: boolean = false
  isUsersExpanded: boolean = true
  isUsersShowing: boolean = false
  showEventsSubmenu: boolean = false
  isEventsExpanded: boolean = true
  isEventsShowing: boolean = false
  showPaymentsSubmenu: boolean = false
  isPaymentsExpanded: boolean = true
  isPaymentsShowing: boolean = false
  showBannersSubmenu: boolean = false
  isBannersExpanded: boolean = true
  isBannersShowing: boolean = false
  showEntradasSubmenu: boolean = false
  isEntradasExpanded: boolean = true
  isEntradasShowing: boolean = false
  user: any
  roles: any
  mobile: boolean = false

  constructor() {}

  async ngOnInit() {
    await this.getRoles()
    if (window.screen.width < 600) {
      this.mobile = true
    }
    console.log('this.mobile: ', this.mobile)
  }

  async getRoles() {
    const user = JSON.parse(localStorage.getItem('fndsadmin::current_user') || '{}')
    this.user = user.user
    this.roles = this.user.role
  }
}
