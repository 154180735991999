import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { ScannerQRCodeConfig, ScannerQRCodeDevice } from 'ngx-scanner-qrcode'
import { firstValueFrom } from 'rxjs'
import { TicketsService } from 'src/app/shared/services/tickets.service'
import Swal from 'sweetalert2'
import { EventsService } from './../../../../shared/services/events.service'

let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

@Component({
  selector: 'app-lector-qr',
  templateUrl: './lector-qr.component.html',
  styleUrls: ['./lector-qr.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LectorQrComponent implements OnInit {
  user: any
  roles: any
  events: any
  minDate = new Date()
  showDataSubmenu: boolean = false
  isDataExpanded: boolean = true
  isDataShowing: boolean = false
  showHourSubmenu: boolean = false
  isHourExpanded: boolean = true
  isHourShowing: boolean = false
  fechaSelectedMientras: Date | undefined
  selectedPerpetualSession: any
  fechaSelected: Date | undefined
  hourSelected: string = ''
  hours: string[] = []
  fechas: Date[] = []
  sessions: any[] = []
  evento: any
  perpetualDateSelected: Date | null | undefined
  @ViewChild('action') output: any
  session: any
  tickets: any
  ticketsChecked: any = []
  verificacion: boolean = true
  tipoPerpetuo: any
  sessionId: string = ''

  public config: ScannerQRCodeConfig = isMobile
    ? {
        constraints: {
          video: {
            facingMode: { exact: 'environment' },
            width: window.innerWidth
          }
        }
      }
    : {
        constraints: {
          video: {
            width: window.innerWidth
          }
        }
      }

  public canvasStyles = {
    fillStyle: '#000',
    font: '1px',
    lineWidth: 0,
    strokeStyle: 'black'
  }

  public device: ScannerQRCodeDevice = {
    deviceId: '1',
    kind: 'Rear camera',
    label: 'RC',
    groupId: '1'
  }
  showSearch: boolean = false
  search: string = ''
  oldEvents: any
  showQR: boolean = true
  actualStatus: boolean = false
  fechasPerpetual: string = ''
  perpetualSession: boolean = false

  constructor(private eventsService: EventsService, private ticketsService: TicketsService) {}

  async ngOnInit() {
    this.actualStatus = this.onLine()
    console.log('actualStatus: ', this.actualStatus)
    this.getRoles()
    await this.getEvents()
  }

  getRoles() {
    const user = JSON.parse(localStorage.getItem('fndsadmin::current_user') || '{}')
    this.user = user.user
    this.roles = this.user.role
  }

  /* async getEvents() {
    this.events = firstValueFrom(await this.eventsService.getEventsByValidator(this.user.id)).then(
      res => {
        this.events = res.event
        console.log(res)
        console.log(this.events)
      },
      err => {
        console.log(err)
      }
    )
  } */
  saveSession(session: any) {
    this.selectedPerpetualSession = session.name
    this.tipoPerpetuo = session.name
    console.log(111, this.selectedPerpetualSession)
    this.fechaSelected = this.fechaSelectedMientras
    // this.fechaSelectedMientras = undefined
    console.log(this.fechaSelectedMientras)
    console.log(this.tipoPerpetuo)
  }

  async getEventsAll() {
    this.events = firstValueFrom(await this.eventsService.getAll()).then(
      res => {
        this.events = res.event
        this.oldEvents = this.events
        console.log(this.events)
      },
      err => {
        console.log(err)
      }
    )
  }
  OnDateChange(arg0: Date | null) {
    this.perpetualDateSelected = arg0
    this.isHourShowing = true
    //this.showHourSubmenu = false
    console.log(this.perpetualDateSelected)
    this.fechaSelected = undefined

    //this.enableBuyButton = false
  }

  async getEvents() {
    if (this.roles.includes('admin')) {
      await this.getEventsAll()
    } else {
      this.events = firstValueFrom(
        await this.eventsService.getEventsByValidator(this.user.id)
      ).then(
        res => {
          this.events = res.event
          this.oldEvents = this.events
          console.log(res)
          console.log(this.events)
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  async selectEvent(evento: any) {
    this.actualStatus = this.onLine()
    this.evento = evento
    console.log(this.evento)
    this.events = [evento]
    console.log('evento.perpetualEvent: ', evento.perpetualEvent)
    if (!evento.perpetualEvent) {
      this.perpetualSession = false
      this.getFechas()
    } else {
      this.perpetualSession = true
      console.log('perpetual!!')
      this.getFechasPerpetual(evento)
    }
  }

  getFechas() {
    this.fechas = []
    this.sessions = []
    this.evento.sessions.forEach((session: any) => {
      this.sessions.push(session)
      this.fechas.push(session.date)
    })
  }

  async getFechasPerpetual(evento: any) {
    const initDateInitial = new Date(evento.initDate)
    const endDateInitial = new Date(evento.endDate)
    const initDate = initDateInitial.toLocaleDateString()
    const endDate = endDateInitial.toLocaleDateString()
    this.fechasPerpetual = `${initDate} - ${endDate}`
    console.log(initDate)
    await this.getTicketsbyEvent(this.evento.id)
  }

  selectFecha(fecha: any) {
    this.actualStatus = this.onLine()
    console.log('fecha: ', fecha)
    this.events = [this.evento]
    this.fechaSelected = fecha
    console.log(this.fechaSelected)
    this.isDataShowing = true
    this.showDataSubmenu = false
    if (!this.evento.perpetualEvent) {
      this.getHours(fecha)
    }
  }

  getHours(session: any) {
    this.hours = []
    this.session = session
    console.log('session: ', session)
    this.sessionId = this.session.id
    this.hours.push(session.initHour + ' - ' + session.endHour)
    console.log(this.session.id)
    /* this.sessions.forEach((session: any) => {
      const initHour = session.initHour
      const endHour = session.endHour
      const hour = initHour + ' - ' + endHour
      this.hours.push(hour)
    }) */
  }

  async selectHour(hour: string) {
    this.actualStatus = this.onLine()
    this.hourSelected = hour
    console.log(this.hourSelected)
    this.isHourShowing = true
    this.showHourSubmenu = false
    await this.getTicketsbyEvent(this.evento.id)
  }

  gotoList() {
    this.fechaSelected = undefined
    this.hourSelected = ''
    this.isDataShowing = false
    this.isHourShowing = false
    this.tickets = []
    this.evento = {}
    this.ngOnInit()
  }

  public async readQR(action: any) {
    this.actualStatus = this.onLine()
    console.log('entraaaaa')
    const ticketId = action.data.value[0].value || ''
    console.log(ticketId)
    await this.validate(ticketId, action)
  }

  public async validate(ticketId: string, action: any) {
    console.log(1111, ticketId)
    console.log('validar ticket con id: ' + ticketId)
    let valid = 'error'
    let ticket: any
    this.actualStatus = this.onLine()
    // if (ticket.event.perpetualEvent) {
    //   if (
    //     ticket.typeTicket !== this.selectedPerpetualSession ||
    //     ticket.date !== this.fechaSelected
    //   ) {
    //     this.verificacion = false
    //   }
    // }
    console.log(12132121, this.fechaSelected)
    let fecha
    if (this.actualStatus) {
      if (this.fechaSelected) {
        fecha = new Date(this.fechaSelected)
        let tipo
        if (this.selectedPerpetualSession) {
          tipo = this.selectedPerpetualSession
          this.sessionId = 'a'
        } else {
          tipo = 'aaa'
          fecha = new Date()
        }
        await firstValueFrom(
          await this.ticketsService.checkTicket(
            ticketId,
            fecha,
            tipo,
            this.evento.id,
            this.sessionId
          )
        ).then(
          res => {
            console.log(res)
            valid = res.success.toString()
            ticket = res.ticket
            return res
          },
          err => {
            console.log(err)
          }
        )
      }

      if (valid === 'false') action.stop()
      this.showQR = false
      if (valid === 'false') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          timerProgressBar: true,
          width: '500',
          showConfirmButton: true
        })
        Toast.fire({
          icon: 'error',
          title: "<h2 style='color:red'>" + 'Código Incorrecto' + '</h2>',
          text: 'El código es incorrecto o ha sido utilizado'
        })
      } else if (valid === 'true') {
        console.log('Ticket is valid and not used')
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          timerProgressBar: true,
          width: '500',
          showConfirmButton: true,
          timer: 10000
        })
        let fecha
        let initHour
        let endHour
        if (ticket.session) {
          fecha = new Date(ticket.session.date).toLocaleDateString()
          initHour = ticket.session.initHour.substring(0, 5)
          endHour = ticket.session.endHour.substring(0, 5)
        } else {
          fecha = this.fechaSelected!.toLocaleDateString()
          initHour = ''
          endHour = ''
        }
        console.log(fecha)
        console.log(this.evento.name)
        console.log(ticket.fila)
        console.log(ticket.butaca)
        console.log(initHour)
        console.log(endHour)
        const text2Swal =
          '<h2>' +
          this.evento.name +
          ' </h2>' +
          '<h3>' +
          'Recinto: ' +
          // this.evento.enclosure.name +
          ' </h3>' +
          ' <p>' +
          'Fila ' +
          ticket.fila +
          ' / butaca ' +
          ticket.butaca +
          ' </p>' +
          ' <p>' +
          fecha +
          ' </p>' +
          ' <p>' +
          initHour +
          ' - ' +
          endHour +
          '</p>'
        Toast.fire({
          icon: 'success',
          title: "<h2 style='color:green'>" + 'Entrada aceptada' + '</h2>',
          html: text2Swal
        })
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          timerProgressBar: true,
          width: '500',
          showConfirmButton: false
        })
        Toast.fire({
          icon: 'error',
          title: "<h2 style='color:red'>" + 'Error de lectura' + '</h2>',
          text: 'Ha ocurrido un error en la lectura del código. Por favor vuelva a intentarlo.'
        })
      }
    } else {
      valid = this.checkTicketOffline(ticketId)
      ticket = this.tickets.find((ticket: any) => ticket.id === ticketId)
      action.stop()
      this.showQR = false
      if (valid === 'false') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          timerProgressBar: true,
          width: '500',
          showConfirmButton: true
        })
        Toast.fire({
          icon: 'error',
          title: "<h2 style='color:red'>" + 'Código Incorrecto' + '</h2>',
          text: 'El código es incorrecto o ha sido utilizado'
        })
      } else if (valid === 'true') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          timerProgressBar: true,
          width: '500',
          showConfirmButton: true,
          timer: 10000
        })
        this.ticketsChecked.push(ticket.id)
        const fecha = new Date(ticket.session.date).toLocaleDateString()
        const initHour = ticket.session.initHour.substring(0, 5)
        const endHour = ticket.session.endHour.substring(0, 5)
        const text2Swal =
          '<h2>' +
          this.evento.name +
          ' </h2>' +
          '<h3>' +
          'Recinto: ' +
          this.evento.enclosure.name +
          ' </h3>' +
          ' <p>' +
          'Fila ' +
          ticket.fila +
          ' / butaca ' +
          ticket.butaca +
          ' </p>' +
          ' <p>' +
          fecha +
          ' </p>' +
          ' <p>' +
          initHour +
          ' - ' +
          endHour +
          '</p>'
        Toast.fire({
          icon: 'success',
          title: "<h2 style='color:green'>" + 'Entrada aceptada' + '</h2>',
          html: text2Swal
        })
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          timerProgressBar: true,
          width: '500',
          showConfirmButton: false
        })
        Toast.fire({
          icon: 'error',
          title: "<h2 style='color:red'>" + 'Error de lectura' + '</h2>',
          text: 'Ha ocurrido un error en la lectura del código. Por favor vuelva a intentarlo.'
        })
      }
    }
  }

  public handle(action: any, fn: string): void {
    this.actualStatus = this.onLine()
    const playDeviceFacingBack = (devices: ScannerQRCodeDevice[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => /back|rear|environment/gi.test(f.label)) // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId)
    }
    this.showQR = true

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => console.log(fn, r), alert)
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert)
    }
  }

  public openSearch() {
    this.showSearch = !this.showSearch
    document.getElementById('searchInput')?.focus()
  }

  public searchEvent(search: string) {
    if (search.length === 0) {
      this.events = this.oldEvents
    } else {
      this.events = this.oldEvents.filter((event: any) => {
        return event.name.toLowerCase().includes(search.toLowerCase())
      })
    }
  }

  public onLine() {
    return window.navigator.onLine
  }

  async getTicketsbyEvent(eventId: string): Promise<any> {
    this.actualStatus = this.onLine()
    await firstValueFrom(await this.ticketsService.getTicketsByEvent(eventId)).then(res => {
      console.log(res)
      this.tickets = res.tickets
      console.log('thistickets:', this.tickets)
      return res
    })
  }

  checkTicketOffline(ticketId: string): string {
    this.actualStatus = this.onLine()
    let valid = 'false'
    for (let ticket of this.tickets) {
      if (ticket.id === ticketId && !ticket.check) {
        valid = 'true'
        this.tickets[this.tickets.indexOf(ticket)].check = true
        return valid
      }
    }
    return valid
  }
}
