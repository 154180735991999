import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AccountService } from 'src/app/shared/services/account.service'
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  constructor(private _http: HttpClient, private accountService: AccountService) {}

  async create(ticket: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.post(environment.apiUrl + '/admin/tickets', ticket, { headers })
  }

  async getAll(): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/tickets', { headers })
  }

  async getOne(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/tickets/' + id, { headers })
  }

  async updateOne(id: string, ticket: any): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.put(environment.apiUrl + '/admin/tickets/' + id, ticket, { headers })
  }

  async delete(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.delete(environment.apiUrl + '/admin/tickets/' + id, { headers })
  }

  async checkTicket(
    ticket: string,
    fecha: Date,
    tipo: string,
    eventId: string,
    sesionId: string
  ): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    fecha.setHours(fecha.getHours() + 2)

    const fechaString = fecha.toISOString()
    const url = `${
      environment.apiUrl
    }/admin/tickets/check/${ticket}/${fechaString}/${encodeURIComponent(
      tipo
    )}/${eventId}/${sesionId}`
    return this._http.get(url, { headers })
  }

  async getTicketsByEvent(id: string): Promise<Observable<any>> {
    const auth_token = await this.accountService.getToken()
    const headers = {
      Authorization: 'Bearer ' + auth_token,
      'My-Custom-Header': 'foobar'
    }
    return this._http.get(environment.apiUrl + '/admin/tickets/ticketsbyevent/' + id, { headers })
  }
}
