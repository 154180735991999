/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { SeatMapsService } from 'src/app/shared/services/seat-maps.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-seat-maps-new',
  templateUrl: './seat-maps-new.component.html',
  styleUrls: ['./seat-maps-new.component.scss']
})
export class SeatMapsNewComponent implements OnInit {
  public createSeatMapForm!: FormGroup
  loadedSeatMap: any
  loadedEnclosure: any
  mode = 'create'
  submitted = false
  loading = false
  constructor(
    private router: Router,
    private seatMapsService: SeatMapsService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.createSeatMapForm = this.fb.group({
      name: ['', Validators.required]
    })
    this.loadedSeatMap = history.state.seatMap
    this.loadedEnclosure = history.state.enclosure
    if (history.state.mode) {
      this.mode = history.state.mode
    }
    history.pushState(null, '')
    if (this.mode === 'edit' || this.mode === 'watch') {
      this.setLoadedData()
    }
  }

  setLoadedData() {
    this.createSeatMapForm.controls['name'].setValue(this.loadedSeatMap.name)
  }

  public async onSubmit(): Promise<void> {
    if (this.createSeatMapForm.valid) {
      if (this.mode === 'edit') {
        this.edit()
      } else {
        const newSeatMap = {
          name: this.createSeatMapForm.get('name')?.value,
          stage: [],
          sections: [],
          access: [],
          toilets: [],
          enclosure: this.loadedEnclosure
        }
        this.submitted = true
        ;(await this.seatMapsService.create(newSeatMap)).subscribe({
          next: async response => {
            Swal.fire('Mapa de asientos creado correctamente', '', 'success')
            this.router.navigate(['/home/seat-map-general'], {
              state: { mode: 'edit', seatMap: response.seatMap }
            })
          },
          complete: () => {},
          error: error => {
            console.log(error)
          }
        })
      }
    }
  }

  public async edit(): Promise<void> {
    const seatMap = {
      name: this.createSeatMapForm.get('name')?.value,
      stage: this.loadedSeatMap.stage,
      sections: this.loadedSeatMap.sections,
      access: this.loadedSeatMap.access,
      toilets: this.loadedSeatMap.toilets
    }
    this.submitted = true
    ;(await this.seatMapsService.updateOne(this.loadedSeatMap.id, seatMap)).subscribe({
      next: async response => {
        Swal.fire('Mapa de asientos editado correctamente', '', 'success')
        this.router.navigate(['/home/seat-map-general'], {
          state: { mode: 'edit', seatMap: response.seatMap }
        })
      },
      complete: () => {},
      error: error => {
        console.log(error)
      }
    })
  }

  gotoList() {
    this.router.navigate(['/home/enclosures-list'])
  }
}
