<div class="wrapper">
  <div class="sidenav">
    <div class="opener"></div>
    <!--<div>
      <mat-icon>dashboard</mat-icon>
      <mat-list-item (click)="showDashboardSubmenu = !showDashboardSubmenu" class="parent">
        <span class="full-width" *ngIf="isDashboardExpanded || isDashboardShowing">Dashboard</span>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showDashboardSubmenu }"
          *ngIf="isDashboardExpanded || isDashboardShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
    </div>-->
    <!-- <div
      class="submenu"
      [ngClass]="{ expanded: showDashboardSubmenu }"
      *ngIf="isDashboardShowing || isDashboardExpanded">
      <p>Pdte</p>
    </div>
    <div class="separator"></div> -->
    <div *ngIf="roles.includes('admin')">
      <div style="cursor: pointer">
        <mat-icon class="left-icon">account_box</mat-icon>
        <mat-list-item (click)="showUsersSubmenu = !showUsersSubmenu" class="parent">
          <span class="full-width" *ngIf="isUsersExpanded || isUsersShowing">Usuarios</span>
          <mat-icon
            class="menu-button"
            [ngClass]="{ rotated: showUsersSubmenu }"
            *ngIf="isUsersExpanded || isUsersShowing"
            >expand_more</mat-icon
          >
        </mat-list-item>
      </div>
      <div
        class="submenu"
        [ngClass]="{ expanded: showUsersSubmenu }"
        *ngIf="isUsersShowing || isUsersExpanded">
        <p style="cursor: pointer" routerLink="/home/users-list">Listado de usuarios</p>
      </div>
    </div>

    <div class="separator" *ngIf="roles.includes('admin')"></div>
    <div *ngIf="roles.includes('admin')">
      <div style="cursor: pointer">
        <mat-icon class="left-icon">event</mat-icon>
        <mat-list-item (click)="showEventsSubmenu = !showEventsSubmenu" class="parent">
          <span class="full-width" *ngIf="isEventsExpanded || isEventsShowing">Eventos</span>
          <mat-icon
            class="menu-button"
            [ngClass]="{ rotated: showEventsSubmenu }"
            *ngIf="isEventsExpanded || isEventsShowing"
            >expand_more</mat-icon
          >
        </mat-list-item>
      </div>
      <div
        class="submenu"
        [ngClass]="{ expanded: showEventsSubmenu }"
        *ngIf="isEventsShowing || isEventsExpanded">
        <p style="cursor: pointer" routerLink="/home/events-list">Listado de eventos</p>
        <p style="cursor: pointer" routerLink="/home/events">Crear evento</p>
        <p style="cursor: pointer" routerLink="/home/categories-list">Categorías</p>
        <p style="cursor: pointer" routerLink="/home/subcategories-list">Subcategorías</p>
      </div>
    </div>
    <div class="separator" *ngIf="roles.includes('admin')"></div>
    <div *ngIf="roles.includes('admin')">
      <div style="cursor: pointer">
        <mat-icon class="left-icon"> place</mat-icon>
        <mat-list-item (click)="showPaymentsSubmenu = !showPaymentsSubmenu" class="parent">
          <span class="full-width" *ngIf="isPaymentsExpanded || isPaymentsShowing">Recintos</span>
          <mat-icon
            class="menu-button"
            [ngClass]="{ rotated: showPaymentsSubmenu }"
            *ngIf="isPaymentsExpanded || isPaymentsShowing"
            >expand_more</mat-icon
          >
        </mat-list-item>
      </div>
      <div
        class="submenu"
        [ngClass]="{ expanded: showPaymentsSubmenu }"
        *ngIf="isPaymentsShowing || isPaymentsExpanded">
        <p style="cursor: pointer" routerLink="/home/enclosures-list">Listado de recintos</p>
        <p style="cursor: pointer" routerLink="/home/enclosures">Crear recinto</p>
        <p style="cursor: pointer" routerLink="/home/seat-map-list">Mapas de asientos</p>
      </div>
    </div>
    <div *ngIf="roles.includes('admin')" class="separator"></div>
    <!--<div>
      <mat-icon>calendar_view_day</mat-icon>
      <mat-list-item (click)="showBannersSubmenu = !showBannersSubmenu" class="parent">
        <span class="full-width" *ngIf="isBannersExpanded || isBannersShowing">Banners</span>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showBannersSubmenu }"
          *ngIf="isBannersExpanded || isBannersShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
    </div>-->
    <div *ngIf="roles.includes('admin')">
      <div style="cursor: pointer">
        <mat-icon class="left-icon"> local_activity</mat-icon>
        <mat-list-item (click)="showBannersSubmenu = !showBannersSubmenu" class="parent">
          <span class="full-width" *ngIf="isBannersExpanded || isBannersShowing">Banners</span>
          <mat-icon
            class="menu-button"
            [ngClass]="{ rotated: showPaymentsSubmenu }"
            *ngIf="isPaymentsExpanded || isPaymentsShowing"
            >expand_more</mat-icon
          >
        </mat-list-item>
      </div>
      <div
        class="submenu"
        [ngClass]="{ expanded: showBannersSubmenu }"
        *ngIf="isBannersShowing || isBannersExpanded">
        <p style="cursor: pointer" routerLink="/home/banners-list">Listado de banners</p>
        <p style="cursor: pointer" routerLink="/home/banners">Crear banner</p>
      </div>
    </div>

    <div *ngIf="roles.includes('admin')" class="separator"></div>
    <div
      *ngIf="
        (roles.includes('ventaTaquilla') ||
          roles.includes('validador') ||
          roles.includes('admin')) &&
        !mobile
      ">
      <div
        style="cursor: pointer"
        *ngIf="
          roles.includes('ventaTaquilla') || roles.includes('validador') || roles.includes('admin')
        ">
        <mat-icon class="left-icon"> confirmation_number</mat-icon>
        <mat-list-item (click)="showEntradasSubmenu = !showEntradasSubmenu" class="parent">
          <span class="full-width" *ngIf="isEntradasExpanded || isEntradasShowing">Entradas</span>
          <mat-icon
            class="menu-button"
            [ngClass]="{ rotated: showEntradasSubmenu }"
            *ngIf="isEntradasExpanded || isEntradasShowing"
            >expand_more</mat-icon
          >
        </mat-list-item>
      </div>
      <div
        class="submenu"
        [ngClass]="{ expanded: showEntradasSubmenu }"
        *ngIf="isEntradasShowing || isEntradasExpanded">
        <p
          *ngIf="roles.includes('ventaTaquilla') || roles.includes('admin')"
          style="cursor: pointer"
          routerLink="/home/entradas">
          Venta por taquilla
        </p>
        <p
          *ngIf="roles.includes('validador') || roles.includes('admin')"
          style="cursor: pointer"
          routerLink="/home/lector-qr">
          Validar QR
        </p>
      </div>
    </div>
    <div
      *ngIf="
        (roles.includes('ventaTaquilla') ||
          roles.includes('validador') ||
          roles.includes('admin')) &&
        mobile
      ">
      <div
        style="cursor: pointer"
        *ngIf="
          roles.includes('ventaTaquilla') || roles.includes('validador') || roles.includes('admin')
        ">
        <!-- <mat-icon class="left-icon"> confirmation_number</mat-icon> -->
        <mat-list-item (click)="showEntradasSubmenu = !showEntradasSubmenu" class="parent">
          <span class="full-width" *ngIf="isEntradasExpanded || isEntradasShowing">Entradas</span>
          <mat-icon
            class="menu-button"
            [ngClass]="{ rotated: showEntradasSubmenu }"
            *ngIf="isEntradasExpanded || isEntradasShowing"
            >expand_more</mat-icon
          >
        </mat-list-item>
      </div>
      <div
        class="submenu"
        [ngClass]="{ expanded: showEntradasSubmenu }"
        *ngIf="isEntradasShowing || isEntradasExpanded">
        <mat-icon
          *ngIf="roles.includes('ventaTaquilla') || roles.includes('admin')"
          style="cursor: pointer"
          routerLink="/home/entradas">
          confirmation_number
        </mat-icon>
        <mat-icon
          *ngIf="roles.includes('validador') || roles.includes('admin')"
          style="cursor: pointer"
          routerLink="/home/lector-qr">
          qr_code
        </mat-icon>
      </div>
    </div>

    <!-- <div
      class="submenu"
      [ngClass]="{ expanded: showBannersSubmenu }"
      *ngIf="isBannersShowing || isBannersExpanded">
      <p>Pdte</p>
    </div> -->
  </div>
  <div class="content"><router-outlet></router-outlet></div>
</div>
